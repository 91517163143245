import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {Button} from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CategoryIcon from '@mui/icons-material/Category';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PrintIcon from '@mui/icons-material/Print';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SwipeDownAltIcon from '@mui/icons-material/SwipeDownAlt';
import SwipeUpAltIcon from '@mui/icons-material/SwipeUpAlt';
import SwipeLeftAltIcon from '@mui/icons-material/SwipeLeftAlt';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Loading from '../Loading';
import NotFound from '../notFound';
import { HighlightOff, RemoveCircleOutline, Settings } from '@mui/icons-material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Body = styled.div`
    width: 100%;
`

const Header = styled.div`
    height: 35px;
    width: 96%;
    padding: 10px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px #e1e1e1 solid;
    @media only screen and (max-width: 850px) {
        flex-direction: column !important;
    }
`
const HeaderTitle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: center;
    @media only screen and (max-width: 850px) {
        width: 100% !important;
        flex-direction: row;
        flex-wrap: wrap;
    }
`


const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
    @media only screen and (max-width: 850px) {
      width: 100%;
    }
`

const BodyContent = styled.div`
    position: relative;
    width: 100%;
    background-color: #f6f7fa;
    min-height: calc(100vh - 122px - 20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    flex-grow: 3;
    padding-bottom: 25px;
    @media only screen and (max-width: 850px) {
    }
`

const Form = styled.div`
    width: calc(90% - 40px) ;
    margin: 0px 5%;
    background-color: white;
    min-height: max-content;
    box-shadow: 0px 0px 1px 0px grey;
    padding: 10px 20px;
    margin-top: 25px;
    @media only screen and (max-width: 850px) {
      width: calc(90% - 10px) ;
      padding: 10px 5px;

    }
`

const ProductHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 850px) {
    flex-direction: column;
  }
`

const ProductHeaderContainerImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 28%;
  @media only screen and (max-width: 850px) {
    width: 100%;
  }
`



const ProductHeaderImage = styled.div`
  width: 100%;
  margin-bottom: 10px;
  height: 250px;
  background-color: #8080804f;
  border-radius: 6px;
  background-image: url(${props => props.url});
  background-position: center;
  background-size: cover;
  @media print {
    background-image: url(${props => props.url});
    /* Additional styling properties for printing */
  }
`

const ProductHeaderContainerDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 70%;
  @media only screen and (max-width: 850px) {
    width: 100%;
  }
`

const ProductHeaderContainerDetailsElement = styled.div`
  width: 100%;
  display: flex;
  flex-direction: rows;
  justify-content: start;
  align-items: center;
  margin-bottom: 10px;
`

const MyLabel = styled.p`
  width: max-content;
  min-width: 80px;
  padding: 0%;
  margin: 0%;
  text-align: left;
  padding-right: 10px;
`


const MyTextField = styled(TextField)`
  //width: 85%;
  min-width: 300px !important;
  padding: 0%;
  margin: 0%;
  flex-grow: 1;
  border-color: red;
`
const AddedComponents = styled.div`
  position: relative;
  padding-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  //background-color: #fdf0ffae;
`

const AddedComponentsContainer = styled.div`
  width: calc(100% -0px);
  //padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: end;
  margin-bottom: 10px;
  //background-color: #fdf0ff8c;
  //border: 1px #60606085 solid;
  transition: 200ms ease-in-out;
`

const SmallTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 25px 0px 10px 0px;
`
const SmallTitleText = styled.p`
  margin: 0%;
  padding: 0% 10px;
  text-align: center;
  width: max-content;
  color: #a947c7
`
const SmallTitleSpan = styled.span`
  height: 1px;
  width: 50%;
  background-color: #dcaeedc2;
`


const AvailableNumberContainer = styled.div`
  position: absolute;
  top: -15px;
  right: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-left: 10px;
`

const AvailableNumberTitle = styled.p`
  margin: 0% 10px 0px 0px;
  padding: 0;
  color: #808080;
  font-size: medium;
`
const AvailableNumberDesc = styled.p`
  margin: 0%;
  padding: 0;
  color: #000000;
  font-size: medium;
`
const ProductComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
`

const ProductComponentContainerTitle = styled.p`
  margin: 0%;
  padding: 0;
  color: #000000;
  font-size: medium;
  display: flex;
  flex-direction: column;
`

const ProductComponentContainerSubTitle = styled.p`
  margin: 0%;
  padding: 0;
  color: #000000;
  font-size: medium;
`













export default function ProductView({handleHeader, handleError, Language, languageSelected}) {

  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [ openAlert, setOpenAlert] = useState(false)
  const location = useLocation();


  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleClickOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };


  const [openEditModal, setOpenEditModal] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };


  const navigate = useNavigate()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const [showAddComponentsContainer, setShowAddComponentsContainer] = useState('none');
  function handleChangeType(type){
    if(isDraft === true){
      if(type === 'product'){
        setShowAddComponentsContainer('flex')
      }else{
        setShowAddComponentsContainer('none')
      }
    }
  }

  const [receivedQuantity, setReceivedQuantity] = useState('');
  const [deliveredQuantity, setDeliveredQuantity] = useState('');
  const [returnedQuantity, setReturnedQuantity] = useState('');

  
  useEffect(() => {
    handleHeader('History')
  }, []);

  // initialisation

  // privileges
  const [create_product, setCreate_product] = useState('none');
  const [delete_product, setDelete_product] = useState('none');
  const [edit_product, setEdit_product] = useState('none');


  //Allow Parameters
  const [allowSave, setAllowSave] = useState(true);
  const [allowDelete, setAllowDelete] = useState(false);
  const [allowReceived, setAllowReceived] = useState(false);
  const [allowDelivered, setAllowDelivered] = useState(false);
  const [allowReturned, setAllowReturned] = useState(false);
  const [allowFilter, setAllowFilter] = useState(false);
  const [allowPrint, setAllowPrint] = useState(false);
  const [allowAddComponent, setAllowAddComponent] = useState(false);
  const [allowEdit, setAllowEdit] = useState(true);
  const [isProduct, setIsProduct] = useState(false);

  // Product Informations
  const [productId, setProductId] = useState('');
  const [image, setImage] = useState('/emptyImage.png');
  const [name, setName] = useState('');
  const [reference, setReference] = useState('');
  const [price, setPrice] = useState(formatNumberWithMinimumDigits(0));
  const [type, setType] = useState('product');
  const [quantity, setQuantity] = useState(0);
  const [total, setTotal] = useState(0);
  const [limitDelivered, setLimitDelivered] = useState(0);
  const [limitReturned, setLimitReturned] = useState(0);


  const [components, setComponents] = useState([]);


  // Product Error Handlers
  const [nameError, setNameError] = useState('');
  const [referenceError, setReferenceError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [typeError, setTypeError] = useState('');

  const [nameIsError, setNameIsError] = useState(false);
  const [referenceIsError, setReferenceIsError] = useState(false);
  const [priceIsError, setPriceIsError] = useState(false);
  const [typeIsError, setTypeIsError] = useState(false);
  

  // loadings
  const [isLoadingAddComponent, setIsLoadingAddComponent] = useState(false);
  const [isLoadingAddReceived, setIsLoadingAddReceived] = useState(false);
  const [isLoadingAddDelivered, setIsLoadingAddDelivered] = useState(false);
  const [isLoadingAddReturned, setIsLoadingAddReturned] = useState(false);
  const [isLoadingUploadImage, setIsLoadingUploadImage] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);

  // Handle change product information
  
  const [idStatus, setIdStatus] = useState('');
  
  
  function handleChangeProductInfo(field, value){
    // Set changes
    console.log('allow edit? ', allowEdit)
    if(allowEdit){
      if(field === "name"){
        setName(value)
        setNameError('')
        setNameIsError(false)
      } else if(field === "type"){
        setType(value)
        setTypeError('')
        setTypeIsError(false)
      } else if(field === "reference"){
        setReference(value)
        setReferenceError('')
        setReferenceIsError(false)
      } else if(field === "price"){
        setPrice(value)
        setPriceError('')
        setPriceIsError(false)
      }
    }
    // save changes to the database of the product is draft
    console.log('pre saving data ..', isDraft)
    if(isDraft === true && ( idStatus === 'finish' || idStatus === 'init' )){
      console.log('saving ..')
      if(idStatus === 'init'){
        setIdStatus('loading')
      }
      axios.post("/inventory_management/save", {
        field, value, productId, languageSelected, type: Type
      })
      .then(function (response) {
        if(productId === 'draft'){
          navigate('/app/Inventory_management/product?draft=true&id=' + response.data.ProductId)
        }
        setIdStatus('finish')
        setAllowDelete(true)
        console.log(response.data.response)
        setAlertMessage(Language[response.data.message])
        setProductId(response.data.ProductId)
        setAlertType('success')
        //setOpenAlert(true)  
        setTimeout(() => {
          setOpenAlert(false)
        }, 5000);
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        handleError(error)
      })
    }
    
   
  }


  function formatNumberWithMinimumDigits(number) {
    let decimalPlaces = parseFloat(number).toString().split('.')[1];
    let minDigits = decimalPlaces ? Math.max(decimalPlaces.length, 2) : 2;
    let formattedNumber = parseFloat(number).toFixed(minDigits);
    return formattedNumber.replace(/(\.\d*?[1-9])0+$/, '$1');
  }

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  // get product details
  function getProductDetails (id){
    axios.post("/inventory_management/product_details", {
        id, languageSelected
      })
      .then(function (response) {
        console.log('create product? ', create_product )
        console.log('delete product? ', delete_product )
        if(response.data.client && response.data.client.superAdmin){
          setIsSuperAdmin(response.data.client.superAdmin)
        }
        if( response.data.product.valid === true){
          for(let i = 0; i < response.data.client.privileges.length; i++){
            const item = response.data.client.privileges[i]
            var status= 'none'
            if(item.userPrivilege.status === true){
              status = 'flex'
            }
            if(item.name === 'delete_product'){
              setDelete_product(status)
            }else if(item.name === 'edit_product'){
              setCreate_product(status)
              if(item.userPrivilege.status === true){
                setAllowEdit(true)
              }else{
                setAllowEdit(false)
              }
            }
          }
        }else{
          setIsDraft(true)
          setCreate_product('flex')
          setDelete_product('flex')
        }
        
        setAllowDelete(true)
        console.log(response.data)
        // loading false
        setTotal(response.data.total)
        setHistory(response.data.product.operations)
        setLimitDelivered(response.data.limitDelivered)
        setLimitReturned(response.data.limitReturned)
        console.log('new create product? ', create_product )
        console.log('new delete product? ', delete_product )
        if(response.data.limitDelivered > 0){
          setAllowDelivered(true)
        }
        if(response.data.limitReturned > 0){
          setAllowReturned(true)
        }
        setProductComponents(response.data.product.subProducts)
        setComponents(response.data.products)
        setName(response.data.product.name)
        setPrice(formatNumberWithMinimumDigits(response.data.product.price))
        if(response.data.product.image){
          setImage(response.data.product.image)
        }
        setReference(response.data.product.reference)
        setType(response.data.product.type)
        setIsDraft(!response.data.product.valid)
        setAllowPrint(response.data.product.valid)
        setAllowReceived(response.data.product.valid)
        setAllowFilter(response.data.product.valid)
        if(response.data.product.type === 'product'){
          setShowAddComponentsContainer('flex')
        }else{
          setShowAddComponentsContainer('none')
        }
        if(!response.data.product.valid === true){
          setAllowEdit(true)
          setAllowDelete(true)
        }
        setIsLoading(false)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        if(error.response.status === 404){
          setNotFound(true)
          setIsLoading(false)
        }
        handleError(error)
      })
  }

  const [history, setHistory] = useState([]);

  // make operations
  function makeOperation(operation, value){
    if(operation === 'received'){
      setIsLoadingAddReceived(true)
    }else if(operation === 'delivered'){
      setIsLoadingAddDelivered(true)
    }else if(operation === 'returned'){
      setIsLoadingAddReturned(true)
    }
    axios.post("/inventory_management/add_operation", {
        productId, operationType: operation, value, languageSelected
      })
      .then(function (response) {
        setDeliveredQuantity('')
        setReceivedQuantity('')
        setReturnedQuantity('')
        console.log(response.data)
        if(operation === 'received'){
          setIsLoadingAddReceived(false)
        }else if(operation === 'delivered'){
          setIsLoadingAddDelivered(false)
        }else if(operation === 'returned'){
          setIsLoadingAddReturned(false)
        }
        // implement History on the table..
        setTotal(response.data.total)
        setLimitDelivered(response.data.limitDelivered)
        setLimitReturned(response.data.limitReturned)
        if(response.data.limitDelivered > 0){
          setAllowDelivered(true)
        }else{
          setAllowDelivered(false)
          setValue('1')
        }
        if(response.data.limitReturned > 0){
          setAllowReturned(true)
        }else{
          setAllowReturned(false)
          setValue('1')
        }
        setHistory(response.data.product.operations)
      })
      .catch(function (error) {
        // handle error
        if(operation === 'received'){
          setIsLoadingAddReceived(false)
        }else if(operation === 'delivered'){
          setIsLoadingAddDelivered(false)
        }else if(operation === 'returned'){
          setIsLoadingAddReturned(false)
        }
        console.log(error)
        handleError(error)
      })
  }
   


  // filter history
  const [filterDate, setFilterDate] = useState('');
  const [showReset, setShowReset] = useState('none');

  function filterHistory (operation){
    console.log(filterDate)
    setIsLoadingHistory(true)
    axios
      .post("/inventory_management/filter_product_history", {
        productId , filterDate, operation, languageSelected
      })
      .then(function (response) {
        console.log(response.data)
        setIsLoadingHistory(false)
        if(response.data.product){
          setHistory(response.data.product.operations)
        }else{
          setHistory([])
        }
        if(operation === 'filter'){
          setShowReset('unset')
        }else{
          setShowReset('none')
          setFilterDate('')
        }
      })
      .catch(function (error) {
        // handle error
        setIsLoadingHistory(false)
        console.log(error)
        handleError(error)
      })
  }


  // save product 
  function saveProduct (){
    axios.post("/inventory_management/save_product", {
        productId , name, type, reference, price, languageSelected
      })
      .then(function (response) {
        console.log('***********************')
        console.log(response)
        console.log('***********************')
        navigate('/app/Inventory_management/product?id=' + productId)
        if(isDraft === false){
          for(let i = 0; i < response.data.client.privileges; i++){
            const item = response.data.client.privileges[i]
            var status= 'none'
            if(item.userPrivilege.status === true){
              status = 'flex'
            }
            if(item.name === 'delete_product'){
              setDelete_product(status)
            }else if(item.name === 'create_product'){
              setCreate_product(status)
            }
          }
          console.log(response.data)
          console.log(response.data.response)
          setAlertMessage(Language[response.data.message])
          setAlertType('success')
          setOpenAlert(true)  
          setIsDraft(false)
          setTimeout(() => {
            setOpenAlert(false)
            
          }, 3000);
          getProductDetails(productId)
        }else{
          window.location.reload();
        }
        
        
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        handleError(error)
      })
  }


  // delete product 
  function deleteProduct (){
    axios.post("/inventory_management/delete_product", {
        productId , languageSelected
      })
      .then(function (response) {
        setOpenDeleteModal(false)
        setAlertMessage(Language[response.data.message])
        setAlertType('success')
        setOpenAlert(true)  
        setIsDraft(false)
        setTimeout(() => {
          setOpenAlert(false)
          navigate('/app/Inventory_management/products')
        }, 500);
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        handleError(error)
      })
  }

// upload image
const fileInputRef = useRef(null);

const handleFileSelect = () => {
  fileInputRef.current.click();
};


const handleFileChange = (event) => {
  const selectedFile = event.target.files[0];
  setIsLoadingUploadImage(true)
  const formData = new FormData();
  formData.append('file', selectedFile);
  formData.append('productId', productId);
  axios
    .post('/inventory_management/upload_product_image', formData)
    .then((response) => {
      setIsLoadingUploadImage(false)
      // Handle success
      setImage(response.data.image)
      console.log(response.data);
    })
    .catch((error) => {
      // Handle error
      setIsLoadingUploadImage(false)
      handleError(error)
    });
};





  // add component

  const [componentName, setComponentName] = useState('');
  const [componentQuantity, setComponentQuantity] = useState(0);
  const [productComponents, setProductComponents] = useState([]);

  function addComponent(){
    console.log(componentName, componentQuantity)
    setIsLoadingAddComponent(true)
    axios.post("/inventory_management/add_component_to_product", {
        productId , componentName, componentQuantity, languageSelected
      })
      .then(function (response) {
        console.log(response)
        setIsLoadingAddComponent(false)
        setProductComponents(response.data.product.subProducts)
      })
      .catch(function (error) {
        setIsLoadingAddComponent(false)
        console.log(error)
        handleError(error)
      })
  }

  const [operationQuantity, setOperationQuantity] = useState(0);
  const [operationId, setOperationId] = useState('');
  const [newOperationQuantity, setNewOperationQuantity] = useState(0);
  const [isLoadingnewOperationQuantityButton, setIsLoadingNewOperationQuantityButton] = useState(false);

  function handleEditOperationQuantity(){
    setIsLoadingNewOperationQuantityButton(true)
    axios.post("/inventory_management/edit_operation_quantity", {
        id: operationId, quantity: newOperationQuantity
      })
      .then(function (response) {
        setIsLoadingNewOperationQuantityButton(false)
        handleCloseEditModal()
        setHistory(response.data.product.operations)
      })
      .catch(function (error) {
        setIsLoadingNewOperationQuantityButton(false)
        handleError(error)
      })
  }

  function deleteProductComponent(packId){
    console.log(componentName, componentQuantity)
    axios.post("/inventory_management/delete_component_to_product", {
        productId , packId, languageSelected
      })
      .then(function (response) {
        console.log(response.data.product)
        setProductComponents(response.data.product.subProducts)
      })
      .catch(function (error) {
        console.log(error)
        handleError(error)
      })
  }
  
  const [isDraft, setIsDraft] = useState(false)
  const queryParams = new URLSearchParams(location.search);
  const draft = queryParams.get('draft');
  const Type = queryParams.get('type');
  const url_id = queryParams.get('id');
  
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if(draft && draft === 'true'){
      setCreate_product('flex')
      setDelete_product('flex')
      if(url_id && url_id.length > 0){
        console.log('it is  draft!')
        setProductId(url_id)
        setIsDraft(true)
        // getProductDetails
        setIdStatus('finish')
        getProductDetails(url_id)
      }else{
        console.log('it is  draft!')
        setProductId('draft')
        setIsDraft(true)
        setIdStatus('init')
        setIsLoading(false)
      }
    }else if(url_id){
      setIsDraft(false)
      setProductId(url_id)
      setIdStatus('finish')
      getProductDetails(url_id)
    }
    
  }, [navigate]);


  useEffect(()=> {
    if(Type === 'product' || Type === 'component'){
      setType(Type)
    }
  }, [])


  const columns = [
    { field: 'id', headerName: Language['Id'], width: 100 },
    { field: 'admin', headerName:Language['Admin'], width: 180 },
    {
      field: 'createdAt',
      headerName: Language['Date'],
      width: 130,
      renderCell: (params) => {
        const year = params.row.createdAt.slice(0, 4);
        const month = params.row.createdAt.slice(5, 7);
        const day = params.row.createdAt.slice(8, 10);
        const hour = params.row.createdAt.slice(11, 13);
        const minute = params.row.createdAt.slice(14, 16);
        return  <p  style={{margin: '0px', padding: '0px'}} >{year}/{month}/{day} {hour}:{minute}</p>
      },
    },
    {
      field: 'Quantity',
      headerName: Language['Quantity'],
      width: 130,
      renderCell: (params) => {
        return <p style={{width: '100%', textAlign: 'center'}}>{params.row.operationProduct.quantity}</p>
      },
    },
    {
      field: 'operation',
      headerName: Language['Operation'],
      width: 150,
      renderCell: (params) => {
        if(params.row.type === 'received'){
          return <Button startIcon={<ArrowCircleDownOutlinedIcon/ >} style={{backgroundColor: 'rgb(162, 159, 97)'}} variant='contained' >RECEIVED</Button>
        } else if(params.row.type === 'delivered'){
          return <Button  startIcon={<ArrowCircleUpOutlinedIcon/ >} style={{backgroundColor: 'rgb(134, 192, 133)'}} variant='contained' >DELIVERED</Button>
        } else if(params.row.type === 'returned'){
          return <Button  startIcon={<ArrowCircleLeftOutlinedIcon/ >} style={{backgroundColor: 'rgb(232, 137, 137)'}} variant='contained' >RETURNED</Button>
        }
      },
        
    },
    {
      field: 'status',
      headerName: Language['Status'],
      width: 130,
      renderCell: (params) => {
        if(params.row.operationProduct.changed === true){
          return <Button color={"info"} startIcon={<CheckCircleOutlineIcon/ >} style={{color: '#bea4c0fc'}} variant='text' >{Language['modified']} </Button>
        }
        else if(params.row.valid === true){
          return <Button color={"info"} startIcon={<CheckCircleOutlineIcon/ >} style={{color: '#bea4c0fc'}} variant='text' >{Language['Valid']} </Button>
        }else{
          return <Button color={"info"} variant='text' startIcon={<RemoveCircleOutline/>} style={{color: '#bea4c0fc'}} >{Language['Draft']} </Button>
        }
      },
    },
    {
      field: 'Action',
      headerName: Language['Action'],
      width: 170,
      renderCell: (params) => {
        if(params.row.canceled && params.row.canceled === true){}
        else if(params.row.valid === true && isSuperAdmin){
        return <Tooltip title='Cancel operation'><Button onClick={() => {setOperationQuantity(params.row.operationProduct.quantity); setNewOperationQuantity(params.row.operationProduct.quantity); setOperationId(params.row.operationProduct.id); setOpenEditModal(true)}} color={"error"} startIcon={<Settings/ >}  variant='contained' >{Language['Edit']}</Button></Tooltip>
        }else{}
      },
    },
    
        

  ];

  if(isLoading === true){
    return(
      <Body>
        <Loading></Loading>
      </Body>
    )
  } else if(notFound === true){
    return(
      <Body>
        <NotFound></NotFound>
      </Body>
    )
  }else{
    return (
      <Body>
          <Header>
              <HeaderTitle>
                  <H1>
                    <span>{Language['Product details']} </span>

                    <div>
                      <Tooltip title={Language['Print']}>
                        <IconButton onClick={() => window.print()} color='info' style={{ margin: '5px 5px 5px 10px', height :'min-content', alignItems: 'right'}} variant="contained" size='small'><PrintIcon /></IconButton>
                      </Tooltip>
                    </div>
                    
                  </H1>
              </HeaderTitle>
          </Header>
          <BodyContent>
              <Form>
                <ProductHeader>
                  <ProductHeaderContainerImage>
                    <ProductHeaderImage url={image} onload="window.print()"></ProductHeaderImage>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    <LoadingButton variant='outlined' loading={isLoadingUploadImage} loadingPosition='center' style={{display: create_product}} onClick={handleFileSelect}>{Language['upload image']}</LoadingButton>
                  </ProductHeaderContainerImage>
                  <ProductHeaderContainerDetails>
                      <SmallTitle>
                        <SmallTitleSpan></SmallTitleSpan>
                        <SmallTitleText>{Language['General']}</SmallTitleText>
                        <SmallTitleSpan></SmallTitleSpan>
                      </SmallTitle>
                    <ProductHeaderContainerDetailsElement style={{flexWrap: 'wrap'}}>
                      <MyTextField id="standard-basic" variant="standard" label={Language['Name']} defaultValue={name} error={nameIsError} helperText={nameError} onChange={(e) => {handleChangeProductInfo("name", e.target.value)}} value={name} />
                    </ProductHeaderContainerDetailsElement>

                    <ProductHeaderContainerDetailsElement>
                      {/* <MyLabel>Reference</MyLabel> */}
                      <MyTextField  onChange={(e) => {handleChangeProductInfo('reference', e.target.value)}} id="standard-basic" variant="standard" label={Language['Reference']} error={referenceIsError} helperText={referenceError} value={reference} />
                    </ProductHeaderContainerDetailsElement>

                    <ProductHeaderContainerDetailsElement>
                      {/* <MyLabel>Price</MyLabel> */}
                      <MyTextField  onChange={(e) => {handleChangeProductInfo('price', e.target.value)}} id="standard-basic" variant="standard" label={Language['Price']} error={priceIsError} helperText={priceError} defaultValue={formatNumberWithMinimumDigits(price)} value={price} />
                    </ProductHeaderContainerDetailsElement>


                    

                    <ProductHeaderContainerDetailsElement style={{justifyContent: 'end', padding: '10px 0px'}}>
                      <Button color='error' variant='outlined' disabled={!allowDelete}  style={{ marginRight: '5px', display: delete_product }}  onClick={() => setOpenDeleteModal(true)}>{Language['Delete']}</Button>
                      <Button variant='contained' disabled={!allowEdit} style={{ display: create_product }} onClick={saveProduct} >{Language['Save']}</Button>

                    </ProductHeaderContainerDetailsElement>


                  </ProductHeaderContainerDetails>
                </ProductHeader>

                
                <AddedComponentsContainer style={{display: showAddComponentsContainer}}>
                      <SmallTitle>
                        <SmallTitleSpan></SmallTitleSpan>
                        <SmallTitleText>{Language['Components']}</SmallTitleText>
                        <SmallTitleSpan></SmallTitleSpan>
                      </SmallTitle>
                      <List
                          sx={{ width: 'calc(100% - 20px)',  bgcolor: 'background.paper' }}
                          style={{backgroundColor: "#cdcdcd10", border: '1px solid #6a696938', padding: '10px'}}
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                          subheader={
                            <>
                            <Button onClick={()=> {navigate('/app/Inventory_management/new_product', {replace: true})} } style={{float: 'right', textDecorationLine: 'none'}}>{Language['Create a new component']}</Button>
                            <AddedComponents style={{ paddingBottom: '20px', display: create_product}}>
                              {/* <MyLabel>Price</MyLabel> */}
                              <Stack style={{ width: '50%', marginRight: "10px" }} spacing={0} >
                                <Autocomplete
                                  id="free-solo-demo"
                                  onChange={(e)=> setComponentName(e.target.innerText)}
                                  options={components.map((option) => option.name)}
                                  renderInput={(params) => <TextField variant="standard"   {...params} label={Language['Product']} />}
                                />
                                
                              </Stack>
                              <MyTextField id="standard-basic" type='number' onChange={(e)=> setComponentQuantity(e.target.value)} variant="standard" label={Language['Quantity']} style={{width: '30%', marginRight: "10px"}}/>
                              <LoadingButton variant='contained' disabled={!allowEdit} startIcon={<AddCircleOutlineIcon/>} loading={isLoadingAddComponent} loadingPosition='start' onClick={(e) => addComponent(e)} style={{backgroundColor: allowEdit ? '#64257e' : '#91739e6c', color:'white'}}>{Language['Add']}</LoadingButton>
                            </AddedComponents>
                            </>
                            
                          }
                        >
                          <ListItemButton onClick={handleClick}>
                            <ListItemIcon>
                              <CategoryIcon />
                            </ListItemIcon>
                            <ListItemText primary={<>{Language['Components']} </>} />
                            {open ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                            {
                              productComponents.map((data) => {
                                var quantity = 0
                                if (data.productPack){
                                  quantity = data.productPack.quantity
                                }
                                return (
                                  <ProductComponentContainer style={{width: '90%', padding: "5px 5%", borderBottom: '1px solid whitesmoke'}} sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                      <CheckCircleOutlineIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={
                                      <ProductComponentContainer>
                                        <ProductComponentContainerTitle onClick={()=> window.location.search = '?id=' + data.id } style={{ cursor: 'pointer'}}>
                                          <div >
                                            {data.name} 
                                            <span style={{color: 'gray', fontSize: "small"}}> {quantity} {Language['pieces']}</span>
                                          </div >
                                          <ProductComponentContainerSubTitle>
                                            <span style={{color: 'gray', fontSize: "small"}}><strong>{Language['Stock']}:</strong> {data.quantity} {Language['pieces']}</span>
                                          </ProductComponentContainerSubTitle>  
                                        </ProductComponentContainerTitle>
                                        <IconButton disabled={!allowEdit} onClick={()=> deleteProductComponent(data.id)}><DeleteIcon /></IconButton> 
                                      </ProductComponentContainer>
                                    } />
                                  </ProductComponentContainer>

                                

                                )
                                
                              })
                            }
                          
                            </List>
                          </Collapse>
                        </List>

                </AddedComponentsContainer>

                <SmallTitle>
                  <SmallTitleSpan></SmallTitleSpan>
                  <SmallTitleText>{Language['Operation']}</SmallTitleText>
                  <SmallTitleSpan></SmallTitleSpan>
                </SmallTitle>


                <Box sx={{ width: '100%', typography: 'body1' }} style={{ backgroundColor: "#cdcdcd10", border: '1px solid #6a696938'}}>
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label={Language['Received']} value="1" />
                        <Tab label={Language['Delivered']} disabled={!allowDelivered} value="2" />
                        <Tab label={Language['Returned']} disabled={!allowReturned} value="3" />
                      </TabList>
                    </Box>

                    
                    <TabPanel value="1">
                      <AddedComponents style={{ paddingBottom: '20px'}}>
                        <MyTextField type='number' value={receivedQuantity} id="standard-basic" helperText={Language['unlimited']} onChange={(e)=> setReceivedQuantity(e.target.value)}  variant="standard" label={Language['Quantity']} style={{width: '30% ', marginRight: "10px", marginBottom: '10px', flexGrow: 'unset'}}/>
                        <LoadingButton variant='contained' disabled={!allowReceived} onClick={()=> makeOperation('received', receivedQuantity )} loading={isLoadingAddReceived} loadingPosition='start' startIcon={<AddCircleOutlineIcon/>} style={{backgroundColor: allowReceived ?  '#64257e' : '#d5d5d5', color: 'white',marginBottom: '20px',}}>{Language['Received']}</LoadingButton>
                        <AvailableNumberContainer>
                            <AvailableNumberTitle>{Language['Total inventory']}</AvailableNumberTitle>
                            <AvailableNumberDesc>{total} {Language['pieces']}</AvailableNumberDesc>
                        </AvailableNumberContainer>
                      </AddedComponents>
                    </TabPanel>


                    <TabPanel value="2">
                      <AddedComponents style={{ paddingBottom: '20px'}}>
                        <MyTextField type='number' value={deliveredQuantity} id="standard-basic" helperText={limitDelivered + Language['units']} onChange={(e)=> setDeliveredQuantity(e.target.value)}  variant="standard" label={Language['Quantity']} style={{width: '30% ', marginRight: "10px", marginBottom: '10px', flexGrow: 'unset'}}/>
                        <LoadingButton variant='contained' disabled={!allowDelivered} onClick={()=> makeOperation('delivered', deliveredQuantity )} loading={isLoadingAddDelivered} loadingPosition='start' startIcon={<AddCircleOutlineIcon/>} style={{backgroundColor: '#64257e', color: 'white',marginBottom: '20px',}}>{Language['Delivered']}</LoadingButton>
                        <AvailableNumberContainer>
                            <AvailableNumberTitle>{Language['Total inventory']}</AvailableNumberTitle>
                            <AvailableNumberDesc>{total} {Language['pieces']}</AvailableNumberDesc>
                        </AvailableNumberContainer>
                      </AddedComponents>
                    </TabPanel>



                    <TabPanel value="3">
                      <AddedComponents style={{ paddingBottom: '20px'}}>
                        <MyTextField type='number' value={returnedQuantity} id="standard-basic" helperText={ limitReturned + Language['units']} onChange={(e)=> setReturnedQuantity(e.target.value)}  variant="standard" label='Quantity' style={{width: '30% ', marginRight: "10px", marginBottom: '10px', flexGrow: 'unset'}}/>
                        <LoadingButton variant='contained' disabled={!allowReturned} onClick={()=> makeOperation('returned', returnedQuantity )} loading={isLoadingAddReturned} loadingPosition='start' startIcon={<AddCircleOutlineIcon/>} style={{backgroundColor: '#64257e', color: 'white',marginBottom: '20px',}}>{Language['Returned']}</LoadingButton>
                        <AvailableNumberContainer>
                            <AvailableNumberTitle>{Language['Total inventory']}</AvailableNumberTitle>
                            <AvailableNumberDesc>{total} {Language['pieces']}</AvailableNumberDesc>
                        </AvailableNumberContainer>
                      </AddedComponents>
                    </TabPanel>


                  </TabContext>
                </Box>

                
                <SmallTitle>
                  <SmallTitleSpan></SmallTitleSpan>
                  <SmallTitleText>{Language['History']}</SmallTitleText>
                  <SmallTitleSpan></SmallTitleSpan>
                </SmallTitle>

                <div 
                style={{
                  width: '100',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end',
                  margin: '10px 0px',
                }}
                >
                  <LocalizationProvider variant='standard' dateAdapter={AdapterDayjs} >
                    <DatePicker 
                      label={Language['Select Date']}
                      onChange={(date) => setFilterDate(date)}
                      renderInput={(params) => (
                        <TextField {...params} style={{height: 'max-content'}}  />
                      )}
                    />
                  </LocalizationProvider>
                  <Button disabled={!allowFilter} onClick={() => filterHistory('filter')} variant='outlined' style={{marginLeft: '10px'}}>{Language['Filter']}</Button>
                  <Tooltip title={Language['Reset']}>
                  <IconButton disabled={!allowFilter} size='small' onClick={() => filterHistory('reset')} variant='outlined' style={{marginLeft: '10px', height: 'min-content !important', width: 'min-content', alignSelf: 'end', display: showReset}}><RotateLeftIcon/></IconButton>
                  </Tooltip> 
                </div>


                <div style={{ height: '400px', width: '100%', marginTop: '10px' }}>            

                  <DataGrid
                    style={{backgroundColor: "#cdcdcd10"}}
                    rows={history}
                    columns={columns}
                    disableSelectionOnClick
                    disableSelection
                    loading={isLoadingHistory}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 7 },
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                    sortModel={[{field: 'createdAt',sort: 'desc'}]}
                  />
                </div>

              

              </Form>
          </BodyContent>

            {/* // snackbar */}
            <Snackbar
              open={openAlert}
              autoHideDuration={4000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                {alertMessage}
              </Alert>
            </Snackbar>



            <Dialog
              fullScreen={fullScreen}
              open={openDeleteModal}
              onClose={handleCloseDeleteModal}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">
                {Language['Delete product']}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                {Language['Are you sure you want to permanently delete this product?']}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button autoFocus color='error' onClick={handleCloseDeleteModal}>
                {Language['Cancel']}
                </Button>
                <Button onClick={deleteProduct} color='error' variant='contained' autoFocus>
                {Language['Delete']}
                </Button>
              </DialogActions>
            </Dialog>

           
            
            <Dialog open={openEditModal} onClose={handleCloseEditModal} fullScreen={fullScreen} fullWidth={true}>
              <DialogTitle>{Language['Change the quantity']}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {Language['The super admin is the only user who can change the quantity.']} 
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Quantity"
                  type="number"
                  fullWidth
                  variant="standard"
                  value={newOperationQuantity}
                  onChange={(e)=> setNewOperationQuantity(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseEditModal}>{Language['Cancel']}</Button>
                <LoadingButton loading={isLoadingnewOperationQuantityButton} loadingPosition='center' onClick={() => {handleEditOperationQuantity()}}>{Language['Save']}</LoadingButton>
              </DialogActions>
            </Dialog>

      </Body>
    )
  }
  
  
}
