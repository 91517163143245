export function getResponseMessage(responseCode) {
    if (responseCode >= 400) {
      switch (responseCode) {
        case 400:
          return "Bad Request: The server cannot process the request due to an invalid syntax.";
        case 401:
          return "Unauthorized: Authentication is required, and the user has not provided valid credentials.";
        case 403:
          return "Forbidden: The server understood the request but refuses to authorize it.";
        case 404:
          return "Not Found: The server cannot find the requested resource.";
        case 405:
          return "Method Not Allowed: The request method is not supported for the requested resource.";
        case 429:
          return "Too Many Requests: The user has sent too many requests in a given amount of time.";
        case 500:
          return "Internal Server Error: The server has encountered a situation it doesn't know how to handle.";
        case 503:
          return "Service Unavailable: The server is currently unable to handle the request.";
        default:
          return "Error: There was an error processing your request.";
      }
    } else {
      return "An error has occured.";
    }
  }