import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './components/login';
import Signup from './components/signup';
import React, { useState } from 'react';
import ConfirmEmail from './components/confirmEmail';
import InitInfo from './components/init_account';
import Home from './components/home';
import InventoryManagement from './components/Inventory_management/InventoryManagement';
import './App.css'
import './App.css'
import Hello from './components/hello';
import Editor from './components/editor';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ForgotPassword from './components/forgot_password';
import CheckResetPassword from './components/checkResetPassword';
import NotFound from './components/404';
import styled from 'styled-components'
import english from './Language/english';
import chinese from './Language/chinese';
import french from './Language/french';
import InvoiceManagement from './components/Invoice_management/InvoiceManagement';
import CostumerManagement from './components/costumer_management/CostumerManagement';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Title = styled.h1`
    font-size: large;
    color: #0f0b0e ;
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 30px;
`


function App() {
  const [url, setUrl] = useState('localhost:3000')

  // send feedback
  const [open, setOpen] = useState(false);

  const handleClickOpenUserModal = () => {
      setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [ openAlert, setOpenAlert] = useState(false)
  


  // conditions and terms
  const [openTermsModal, setOpenTermsModal] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleCloseTermsModal = () => {
    setOpenTermsModal(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openTermsModal) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openTermsModal]);


  React.useEffect(() => {
    const language = localStorage.getItem('language');
    if(language === "chinese" || language === 'english' || language === 'french'){
      changeLanguage(language)
    }
  }, []);


  const [language, setLanguage] = useState(english);
  const [languageSelected, setLanguageSelected] = useState("english");
  function changeLanguage(language){
    if(language === "english"){
      setLanguage(english)
      setLanguageSelected('english')
    }else if(language === "chinese"){
      setLanguage(chinese)
      setLanguageSelected('chinese')
    }else if(language === "french"){
      setLanguage(french)
      setLanguageSelected('french')
    }else{
      setLanguage(english)
      setLanguageSelected('english')
    }
  }


  return (
    <Router style={{position: 'relative'}}>
      <Routes>
        {/* <Route axact path="/*" element={ < Panel /> } ></Route> */}
        {/* <Route axact path="/body" element={ < Body /> } ></Route> */}
        <Route axact path="/" element={ < Hello /> } url={url} ></Route>
        <Route axact path="/login" element={ < Login setOpenTermsModal={setOpenTermsModal} /> } ></Route>
        <Route axact path="/signup" element={ < Signup setOpenTermsModal={setOpenTermsModal} /> } url={url} ></Route>
        <Route axact path="/forgot_password" element={ < ForgotPassword  setOpenTermsModal={setOpenTermsModal} /> } url={url} ></Route>
        <Route axact path="/confirm_email" element={ < ConfirmEmail  setOpenTermsModal={setOpenTermsModal}  /> } url={url} ></Route>
        <Route axact path="/check_reset_password" element={ < CheckResetPassword  setOpenTermsModal={setOpenTermsModal} /> } url={url} ></Route>
        <Route axact path="/init_info" element={ < InitInfo  setOpenTermsModal={setOpenTermsModal} /> }  ></Route>
        <Route axact path="/app/*" element={ < Home Language={language} changeLanguage={changeLanguage} languageSelected={languageSelected} /> }  ></Route>
        <Route axact path="/app/Inventory_management/*" element={ < InventoryManagement  Language={language} changeLanguage={changeLanguage} languageSelected={languageSelected}/> }  ></Route>
        <Route axact path="/app/Costumer_management/*" element={ < CostumerManagement  Language={language} changeLanguage={changeLanguage} languageSelected={languageSelected}/> }  ></Route>
        <Route axact path="/app/Invoice_management/*" element={ < InvoiceManagement  Language={language} changeLanguage={changeLanguage} languageSelected={languageSelected}/> }  ></Route>
        <Route axact path="/*" element={ < NotFound /> }  ></Route>
      </Routes>



       {/* // snackbar */}
       <Snackbar
          open={openAlert}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Snackbar>


        {/* terms & conditions */}
        <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={openTermsModal}
        onClose={handleCloseTermsModal}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Terms & Conditions</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
    
          <Title>User registration</Title>
          <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et</p>
              

          <Title>Terms of use</Title>
          <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et</p>
          <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et</p>
    

          <Title>Delete Contacts</Title>
          <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et</p>
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTermsModal}>Close</Button>
        </DialogActions>
      </Dialog>

    </Router>
  );
}

export default App;
