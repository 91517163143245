import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components'

const LoadingComp = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default function NotFound() {
  return (
    <LoadingComp>
      <p style={{padding: '0px ', margin: '5px 0px', fontSize: 'xx-large', fontWeight: '600'}}>404 Error</p>  
      <p style={{padding: '0px ', margin: '5px 0px', fontSize: 'medium', textAlign: "center"}}>the page has been removed or the URL has changed. Please verify the URL or try again later.</p>  
    </LoadingComp>
  )
}
