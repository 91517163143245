const chinese = {
    'Welcome Hengxing Al Sinaiya Star ERP': '欢迎使用Hengxing Al Sinaiya Star ERP',
    'The best factory management platform for you.': '为您提供最佳的工厂管理平台。',
    'Inventory management': '库存管理',
    'Costumer management': '客户管理',
    'Invoice management': '发票管理',
    'TOTAL INVENTORY': '总库存',
    'Total products': '产品总数',
    'RECEIVED': '已收货',
    'Pieces': '件数',
    'Delivered': '已交付',
    'Total delivered product': '已交付产品总数',
    'Returned': '已退货',
    'Total returned products': '已退货产品总数',
    'Home': '首页',
    'Products': '产品',
    'New Product': '新产品',
    'received': '已收货',
    'delivered': '已交付',
    'returned': '已退货',
    'Operation': '操作',
    'operations': '操作',
    'New Operation': '新操作',
    'Print': '打印',
    'Export': '导出',
    'Import': '导入',
    'Id': '编号',
    'Admin': '管理员',
    'Reference': '参考',
    'Date': '日期',
    'Costumer': '客户',
    'Type': '类型',
    'draft': '草稿',
    'complited': '已完成',
    'Action': '操作',
    'Details': '详情',
    'Facturation': '发票',
    'Billing': '账单',
    'Draft': '草稿',
    'Quantity': '数量',
    'Status': '状态',
    'Valid': '有效',
    'Product details': '产品详情',
    'upload image': '上传图片',
    'General': '常规',
    'Name': '名称',
    'Component': '组件',
    'Product': '产品',
    'Price': '价格',
    'Delete': '删除',
    'Save': '保存',
    'Components': '组件',
    'Add': '添加',
    'pieces': '件',
    'Received': '已收货',
    'unlimited': '无限制',
    'Total inventory': '总库存',
    'units': '个',
    'History': '历史',
    'Select Date': '选择日期',
    'Filter': '筛选',
    'Delete product': '删除产品',
    'Are you sure you want to permanently delete this product?': '您确定要永久删除此产品吗？',
    'Cancel': '取消',
    'Stock': '库存',
    'Operation details': '操作详情',
    'Operation type': '操作类型',
    'Operation reference': '操作参考',
    'Client': '客户',
    'Local operation': '本地操作',
    'Full name': '全名',
    'Address': '地址',
    'Created by': '创建者',
    'Add operation': '添加操作',
    'It will be generated after clicking save..': '保存后将生成..',
    'User': '用户',
    'Maximum units': '最大数量',
    'URL Not Found!': 'URL未找到！',
    'The requested url not found, it seems like it changed or deleted.': '请求的URL未找到，似乎已更改或删除。',
    'Apps': '应用',
    'Profile': '个人资料',
    'Logout': '退出登录',
    'My account': '我的账户',
    'Tax identification details': '税务识别详情',
    'Email': '电子邮件',
    'Company': '公司',
    'Bills': '账单',
    'First collaboration': '首次合作',
    'costumers': '客户',
    'Costumer operations': '客户操作',
    'Close': '关闭',
    'paid': '已支付',
    'unpaid': '未支付',
    'canceled': '已取消',
    'Total': '总计',
    'Show': '显示',
    'All': '全部',
    'Paid': '已支付',
    'Unpaid': '未支付',
    'Canceled': '已取消',
    'Save & Print': '保存并打印',
    'Choose products': '选择产品',
    'Imported products': '导入的产品',
    'Update existing products': '更新现有产品',
    'Products details': '产品详细信息',
    'Related To': '相关产品',
    'Message': '消息',
    'Already exist': '已存在',
    'Related to unfound product': '关联到未找到的产品',
    'Operations details': '操作细节',
    'an error has occured.': '发生了错误。',
    'internal server error.': '内部服务器错误。',
    'too many requests.': '请求过多。',
    'No row selected.': '未选择任何行。',
    'You should select a product.': '您应该选择一个产品。',
    'Changed successfully': '修改成功',
    'Auto-save not allowed.': '不允许自动保存。',
    'Not allowed.': '不允许。',
    'Deleted successfully.': '删除成功。',
    'Maximum units reached.': '已达到最大单位数。',
    'The image size is too large.': '图片尺寸太大。',
    'Image only.': '仅限图片。',
    'An error has occured while uploading the image.': '上传图片时发生错误。',
    'Not found.': '未找到。',
    'you should select a product.': '您应该选择一个产品。',
    'Product not found.': '未找到产品。',
    'Already added!': '已经添加！',
    'Operation not found.': '未找到操作。',
    'CSV file only.': '仅限CSV文件。',
    'Type value is not valid.': '类型值无效。',
    'Type is required.': '类型是必需的。',
    'Invalid status.': '无效的状态。',
    'InvoiceId is required.': '发票ID是必需的。',
    '': '',
    'Saved successfully.': '保存成功。',
    'save': '保存',
    'Disconnect': '断开连接',
    'Personal information': '个人信息',
    'Security': '安全',
    'First name': '名字',
    'Family name': '姓氏',
    'Save your information': '保存您的信息',
    'Actuel password': '当前密码',
    'New password': '新密码',
    'Confirm new password': '确认新密码',
    'Save password': '保存密码',
    'Bad Request: The server cannot process the request due to an invalid syntax.': '错误的请求：由于无效的语法，服务器无法处理请求。',
    'Unauthorized: Authentication is required, and the user has not provided valid credentials.': '未经授权：需要进行身份验证，用户未提供有效的凭据。',
    'Forbidden: The server understood the request but refuses to authorize it.': '禁止访问：服务器理解请求但拒绝授权。',
    'Not Found: The server cannot find the requested resource.': '未找到：服务器无法找到请求的资源。',
    'Method Not Allowed: The request method is not supported for the requested resource.': '不允许的方法：请求的方法不支持请求的资源。',
    'Too Many Requests: The user has sent too many requests in a given amount of time.': '请求过多：用户在指定的时间内发送了过多的请求。',
    'Internal Server Error: The server has encountered a situation it does not know how to handle.': '服务器内部错误：服务器遇到无法处理的情况。',
    'Service Unavailable: The server is currently unable to handle the request.': '服务不可用：服务器当前无法处理请求。',
    'Error: There was an error processing your request.': '错误：处理您的请求时出错。',
    'An error has occurred.': '发生了错误。',
    'Logout successfully.': '成功登出。',
    'string only': '仅限字符串',
    'name is required.': '名字是必需的。',
    'enter a valid name': '请输入有效的名字',
    'family name is required.': '姓氏是必需的。',
    'enter a valid family name': '请输入有效的姓氏',
    'your information saved successfully.': '您的信息保存成功。',
    'password is required.': '密码是必需的。',
    'enter a valid password': '请输入有效的密码',
    'new password is required.': '新密码是必需的。',
    'password confirmation is required.': '需要确认密码。',
    'Passwords do not match': '密码不匹配',
    'incorrect password': '密码不正确',
    'home': '首页',
    'products': '产品',
    'Privileges': '特权',
    'Edit': '编辑',
    'Create user': '创建用户',
    'After clicking create button, the user will receive an email with a personalized signup link.': '点击创建按钮后，用户将收到一封带有个性化注册链接的电子邮件。',
    'Email Address': '电子邮件地址',
    'User privilege': '用户权限',
    'Add products': '添加产品',
    'Edit products': '编辑产品',
    'Delete products': '删除产品',
    'Create': '创建',
    'Edit user': '编辑用户',
    'After clicking save, privileges for this user will be changed.': '点击保存后，该用户的权限将被更改。',
    'Be careful': '请小心',
    'Delete user': '删除用户',
    'Please be aware that this action is irreversible and you are about to permanently delete': '请注意，此操作不可逆，您即将永久删除',
    'Users management': '用户管理',
    'Email is not valid.': '电子邮件地址无效。',
    'Email already exists.': '电子邮件已存在。',
    'Active': '活动的',
    'Invited': '已邀请',
    'Total delivered products': '已交付的产品总数',
    'DELIVERED': '已交付',
    'RETURNED': '已退回',
    'Changes saved': '更改已保存',
    'Product is undefined, refresh your page and try again.': '产品未定义，请刷新页面并重试。',
    'You should select a component first.': '您应该先选择一个组件',
    'Reset': '重置',
    'Change the quantity': '更改数量',
    'The super admin is the only user who can change the quantity.': '超级用户是唯一可以更改数量的用户。',
    'modified': '它修改了',
    'Item not found.': '未找到项目。',
    'The quantity value should be a non-negative number.': '数量值应为非负数',
    '': '',
    '': '',
    'Can not perform this operation.': '无法执行此操作。',
    'component': '元件',
    'product': '产品',
    'Create a new component': '创建新组件',

  }
  
  export default chinese;