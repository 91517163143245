import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import {Button, Typography} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../Loading';
import NotFound from '../notFound';
import { LoadingButton } from '@mui/lab';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Body = styled.div`
    width: 100%;
`

const Header = styled.div`
    height: 35px;
    width: 96%;
    padding: 10px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px #e1e1e1 solid;
    @media only screen and (max-width: 850px) {
        height: max-content;
    }
`
const HeaderTitle = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: center;
    @media only screen and (max-width: 850px) {
        width: 100% !important;
        flex-direction: row;
        flex-wrap: wrap;
    }
`


const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    @media only screen and (max-width: 850px) {
      width: max-content;
    }
`

const BodyContent = styled.div`
    position: relative;
    width: 100%;
    background-color: #f6f7fa;
    min-height: calc(100vh - 122px - 20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    flex-grow: 3;
    padding-bottom: 25px;
    @media only screen and (max-width: 850px) {
    }
`

const Form = styled.div`
    width: calc(90% - 40px) ;
    margin: 0px 5%;
    background-color: white;
    min-height: max-content;
    box-shadow: 0px 0px 1px 0px grey;
    padding: 10px 20px;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    @media only screen and (max-width: 850px) {
      width: calc(90% - 10px) ;
      padding: 10px 5px;

    }
`

const DetailContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-left: 0px;

`

const DetailTitle = styled.p`
  margin: 0% 10px 0px 0px;
  padding: 0%;
  color: #808080;
  font-size: medium;
`
const DetailDesc = styled.p`
  margin: 0%;
  padding: 0;
  color: #000000;
  font-size: medium;
`

const ClinetContainer = styled.div`
  padding: 10px;
  width: calc(100% - 20px);
  margin-bottom: 0px;
  transition: 200ms ease-in-out;
  
`

const ClinetContainer1 = styled.div`
  background-color: #cdcdcd10;
  border: 1px solid #6a696938;
  padding: 10px;
  width: calc(100% - 20px);
  margin-bottom: 10px;
  
`

const MyTextField = styled(TextField)`
  //width: 85%;
  min-width: 300px !important;
  padding: 0%;
  margin: 0%;
  flex-grow: 1;
  width: 100%;
`

const SmallTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 25px 0px 10px 0px;
`
const SmallTitleText = styled.p`
  margin: 0%;
  padding: 0% 10px;
  text-align: center;
  width: max-content;
  color: #a947c7
`
const SmallTitleSpan = styled.span`
  height: 1px;
  width: 50%;
  background-color: #dcaeedc2;
`

const AddedComponents = styled.div`
  position: relative;
  padding-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  margin-bottom: 10px;
  flex-wrap: wrap;
  //background-color: #fdf0ffae;
`


const AvailableNumberContainer = styled.div`
  position: absolute;
  top: -5px;
  right: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-left: 10px;
`

const AvailableNumberTitle = styled.p`
  margin: 0% 10px 0px 0px;
  padding: 0;
  color: #808080;
  font-size: medium;
`
const AvailableNumberDesc = styled.p`
  margin: 0%;
  padding: 0;
  color: #000000;
  font-size: medium;
`



export default function NewOperation({handleHeader, handleError, userName, Language, isSuperAdmin}) {

  const navigate = useNavigate()
  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [ openAlert, setOpenAlert] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const [checked, setChecked] = React.useState(true);
  const [localFactory, setLocalFactory] = React.useState('flex');
  const [externClient, setExternClient] = React.useState('none');
  
  useEffect(() => {
    if(checked){
      setLocalFactory('flex')
      setExternClient('none')
    }else{
      setLocalFactory('none')
      setExternClient('flex')
    }
  }, [checked]);
  
  const handleChange = (event) => {
    
    setChecked(event.target.checked);
    setCountChanges(countChanges + 1 )
  };


  const columns = [
    { field: 'id', headerName: Language['Id'], width: 100 },
    { field: 'name', headerName: Language['Name'], width: 180 },
    { field: 'reference', headerName: Language['Reference'], width: 180 },
    {
      field: 'Quantity',
      headerName: Language['Quantity'],
      sortable: false,
      width: 130,
      valueGetter: (params) =>{
        if(params.row.operationProduct){
          return params.row.operationProduct.quantity
        }else{
          return '--'
        }
        
      }
    },
    {
      field: 'Price',
      headerName: Language['Price'],
      sortable: false,
      width: 130,
      valueGetter: (params) =>{
        if(params.row.operationProduct){
          function formatNumberWithMinimumDigits(number) {
            let decimalPlaces = parseFloat(number).toString().split('.')[1];
            let minDigits = decimalPlaces ? Math.max(decimalPlaces.length, 2) : 2;
            let formattedNumber = parseFloat(number).toFixed(minDigits);
            return formattedNumber.replace(/(\.\d*?[1-9])0+$/, '$1');
          }
          return formatNumberWithMinimumDigits(params.row.operationProduct.price)
        }else{
          return '--'
        }
        
      }
    },
    { field: 'quantity', headerName: Language['Stock'], width: 120 },
    { field: 'createdByAdmin', headerName: Language['Created by'], width: 180 },
    {
      field: 'Action',
      headerName: Language['Action'],
      sortable: false,
      width: 130,
      renderCell: (params) => {
          return <LoadingButton color='error' loading={false} loadingPosition='center' variant='contained' onClick={()=> DeleteProductFromDraftOperation(params.row.id)} >Delete</LoadingButton>
      },
    },
  ];
  

  const [loadingDelete, setLoadingDelete] = React.useState(false);


  function DeleteProductFromDraftOperation(id){
    console.log(id)
    setLoadingDelete(true)
    axios
      .post("/inventory_management/DeleteProductFromDraftOperation", { operationId, productId: id})
        .then(function (response) {
          setLoadingDelete(false)
          setoperationProducts(response.data.products)
      })
      .catch(function (error) {
        // handle error
        setLoadingDelete(false)
        handleError(error)
      })
  }


  function CancelOperation(){
    axios
      .post("/inventory_management/CancelOperation", { operationId})
        .then(function (response) {
          navigate('/app/inventory_management/history?type=' + pageTitle, {replace: true})
      })
      .catch(function (error) {
        // handle error
        handleError(error)
      })
  }

  const [loadingSave, setLoadingSave] = React.useState(false);

  function SaveOperation(){
    setLoadingSave(true)
    axios
      .post("/inventory_management/SaveOperation", { operationId })
        .then(function (response) {
          navigate('/app/inventory_management/operation?id=' + operationId, {replace: true})
      })
      .catch(function (error) {
        // handle error
        setLoadingSave(false)
        handleError(error)
      })
  }


  const rows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  ];


  const [products, setProducts] = React.useState([]);
  const [costumers, setCostumers] = React.useState([]);
  const [allowUserTypeNonSavedProduct, setAllowUserTypeNonSavedProduct] = React.useState(false);
  
  function getProducts (){
    axios
      .post("/inventory_management/new_operation", { operation: type})
        .then(function (response) {
          setIssLoading(false)
          console.log(response.data)
          setProducts(response.data.products)
          setCostumers(response.data.costumers)
      })
      .catch(function (error) {
        // handle error
        setIssLoading(false)
        handleError(error)
      })
  }

  const [nonFound, setNonFound] = React.useState(false);
  
  function getOperation (){
    console.log('************ getOperation Id after sending request ***************')
    console.log(operationId)
    axios
      .post("/inventory_management/draft_operation_details", { operationId: id })
        .then(function (response) {
          // console.log('********* operation details ***********')
          // console.log(response)
          console.log('************ getOperation Id after sending request ***************')
          console.log(operationId)

          console.log('************ getOperation new Id after sending request ***************')
          console.log(response.data.operation)

          if(response.data.costumer){
            // updating costumer info
            console.log('updating costumer info')
            const costumer = response.data.costumer
            setCostumerId(costumer.id)
            setCostumerName(costumer.name)
            setCostumerAddress(costumer.address)
            setCostumerRC(costumer.rc)
            setCostumerNIF(costumer.nif)
            setCostumerNIS(costumer.nis)
            setCostumerAI(costumer.ai)
          }
          if(response.data.operation){
            const operation = response.data.operation
            // updating operation info
            console.log('updating operation info')
            setChecked(operation.externClient)
            // products
          }
          setoperationProducts(response.data.products)
          getProducts()
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        if(error.response.status === 404){
          setNonFound(true)
          setIssLoading(false)
        }
        setIssLoading(false)
        handleError(error)
      })
  }


  const [operationId, setOperationId] = useState('');
  const [costumerId, setCostumerId] = useState('');

  
  const [selectedOption, setSelectedOption] = useState();
  const [productName, setProductName] = useState('');
  const [selectedOptionObject, setSelectedOptionObject] = useState();
  const [allowedQuantity, setAllowedQuantity] = useState(0);



  const handleOptionChange = (event, value) => {
    setSelectedOption(value);
    setProductName(value.name)
    if(value.price){
      var n = parseFloat(value.price)
      setPrice(n.toFixed(2))
    }
    
    if(pageTitle === 'received') {
      setAllowedQuantity('unlimited')
    }else if(pageTitle === 'delivered') {
      setAllowedQuantity(value.delivered)
    }else if(pageTitle === 'returned') {
      setAllowedQuantity(value.returned)
    }
  };



  const [selectedOptionCostumer, setSelectedOptionCostumer] = useState();
  const [costumerName, setCostumerName] = useState('');
  const [costumerAddress, setCostumerAddress] = useState('');
  const [costumerRC, setCostumerRC] = useState('');
  const [costumerNIF, setCostumerNIF] = useState('');
  const [costumerAI, setCostumerAI] = useState('');
  const [costumerNIS, setCostumerNIS] = useState('');

  const handleOptionChangeCostumer = (event, value) => {
    console.log('changing data')
    setSelectedOptionCostumer(value);
    setCostumerName(value.name)
    setCostumerAddress(value.address)
    setCostumerRC(value.rc)
    setCostumerNIF(value.nif)
    setCostumerAI(value.ai)
    setCostumerNIS(value.nis)
    setCountChanges(countChanges + 1 )
  };

  const [operationProducts, setoperationProducts] = useState([]);
 
  function addProduct(){
    var isNewProduct = true
    if(selectedOption){
      if(selectedOption.name === productName){
        console.log('exists')
        isNewProduct= false
      }else {
        console.log('new product')
        isNewProduct = true
      }
    }else{
      console.log('new product')
      isNewProduct = true
    }   
    // send request..
    if(isNewProduct && ( pageTitle === 'returned' || pageTitle === 'delivered')){
      setOpenAlert(true)
      setAlertMessage(Language['You should select a product.'])
      setAlertType("error")
      setTimeout(() => {
        setOpenAlert(false)
      }, 3000);
    }else{
      axios
      .post("/inventory_management/add_product_to_draft_operation", {externClient: checked, type: pageTitle , operationId, isNewProduct, productName, selectedProduct: selectedOption, quantity, price })
        .then(function (response) {
          // console.log(response)
          setoperationProducts(response.data.products)
          if(operationId === ''){
            setOperationId(response.data.operationId)
            navigate("/app/Inventory_management/new_operation?type="+ pageTitle +"&id=" + response.data.operationId, {replace: true})
          }
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        setIssLoading(false)
        if(error.response.status === 400 && error.response.data.response === 'admin_required'){
            setChecked(true)
          }
        handleError(error)
      })
    }
    
  }

  const [countChanges, setCountChanges] = useState(0);
  const [sending, setSending] = useState("init");


  useEffect(() => {
    saveDraft()
  }, [countChanges]);


  function saveDraft(){
    var name = costumerName
    console.log(name)
    if(countChanges > 0){
      if(sending === 'init'){
        setSending('stopped')
      }
      axios
        .post("/inventory_management/save_draft_costumer_of_operation",
        { 
          costumerName, costumerAddress, costumerRC, costumerNIF, costumerAI, costumerNIS, operationId, externClient: checked, costumerId, type: pageTitle
        })
        .then(function (response) {
          console.log(response.data)
          console.log('************ saveDraft Id after sending request ***************')
          console.log(operationId)

          console.log('************ saveDraft new Id after sending request ***************')
          console.log(response.data.operationId)

          if(operationId === ""){
            setOperationId(response.data.operationId)
            navigate("/app/Inventory_management/new_operation?type="+ pageTitle +"&id=" + response.data.operationId, {replace: true})
          }
          if(costumerId === ""){
            setCostumerId(response.data.costumerId)
          }
          setSending("allowed")
        })
        .catch(function (error) {
          // handle error
          setIssLoading(false)
          if(error.response.status === 400 && error.response.data.response === 'admin_required'){
            setChecked(true)
          }
          handleError(error)
        })
    }
   
  }


  const [isLoading, setIssLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const id = queryParams.get('id');
  const [pageTitle, setPageTitle] = useState('');

  const [quantity, setQuantity] = useState('');
  const [price, setPrice] = useState('');



  useEffect(() => {
    if(type !== 'received' && type !== 'delivered' && type !== 'returned'){
      navigate('/app/Inventory_management')
    }else {
      if(type === 'received'){
        setAllowUserTypeNonSavedProduct(true)
      }
      setPageTitle(type)
      if(id && id.length > 5){
        setOperationId(id)
        getOperation()
      }else{
        getProducts()
      }
      
    }
  }, []);

  if(isLoading){
    return (
      <Body>
        <Loading></Loading>
      </Body>
    )
  }if(nonFound){
    return (
      <Body>
        <NotFound/>
      </Body>
    )
  }else{
    return (
      <Body>
          <Header>
              <HeaderTitle>
                  <H1>{Language['Add operation']}</H1>
              </HeaderTitle>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'end', margin: '0px 0px'}}>
                  <Button variant='text' color='error' style={{marginLeft: '10px'}} onClick={CancelOperation} >{Language['Cancel']}</Button>
                  <LoadingButton loading={loadingSave} loadingPosition='center' variant='contained' style={{marginLeft: '10px'}} onClick={SaveOperation} >{Language['Save']}</LoadingButton>
                </div>
          </Header>
          <BodyContent>
              <Form>

                <ClinetContainer>
                  
                <DetailContainer>
                    <DetailTitle>{Language['Operation type']}:</DetailTitle>
                    <DetailDesc style={{ width: 'max-contet', backgroundColor:'#45d029', color: 'white', padding: "0px 5px"}}>{Language[pageTitle]}</DetailDesc>
                  </DetailContainer>


                  <DetailContainer>
                    <DetailTitle>{Language['Operation reference']}:</DetailTitle>
                    <DetailDesc>{Language['It will be generated after clicking save..']}</DetailDesc>
                  </DetailContainer>

                  <DetailContainer>
                    <DetailTitle>{Language['User']}:</DetailTitle>
                    <DetailDesc>{userName}</DetailDesc>
                  </DetailContainer>
                </ClinetContainer>
                
                

                <ClinetContainer>
                  
                  <ClinetContainer1>

                    <DetailContainer style={{justifyContent: 'space-between'}}>
                    <DetailTitle>{Language['Client']}</DetailTitle>
                    <DetailDesc>
                      <FormControlLabel control={
                        <Switch
                          disabled={!isSuperAdmin}
                          checked={checked}
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      } 
                      label={Language['Local operation']}
                      labelPlacement="start" />
                    </DetailDesc>
                  </DetailContainer>

                  <ClinetContainer >
                    <div style={{display: localFactory, width: '100%', transition: '200ms ease-in-out'}}>
                      <DetailDesc>EURL ELAI WALL COMMERCE</DetailDesc>
                    </div>
                    <div style={{display: externClient, flexDirection:'column', transition: '200ms ease-in-out'}}>
                      <Stack style={{ width: '100%', marginRight: "10px" }} spacing={0} >
                        <Autocomplete
                          id="free-solo-demo"
                          defaultValue={{name: costumerName}}
                          onChange={handleOptionChangeCostumer}
                          freeSolo={true}
                          options={costumers}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField variant="standard" value={costumerName} onChange={(e) => {setCostumerName(e.target.value); setCountChanges(countChanges + 1 )}} {...params} label={Language['Full name']} />}
                          //renderOption={renderOption}
                        />
                      </Stack>

                      <MyTextField id="standard-basic" variant="standard" value={costumerAddress}  onChange={async (e) => {setCostumerAddress(e.target.value); setCountChanges(countChanges + 1 )}} label={Language['Address']} />
                      <MyTextField id="standard-basic" variant="standard" value={costumerRC} onChange={async (e) => {setCostumerRC(e.target.value); setCountChanges(countChanges + 1 )}} label='R.C N°' />
                      <MyTextField id="standard-basic" variant="standard" value={costumerNIF} onChange={async (e) => {setCostumerNIF(e.target.value); setCountChanges(countChanges + 1 )}} label='N.I.F N°' />
                      <MyTextField id="standard-basic" variant="standard" value={costumerAI} onChange={async (e) => {setCostumerAI(e.target.value); setCountChanges(countChanges + 1 )}} label='A. I  N°' />
                      <MyTextField id="standard-basic" variant="standard" value={costumerNIS} onChange={async (e) => {setCostumerNIS(e.target.value); setCountChanges(countChanges + 1 )}} label='N.I.S N°' />
                    </div>
                  </ClinetContainer>
                    
                  </ClinetContainer1>
                  
                </ClinetContainer>

                <ClinetContainer style={{padding: '0px 10px'}}>
                    <AddedComponents style={{ paddingBottom: '20px', alignItems: 'start'}}>
                    <Stack style={{ width: '30%', marginRight: '10px' }} spacing={0}>
                      <Autocomplete
                        id="free-solo-demo"
                        onChange={handleOptionChange}
                        freeSolo={allowUserTypeNonSavedProduct}
                        options={products}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField variant="standard" onChange={(e) => {setProductName(e.target.value)}} {...params} label={Language['Product']} />}
                        //renderOption={renderOption}
                      />
                    </Stack>

                      <MyTextField id="standard-basic" type='number' value={quantity} onChange={(e) => {setQuantity(e.target.value)}} helperText={Language['Maximum units'] + ' ' + allowedQuantity } variant="standard" label={Language['Quantity']} style={{width: '30% ', marginRight: "10px", marginBottom: '10px', flexGrow: 'unset'}}/>
                      <MyTextField id="standard-basic" type='number' value={price} onChange={(e) => {setPrice(e.target.value)}} helperText={Language['']  } variant="standard" label={Language['Price']} style={{width: '30% ', marginRight: "10px", marginBottom: '10px', flexGrow: 'unset'}}/>
                      <Button variant='contained' startIcon={<AddCircleOutlineIcon/>} onClick={addProduct} style={{backgroundColor: '#64257e',marginBottom: '10px',alignSelf: 'start', marginTop: '10px'}}>{Language['Add']}</Button>
                    </AddedComponents>
                </ClinetContainer>


                <div style={{ height: '400px', width: '100%', marginTop: '10px' }}>            

                <DataGrid
                  style={{backgroundColor: "#cdcdcd10"}}
                  rows={operationProducts}
                  columns={columns}
                  disableSelectionOnClick
                  disableSelection
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 7 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                />
                </div>
                <div style={{display: 'none', flexDirection: 'row', justifyContent: 'end', margin: '10px 0px'}}>
                  <Button variant='text' color='error' style={{marginLeft: '10px'}} >{Language['Cancel']}</Button>
                  <Button variant='outlined' style={{marginLeft: '10px'}} >{Language['Draft']}</Button>
                  <Button variant='contained' style={{marginLeft: '10px'}}>{Language['Save']}</Button>
                </div>
              </Form>
          </BodyContent>

            {/* // snackbar */}
            <Snackbar
              open={openAlert}
              autoHideDuration={4000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity={"error"} sx={{ width: '100%' }}>
                {alertMessage}
              </Alert>
            </Snackbar>

      </Body>
    )
  }

  
}
