import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { Routes, Route } from 'react-router-dom';
import Loading from '../Loading';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation } from "react-router-dom";

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ListItemIcon from '@mui/material/ListItemIcon';
import MailIcon from '@mui/icons-material/Mail';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import NotFound from '../404';
import History from './history';
import Product from './product';
import Products from './products';
import HomeInventory from './home';
import Operation from './operation';
import ProductView from './productView';
import NewOperation from './newOperation';
import { ArrowDownward, ArrowLeftOutlined, ArrowLeftRounded, ArrowLeftSharp, ArrowRightSharp, ArrowUpward, Group, LogoutRounded } from '@mui/icons-material';
import Newproduct from './newproduct';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Home = styled.div`
    width: 100µ;
`


const Header = styled.div`
    width: 90%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #64257e;
    padding: 10px 5%;
    align-items: center;
    
`
const HeaderLogo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
`

const HeaderTitle = styled.h1`
    margin: 5px;
    padding: 0px;
    font-size: larger;
    color: white;
    font-weight: 400;
`




const HeaderLinks = styled.div`
    width: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media only screen and (max-width: 850px) {
        display: none;
    }
`

const HeaderLink = styled(Link)`
    text-decoration-line: none;
    padding:  10px;
    margin: 0px 10px;
    color: white;
    cursor: pointer;
    transition: 0.3s;
    &:hover{
        background-color: #4a074ca6;
    }
    @media only screen and (max-width: 850px) {
        color: #353535;
        width: 100% !important;
        display: flex;
    }
`
const PersonIconStyled = styled(PersonIcon)`
    color: white;
    &:hover {
        cursor: pointer;
    }
    @media only screen and (max-width: 850px) {
        display: none !important;
    }
`




const Title1 = styled.h1`
    font-size: xx-large;
    margin: 0%;
    padding: 0%;

`

const Title2 = styled(Title1)`
    font-size: large;
    padding: 5px 5px 0px 15px;
`
const Title4 = styled(Title1)`
    font-size: small;
    padding: 5px 5px 20px 15px;
    color: gray;
    font-weight: 400;
`

const Name = styled.h1`
  font-size: large;
  color: black;
  padding: 10px 10px 3px 10px ;
  margin: 0px;
  margin-top: 10px;
  font-weight: 400;
  text-align: center;

`

const Email = styled.p`
  font-size: small;
  color: grey;
  padding: 0px 10px;
  margin: 0px;
  margin-bottom: 10px;
  text-align: center;
`

const MainBodyContent = styled.div`
    width: 100%;
    height: max-content;
`

const SideBarIcon = styled(ViewStreamIcon)`
    font-size: large;
    color: white;
    //cursor: pointer;
    //pointer-events: visiblePainted;
    display: none !important;
    padding: 10px;
    @media only screen and (max-width: 850px) {
        display: inline-block !important;
        pointer-events: visible;
    }
`
const Center = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`
const MyAvater = styled(Avatar)`
  font-size: 30px !important;
  width: 80px !important;
  height: 80px !important;
`

export default function InventoryManagement({Language, changeLanguage, languageSelected}) {

    const navigate = useNavigate()
    const [ loading, setLoading] = useState(true)
    const [ userName, setUserName] = useState(' your name')
    const [ email, setEmail] = useState('user@gmail.com')
    const [ page, setPage] = useState('contacts') 
    const [ alertMessage, setAlertMessage] = useState('')
    const [ alertType, setAlertType] = useState('')
    const [openAlert, setOpenAlert] = React.useState(false);
    const [firstName, setFirstName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [ isSuperAdmin, setIsSuperAdmin] = useState(false)


  
    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenAlert(false);
    };

    function handleHeader(item){
      var items = []
      items = document.querySelectorAll('.clicked_link')
      console.log(items)
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        element.classList.remove('clicked_link')
        
      }
     
      const myItem = '.' + item
      console.log('my item: ',myItem)
      const myElement = document.querySelector(myItem)
      if(myElement){
             console.log("myElement is : ",myElement)
             myElement.classList.add('clicked_link')
      }
      
    }
    
    // mui config

    const [anchorElListContact, setAnchorElListContact] = React.useState(null);
    const openListContact = Boolean(anchorElListContact);
    const handleClickListContact = (event) => {
        setAnchorElListContact(event.currentTarget);
    };
    const handleCloseListContact = () => {
        setAnchorElListContact(null);
    };



    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (link) => {
        if(link === 'account'){
            navigate('/app/profile', { replace: false });
        }else if(link === 'users'){
          navigate('/app/users', { replace: false });
        }else if(link === 'logout'){
              // get data from database
                axios.post("/logout")
                .then(function (response) {
                    console.log(response.data)

                    if(response.data.res === 'done'){
                        navigate('/login', { replace: true });
                    }else {
                      setOpenAlert(true)
                      setAlertMessage(Language['an error has occured.'])
                      setAlertType("error")
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                    if(error.response){
                        setOpenAlert(true)
                        setAlertMessage(Language[error.response.statusText])
                        setAlertType("error")
                      }else{
                        setOpenAlert(true)
                        setAlertMessage(Language['internal server error.'])
                        setAlertType("error")
                      }
                })
        }
        setAnchorEl(null);
    };

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };

      const list = (anchor) => (
        <Box
          sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >

          <Center>
            <MyAvater></MyAvater>
          </Center>
          <Name>{userName}</Name>
          <Email>{email}</Email>
          <List>
              <ListItem disablePadding >
                <HeaderLink className='headerLink' to={''}>
                    <ListItemIcon>
                        <ArrowRightSharp />
                    </ListItemIcon>
                    {Language['Home']} </HeaderLink>
              </ListItem>
              <ListItem disablePadding >
                <HeaderLink className='headerLink' to={'history?type=received'}>
                    <ListItemIcon>
                        <ArrowRightSharp />
                    </ListItemIcon>
                    {Language['Received']}</HeaderLink>
              </ListItem>
              <ListItem disablePadding >
                <HeaderLink className='headerLink' to={'history?type=delivred'}>
                    <ListItemIcon>
                        <ArrowRightSharp />
                    </ListItemIcon>
                    {Language['Delivered']}</HeaderLink>
              </ListItem>
              <ListItem disablePadding >
                <HeaderLink className='headerLink' to={'history?type=returned'}>
                    <ListItemIcon>
                        <ArrowRightSharp />
                    </ListItemIcon>
                    {Language['Returned']}</HeaderLink>
              </ListItem>
          </List>
          <Divider />
          <List>
                <ListItem disablePadding >
                  <HeaderLink className='headerLink' to={'/app'}>
                    <ListItemIcon>
                        <WidgetsIcon />
                    </ListItemIcon>
                    {Language['Apps']} </HeaderLink>
              </ListItem>
              <ListItem disablePadding >
                <HeaderLink className='headerLink' to={'/app/profile'}>
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    {Language['Profile']}</HeaderLink>
              </ListItem>
              <ListItem disablePadding >
                <HeaderLink className='headerLink' onClick={ () => handleClose('logout')}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    {Language['Logout']}</HeaderLink>
              </ListItem>
              
          </List>
        </Box>
      );
    /* end mui config */
    let location = useLocation().pathname.split('/')
    useEffect(() => {
      console.log('location', location[location.length-1])
      handleHeader(location[location.length-1])
    }, [ useLocation() ]);

    function handleError(error){
      if(error.response.status === 400 && error.response.data.response === 'access_denied'){
        setAlertMessage(Language[error.response.data.errors])
        // navigate('/login', { replace: true });
        // redirect      
      }else if(error.response.status === 400 &&( error.response.data.response === 'server_error' || error.response.data.response === 'admin_required')){
        setAlertMessage(Language[error.response.data.errors])      
      }else if(error.response.status === 429 ){
        // too many requests
        setAlertMessage(Language['too many requests.'])      
      }else if(error.response.status && error.response.statusText !== '' ){
        setAlertMessage(error.response.statusText)      
      }else{
        setAlertMessage(Language['an error has occured.'])      
      }
      setOpenAlert(true)
      setAlertType("error")
      setTimeout(() => {
        setOpenAlert(false)
      }, 3000);
    }

    useEffect(() => {
        // get data from database
        axios.post("/check_login")
        .then(function (response) {
          console.log(response.data)
          if(response.data.redirect == false){
            setLoading(false)
            setUserName(response.data.user.first_name + ' '+ response.data.user.family_name)
            setFirstName(response.data.user.first_name)
            setFamilyName(response.data.user.family_name)
            setEmail(response.data.user.email)
            if(response.data.user.superAdmin && (response.data.user.superAdmin === true || response.data.user.superAdmin === false)){
              setIsSuperAdmin(response.data.user.superAdmin )
            }
            console.log(userName)
          }
          else if(response.data.result == 'redirect'){
            navigate('/app', { replace: true });
          }
          else{
            navigate('/login', { replace: true });
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          if(error.response){
            setOpenAlert(true)
            setAlertMessage(Language[error.response.statusText])
            setAlertType("error")
          }else{
            setOpenAlert(true)
            setAlertMessage(Language['internal server error.'])
            setAlertType("error")
          }
        })
      
      }, [])

      // email builder initialisation
    const [DraggedElements, setDraggedElements] = useState([{type: 'paragraph', content: 'Write something..', color: '#4b4b4b', backgroundColor: '#ffffff', size: '20', paddingH: '0', paddingV: '0', marginH: '0', marginV: '5', textAlign: 'left' }]);
    const [mycode, setMycode] = useState(`<!DOCTYPE html>
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>mailing</title>
    </head>
    <body>
        <p>hello world!</p>
    </body>
</html>`);

    if(loading){
        return(
            <Loading></Loading>
        )
    }else{
        return (
            <Home>
                <Header>
                    <HeaderLogo>
                        <Link to={'/app'}><WidgetsIcon style={{ color: 'white'}} sx={{ fontSize: 50 }} /></Link>
                        <HeaderTitle >{Language['Inventory management']}</HeaderTitle>
                    </HeaderLogo>
                    <HeaderLinks className='headerLinks'>
                        <HeaderLink to={''}  className={"Home clicked_link"}>{Language['Home']}</HeaderLink>
                        <HeaderLink to={'products'} className={"Products"}>{Language['Products']}</HeaderLink>
                        
                    </HeaderLinks>

                    <SideBarIcon onClick={toggleDrawer('left', true)}>{'left'}</SideBarIcon>
                    
                    <Drawer
                        anchor={'left'}
                        open={state['left']}
                        onClose={toggleDrawer('left', false)}
                    >
                        {list('left')}
                    </Drawer>
        
                    <PersonIconStyled 
                            className='headerUserIcon'
                            fontSize="large"
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        ></PersonIconStyled>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={() => handleClose()}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                        >
                            
                            <Title2 style={{width: '300px'}}>{userName} </Title2>
                            <Title4>{email}</Title4>
                            {/* <MenuItem onClick={handleClose}></MenuItem> */}
                            <MenuItem style={{ flexDirection: 'center'}} onClick={() => handleClose('account')} > <PersonIcon style={{marginRight: '10px'}}></PersonIcon> {Language['My account']}</MenuItem>
                            {isSuperAdmin ?  (<MenuItem style={{marginBottom: '40px', flexDirection: 'center', marginTop: '5px'}} onClick={() => handleClose('users')} > <Group style={{marginRight: '10px'}}/>{Language['Users management']}</MenuItem>) : ''}
                            <MenuItem style={{ borderTop: '1px whitesmoke solid',}} onClick={ () => handleClose('logout')}><LogoutRounded style={{marginRight: '10px'}}/> {Language['Logout']}</MenuItem>
                        </Menu>
                </Header>
        
                <MainBodyContent>
                        <Routes>
                            <Route exact path='/' element={<HomeInventory handleHeader={handleHeader} languageSelected={languageSelected} Language={Language} DraggedElements={DraggedElements} mycode={mycode} setMycode={setMycode} setDraggedElements={setDraggedElements} setPage={setPage} userName={userName}  handleError={handleError} />} ></Route>
                            <Route exact path='/history' element={<History handleError={handleError} languageSelected={languageSelected} Language={Language} setPage={setPage} handleHeader={handleHeader} />} ></Route>
                            <Route exact path='/product' element={<ProductView handleError={handleError} languageSelected={languageSelected} Language={Language} setPage={setPage} handleHeader={handleHeader} />} ></Route>
                            <Route exact path='/products' element={<Products handleError={handleError} languageSelected={languageSelected} Language={Language} setPage={setPage} handleHeader={handleHeader} />} ></Route>
                            <Route exact path='/operation' element={<Operation handleError={handleError} userName={userName} Language={Language} languageSelected={languageSelected} setPage={setPage} handleHeader={handleHeader} />} ></Route>
                            <Route exact path='/new_operation' element={<NewOperation handleError={handleError} userName={userName} Language={Language} languageSelected={languageSelected} setPage={setPage} handleHeader={handleHeader} isSuperAdmin={isSuperAdmin} />} ></Route>
                            <Route exact path='/new_product' element={<Newproduct handleError={handleError} userName={userName} Language={Language} languageSelected={languageSelected} setPage={setPage} handleHeader={handleHeader} isSuperAdmin={isSuperAdmin} />} ></Route>
                            {/* <Route exact path='/new_operation' element={<NewOperation handleError={handleError} userName={userName} Language={Language} languageSelected={languageSelected} setPage={setPage} handleHeader={handleHeader} />} ></Route> */}
                            <Route axact path="/*" element={ < NotFound languageSelected={languageSelected} Language={Language} /> }  ></Route>
                        </Routes>
                </MainBodyContent>
                <Snackbar
                  open={openAlert}
                  autoHideDuration={4000}
                  onClose={handleCloseAlert}
                >
                  <Alert onClose={handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
                    {alertMessage}
                  </Alert>
                </Snackbar>


            </Home>
          )
    }

  
}
