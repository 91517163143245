import React from 'react'
import styled from 'styled-components'
import ErrorIcon from '@mui/icons-material/Error';

const Body = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const H1 = styled.h1`
    width: 100%;
    text-align: center;
    font-size: xx-large;
    margin: 0px;
    margin-top: 30px;
`

const P = styled.p`
    width: 100%;
    text-align: center;
    font-size: medium;
`




export default function ServerError() {
  return (
    <Body>
        <ErrorIcon
        sx={{ fontSize: 70 }}
        
        ></ErrorIcon>
        <H1>Intenal server error</H1>
        <P>we are working to solve this problem, our team apologize for the error.</P>
    </Body>
  )
}
