import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress';


const FormHider = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffffbf;
    z-index: 100;
    width: calc(100%) ;
    margin: 0% 0%;
    min-height: 90%;
    box-shadow: 0px 0px 1px 0px grey;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: ${props => (props.open ? `flex` : `none`)};
    @media only screen and (max-width: 850px) {
      
      

    }
`

export default function LoadingContainer({open}) {
  return (
    <FormHider open={open}><CircularProgress></CircularProgress></FormHider>
  )
}
