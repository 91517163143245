import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {Button} from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CategoryIcon from '@mui/icons-material/Category';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PrintIcon from '@mui/icons-material/Print';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LabTabs from './tabs';



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Body = styled.div`
    width: 100%;
`

const Header = styled.div`
    height: 35px;
    width: 96%;
    padding: 10px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px #e1e1e1 solid;
    @media only screen and (max-width: 850px) {
        flex-direction: column !important;
    }
`
const HeaderTitle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: center;
    @media only screen and (max-width: 850px) {
        width: 100% !important;
        flex-direction: row;
        flex-wrap: wrap;
    }
`


const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
    @media only screen and (max-width: 850px) {
      width: 100%;
    }
`

const BodyContent = styled.div`
    position: relative;
    width: 100%;
    background-color: #f6f7fa;
    min-height: calc(100vh - 122px - 20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    flex-grow: 3;
    padding-bottom: 25px;
    @media only screen and (max-width: 850px) {
    }
`

const Form = styled.div`
    width: calc(90% - 40px) ;
    margin: 0px 5%;
    background-color: white;
    min-height: max-content;
    box-shadow: 0px 0px 1px 0px grey;
    padding: 10px 20px;
    margin-top: 25px;
    @media only screen and (max-width: 850px) {
      width: calc(90% - 10px) ;
      padding: 10px 5px;

    }
`

const ProductHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 850px) {
    flex-direction: column;
  }
`

const ProductHeaderContainerImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 28%;
  @media only screen and (max-width: 850px) {
    width: 100%;
  }
`



const ProductHeaderImage = styled.div`
  width: 100%;
  margin-bottom: 10px;
  height: 250px;
  background-color: #8080804f;
  border-radius: 6px;
  background-image: url('/emptyImage.png');
  background-position: center;
  background-size: cover;
`

const ProductHeaderContainerDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 70%;
  @media only screen and (max-width: 850px) {
    width: 100%;
  }
`

const ProductHeaderContainerDetailsElement = styled.div`
  width: 100%;
  display: flex;
  flex-direction: rows;
  justify-content: start;
  align-items: center;
  margin-bottom: 10px;
  //width: 60%;
  //flex-wrap: wrap;
  //align-self: center;
`

const MyLabel = styled.p`
  width: max-content;
  min-width: 80px;
  padding: 0%;
  margin: 0%;
  text-align: left;
  padding-right: 10px;
`


const MyTextField = styled(TextField)`
  //width: 85%;
  min-width: 300px !important;
  padding: 0%;
  margin: 0%;
  flex-grow: 1;
  border-color: red;
`
const AddedComponents = styled.div`
  position: relative;
  padding-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  margin-bottom: 10px;
  flex-wrap: wrap;
  //background-color: #fdf0ffae;
`

const AddedComponentsContainer = styled.div`
  width: calc(100% -0px);
  //padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: end;
  margin-bottom: 10px;
  //background-color: #fdf0ff8c;
  //border: 1px #60606085 solid;
  transition: 200ms ease-in-out;
`

const SmallTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 25px 0px 10px 0px;
`
const SmallTitleText = styled.p`
  margin: 0%;
  padding: 0% 10px;
  text-align: center;
  width: max-content;
  color: #a947c7
`
const SmallTitleSpan = styled.span`
  height: 1px;
  width: 50%;
  background-color: #dcaeedc2;
`


const AvailableNumberContainer = styled.div`
  position: absolute;
  top: -15px;
  right: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-left: 10px;
`

const AvailableNumberTitle = styled.p`
  margin: 0% 10px 0px 0px;
  padding: 0;
  color: #808080;
  font-size: medium;
`
const AvailableNumberDesc = styled.p`
  margin: 0%;
  padding: 0;
  color: #000000;
  font-size: medium;
`














export default function Product({handleHeader, handleError, Language}) {

  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [ openAlert, setOpenAlert] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const [showAddComponentsContainer, setShowAddComponentsContainer] = useState('none');
  function handleChangeType(type){
    if(type === 'product'){
      setShowAddComponentsContainer('flex')
    }else{
      setShowAddComponentsContainer('none')
    }
  }
   
  useEffect(() => {
    handleHeader('History')
  }, []);

  const columns = [
    { field: 'id', headerName: Language['ID'], width: 70 },
    { field: 'firstName', headerName: Language['User'], width: 180 },
    { field: 'lastName', headerName: Language['Product'], width: 180 },
    {
      field: 'fullName',
      headerName: Language['Reference'],
      sortable: false,
      width: 130,
      valueGetter: (params) =>
        `PR_0021`,
    },
    {
      field: 'agee',
      headerName: Language['Quantity'],
      type: 'number',
      width: 130,
      valueGetter: (params) =>
        `20`,
    },
    {
      field: 'fullNamea',
      headerName: Language['Source'],
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `Mykel Angelo`,
    },
    {
      field: 'aa',
      headerName: 'Date',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `11/03/2023 15:22`,
    },
    

    {
      field: 'ages',
      headerName: 'Last Quantity',
      type: 'number',
      width: 130,
      valueGetter: (params) =>
        `15`,
    },

    {
      field: 'age',
      headerName: 'Current Quantity',
      type: 'number',
      width: 130,
    },
  ];
  
  const rows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    { id: 10, lastName: 'Snow', firstName: 'Jon', age: 35 },
    { id: 20, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
    { id: 30, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    { id: 40, lastName: 'Stark', firstName: 'Arya', age: 16 },
    { id: 50, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 60, lastName: 'Melisandre', firstName: null, age: 150 },
    { id: 70, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 80, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 90, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  ];

  const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
    {
      title: 'The Lord of the Rings: The Return of the King',
      year: 2003,
    },
    { title: 'The Good, the Bad and the Ugly', year: 1966 },
    { title: 'Fight Club', year: 1999 },
    {
      title: 'The Lord of the Rings: The Fellowship of the Ring',
      year: 2001,
    },
    {
      title: 'Star Wars: Episode V - The Empire Strikes Back',
      year: 1980,
    },
    { title: 'Forrest Gump', year: 1994 },
    { title: 'Inception', year: 2010 },
    {
      title: 'The Lord of the Rings: The Two Towers',
      year: 2002,
    },
    { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { title: 'Goodfellas', year: 1990 },
    { title: 'The Matrix', year: 1999 },
    { title: 'Seven Samurai', year: 1954 },
    {
      title: 'Star Wars: Episode IV - A New Hope',
      year: 1977,
    },
    { title: 'City of God', year: 2002 },
    { title: 'Se7en', year: 1995 },
    { title: 'The Silence of the Lambs', year: 1991 },
    { title: "It's a Wonderful Life", year: 1946 },
    { title: 'Life Is Beautiful', year: 1997 },
    { title: 'The Usual Suspects', year: 1995 },
    { title: 'Léon: The Professional', year: 1994 },
    { title: 'Spirited Away', year: 2001 },
    { title: 'Saving Private Ryan', year: 1998 },
    { title: 'Once Upon a Time in the West', year: 1968 },
    { title: 'American History X', year: 1998 },
    { title: 'Interstellar', year: 2014 },
    { title: 'Casablanca', year: 1942 },
    { title: 'City Lights', year: 1931 },
    { title: 'Psycho', year: 1960 },
    { title: 'The Green Mile', year: 1999 },
    { title: 'The Intouchables', year: 2011 },
    { title: 'Modern Times', year: 1936 },
    { title: 'Raiders of the Lost Ark', year: 1981 },
    { title: 'Rear Window', year: 1954 },
    { title: 'The Pianist', year: 2002 },
    { title: 'The Departed', year: 2006 },
    { title: 'Terminator 2: Judgment Day', year: 1991 },
    { title: 'Back to the Future', year: 1985 },
    { title: 'Whiplash', year: 2014 },
    { title: 'Gladiator', year: 2000 },
    { title: 'Memento', year: 2000 },
    { title: 'The Prestige', year: 2006 },
    { title: 'The Lion King', year: 1994 },
    { title: 'Apocalypse Now', year: 1979 },
    { title: 'Alien', year: 1979 },
    { title: 'Sunset Boulevard', year: 1950 },
    {
      title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
      year: 1964,
    },
    { title: 'The Great Dictator', year: 1940 },
    { title: 'Cinema Paradiso', year: 1988 },
    { title: 'The Lives of Others', year: 2006 },
    { title: 'Grave of the Fireflies', year: 1988 },
    { title: 'Paths of Glory', year: 1957 },
    { title: 'Django Unchained', year: 2012 },
    { title: 'The Shining', year: 1980 },
    { title: 'WALL·E', year: 2008 },
    { title: 'American Beauty', year: 1999 },
    { title: 'The Dark Knight Rises', year: 2012 },
    { title: 'Princess Mononoke', year: 1997 },
    { title: 'Aliens', year: 1986 },
    { title: 'Oldboy', year: 2003 },
    { title: 'Once Upon a Time in America', year: 1984 },
    { title: 'Witness for the Prosecution', year: 1957 },
    { title: 'Das Boot', year: 1981 },
    { title: 'Citizen Kane', year: 1941 },
    { title: 'North by Northwest', year: 1959 },
    { title: 'Vertigo', year: 1958 },
    {
      title: 'Star Wars: Episode VI - Return of the Jedi',
      year: 1983,
    },
    { title: 'Reservoir Dogs', year: 1992 },
    { title: 'Braveheart', year: 1995 },
    { title: 'M', year: 1931 },
    { title: 'Requiem for a Dream', year: 2000 },
    { title: 'Amélie', year: 2001 },
    { title: 'A Clockwork Orange', year: 1971 },
    { title: 'Like Stars on Earth', year: 2007 },
    { title: 'Taxi Driver', year: 1976 },
    { title: 'Lawrence of Arabia', year: 1962 },
    { title: 'Double Indemnity', year: 1944 },
    {
      title: 'Eternal Sunshine of the Spotless Mind',
      year: 2004,
    },
    { title: 'Amadeus', year: 1984 },
    { title: 'To Kill a Mockingbird', year: 1962 },
    { title: 'Toy Story 3', year: 2010 },
    { title: 'Logan', year: 2017 },
    { title: 'Full Metal Jacket', year: 1987 },
    { title: 'Dangal', year: 2016 },
    { title: 'The Sting', year: 1973 },
    { title: '2001: A Space Odyssey', year: 1968 },
    { title: "Singin' in the Rain", year: 1952 },
    { title: 'Toy Story', year: 1995 },
    { title: 'Bicycle Thieves', year: 1948 },
    { title: 'The Kid', year: 1921 },
    { title: 'Inglourious Basterds', year: 2009 },
    { title: 'Snatch', year: 2000 },
    { title: '3 Idiots', year: 2009 },
    { title: 'Monty Python and the Holy Grail', year: 1975 },
  ];
  const [name, setName] = React.useState('');

  return (
    <Body>
        <Header>
            <HeaderTitle>
                <H1>
                  <span>Product details</span>

                  <div>
                    <Tooltip title="Print">
                      <IconButton style={{ color: 'white', margin: '5px 5px 5px 10px', height :'min-content', backgroundColor: '#007bff', alignItems: 'right'}} variant="contained" size='small'><PrintIcon /></IconButton>
                    </Tooltip>
                  </div>
                  
                </H1>
            </HeaderTitle>
        </Header>
        <BodyContent>
            <Form>
              <ProductHeader>
                <ProductHeaderContainerImage>
                  <ProductHeaderImage></ProductHeaderImage>
                  <Button variant='outlined'>upload image</Button>
                </ProductHeaderContainerImage>
                <ProductHeaderContainerDetails>

                    <SmallTitle>
                      <SmallTitleSpan></SmallTitleSpan>
                      <SmallTitleText>General</SmallTitleText>
                      <SmallTitleSpan></SmallTitleSpan>
                    </SmallTitle>
                  <ProductHeaderContainerDetailsElement>
                    {/* <MyLabel>Name</MyLabel> */}
                    

                    <MyTextField id="standard-basic" variant="standard" label='name' />
                    
                    <FormControl variant="standard" style={{width: '200px', alignSelf: 'end', marginLeft: '20px'}} >
                    <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      label="Type"
                      defaultValue={'component'}
                      onChange={(e)=> handleChangeType(e.target.value)}
                    >
                      <MenuItem value={'component'}>Component</MenuItem>
                      <MenuItem value={'product'}>Product</MenuItem>
                    </Select>
                    </FormControl>
                  </ProductHeaderContainerDetailsElement>

                  <ProductHeaderContainerDetailsElement>
                    {/* <MyLabel>Reference</MyLabel> */}
                    <MyTextField id="standard-basic" variant="standard" label='Reference' />
                  </ProductHeaderContainerDetailsElement>

                  <ProductHeaderContainerDetailsElement>
                    {/* <MyLabel>Barcode</MyLabel> */}
                    <MyTextField id="standard-basic" variant="standard" label='Barcode'/>
                  </ProductHeaderContainerDetailsElement>

                  <ProductHeaderContainerDetailsElement>
                    {/* <MyLabel>Price</MyLabel> */}
                    <MyTextField id="standard-basic" variant="standard" label='Price'/>
                  </ProductHeaderContainerDetailsElement>


                  

                  <ProductHeaderContainerDetailsElement style={{justifyContent: 'end', padding: '10px 0px'}}>
                    <Button color='error' variant='outlined' style={{ marginRight: '5px'}}>Delete</Button>
                    <Button variant='contained' >Save</Button>

                  </ProductHeaderContainerDetailsElement>


                </ProductHeaderContainerDetails>
              </ProductHeader>

              <LabTabs></LabTabs>
              <AddedComponentsContainer style={{display: showAddComponentsContainer}}>
                    <SmallTitle>
                      <SmallTitleSpan></SmallTitleSpan>
                      <SmallTitleText>Components</SmallTitleText>
                      <SmallTitleSpan></SmallTitleSpan>
                    </SmallTitle>
                    <List
                        sx={{ width: 'calc(100% - 20px)',  bgcolor: 'background.paper' }}
                        style={{backgroundColor: "#cdcdcd10", border: '1px solid #6a696938', padding: '10px'}}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                          <AddedComponents style={{ paddingBottom: '20px'}}>
                            {/* <MyLabel>Price</MyLabel> */}
                            <Stack style={{ width: '50%', marginRight: "10px" }} spacing={0} >
                              <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                options={top100Films.map((option) => option.title)}
                                renderInput={(params) => <TextField variant="standard"  {...params} label="Product" />}
                              />
                            </Stack>
                            <MyTextField id="standard-basic" variant="standard" label='Quantity' style={{width: '30%', marginRight: "10px"}}/>
                            <Button variant='contained' style={{backgroundColor: '#64257e'}}>Add</Button>
                          </AddedComponents>
                          
                        }
                      >
                        <ListItemButton onClick={handleClick}>
                          <ListItemIcon>
                            <CategoryIcon />
                          </ListItemIcon>
                          <ListItemText primary="Components" />
                          {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemIcon>
                                <ArrowDropDownIcon />
                              </ListItemIcon>
                              <ListItemText primary="Kowla" secondary="3 Pieces" />
                            </ListItemButton>

                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemIcon>
                                <ArrowDropDownIcon />
                              </ListItemIcon>
                              <ListItemText primary="Drinox" secondary="1 Pieces" />
                            </ListItemButton>

                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemIcon>
                                <ArrowDropDownIcon />
                              </ListItemIcon>
                              <ListItemText primary="Tube Gaz" secondary="7 Pieces" />
                            </ListItemButton>


                          </List>
                        </Collapse>
                      </List>

              </AddedComponentsContainer>

              <SmallTitle>
                <SmallTitleSpan></SmallTitleSpan>
                <SmallTitleText>Operation</SmallTitleText>
                <SmallTitleSpan></SmallTitleSpan>
              </SmallTitle>
              
              <Box sx={{ width: '100%', position: 'relative' }} style={{ backgroundColor: "#cdcdcd10", border: '1px solid #6a696938'}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                      <Tab label="Received" {...a11yProps(0)} />
                      <Tab label="Delivred" {...a11yProps(1)} />
                      <Tab label="Returned" disabled {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0} style={{width: '100%'}} id={'0'}>
                  <AddedComponents style={{ paddingBottom: '20px'}}>
                    <MyTextField id="standard-basic" value={name} onChange={(e)=> setName(e.target.value)} variant="standard" label='Quantity' style={{width: '30% ', marginRight: "10px", marginBottom: '10px', flexGrow: 'unset'}}/>
                    <Button variant='contained' style={{backgroundColor: '#64257e',marginBottom: '10px',}}>Received</Button>
                    <AvailableNumberContainer>
                        <AvailableNumberTitle>Total inventory</AvailableNumberTitle>
                        <AvailableNumberDesc>250 pieces</AvailableNumberDesc>
                    </AvailableNumberContainer>
                  </AddedComponents>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <AddedComponents style={{ paddingBottom: '20px'}}>
                    <MyTextField id="standard-basic" variant="standard" label='Quantity' style={{width: '30% ', marginRight: "10px", marginBottom: '10px', flexGrow: 'unset'}}/>
                    <Button variant='contained' style={{backgroundColor: '#64257e',marginBottom: '10px',}}>Delivered</Button>
                    <AvailableNumberContainer>
                        <AvailableNumberTitle>Total inventory</AvailableNumberTitle>
                        <AvailableNumberDesc>250 pieces</AvailableNumberDesc>
                    </AvailableNumberContainer>
                  </AddedComponents>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <AddedComponents style={{ paddingBottom: '20px'}}>
                    <MyTextField id="standard-basic" variant="standard" label='Quantity' style={{width: '30% ', marginRight: "10px", marginBottom: '10px', flexGrow: 'unset'}}/>
                    <Button variant='contained' style={{backgroundColor: '#64257e',marginBottom: '10px',}}>Returned</Button>
                    <AvailableNumberContainer>
                        <AvailableNumberTitle>Total inventory</AvailableNumberTitle>
                        <AvailableNumberDesc>250 pieces</AvailableNumberDesc>
                    </AvailableNumberContainer>
                  </AddedComponents>
                </TabPanel>
                {/* <TabPanel value={value} index={2}>
                    Item Three
                </TabPanel> */}
              </Box>

              <SmallTitle>
                <SmallTitleSpan></SmallTitleSpan>
                <SmallTitleText>History</SmallTitleText>
                <SmallTitleSpan></SmallTitleSpan>
              </SmallTitle>

              <div 
              style={{
                width: '100',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                margin: '10px 0px'
              }}
              >
                <LocalizationProvider variant='standard' dateAdapter={AdapterDayjs} >
                  <DatePicker 
                  label="Select Date"
                  renderInput={(params) => (
                    <TextField {...params}/>
                  )}
                  />
                </LocalizationProvider>
                <Button style={{marginLeft: '10px'}}>Filter</Button>
              </div>


              <div style={{ height: '400px', width: '100%', marginTop: '10px' }}>            

                <DataGrid
                  style={{backgroundColor: "#cdcdcd10"}}
                  rows={rows}
                  columns={columns}
                  disableSelectionOnClick
                  disableSelection
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 7 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                />
              </div>

            

            </Form>
        </BodyContent>

          {/* // snackbar */}
          <Snackbar
            open={openAlert}
            autoHideDuration={4000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
              {alertMessage}
            </Alert>
          </Snackbar>

    </Body>
  )
}
