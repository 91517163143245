import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertComp({message, open, handleClose, type}) {


  return (
    <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
    >
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%', mb: 2  }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              handleClose()
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        >
        {message}
        </Alert>
    </Snackbar>
  )
}
