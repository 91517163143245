import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import PersonIcon from '@mui/icons-material/Person';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Loading from './Loading';
import ServerError from './serverError';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import GroupIcon from '@mui/icons-material/Group';
import EmailIcon from '@mui/icons-material/Email';
import { Routes, Route } from 'react-router-dom';
import Profile from './profile';
import NotFound from './404';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { Select } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Group, GroupWork, LogoutOutlined, LogoutRounded, LogoutSharp, Person2TwoTone } from '@mui/icons-material';
import Users from './users';

const Body = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
`
const Header = styled.div`
    width: 90%;
    height: 70px;
    padding: 5px 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f3f3f3;
`
const BodyHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 0px;
    background-color: #300043;
    background-image: url('../assets/img/login5.png');
    background-size: cover;
    background-position: center; 
`

const BodyContent = styled.div`
    width: 70%;
    min-width: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    margin: 20px 0px;
`
const Service = styled.div`
    width: 300px;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 2px 2px whitesmoke;
    padding: 0px 0px;
    cursor: pointer;
    transition: 0.3s;
    margin: 5px 10px;
    &:hover{
        box-shadow: 0px 0px 2px 2px #ececec;
    }

`

const ServiceIcon = styled.div`
    width: max-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #300043b6;
    
`



const MyGroupIcon = styled(GroupIcon)`
    color: #300043;
    color: white;
    font-size: 50px !important;
    padding: 0px 10px;
`

const MyFacturationIcon = styled(RequestQuoteIcon)`
    color: #300043;
    color: white;
    font-size: 50px !important;
    padding: 0px 10px;
`

const MyStockIcon = styled(WarehouseIcon)`
    color: #300043;
    color: white;
    font-size: 50px !important;
    padding: 0px 10px;
`

const ServiceTitle = styled.h1`
    width: 200px;
    font-size: large;
    font-weight: 400;
    color: #39034ec3 !important;
`


const Logo = styled.img`
    width: 60px;
    height: auto;
`


const Title1 = styled.h1`
    font-size: xx-large;
    margin: 0%;
    padding: 0%;

`

const Title2 = styled(Title1)`
    font-size: large;
    padding: 5px 5px 0px 15px;
`
const Title4 = styled(Title1)`
    font-size: small;
    padding: 5px 5px 20px 15px;
    color: gray;
    font-weight: 400;
`

const Title3 = styled(Title1)`
    font-size: xx-large;
    text-align: center;
    padding-bottom: 20px;
    color: #f3f3f3
`
const Parag = styled(Title1)`
    font-size: medium;
    text-align: center;
    font-weight: 400;
    color: #f3f3f3
`
const PersonIconStyled = styled(PersonIcon)`
    &:hover {
        cursor: pointer;
    }
`

export default function Home({Language, changeLanguage, languageSelected}) {
    const navigate = useNavigate()

    // mui config
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (link) => {
        if(link === 'account'){
            navigate('/app/profile', { replace: false });
        }else if(link === 'users'){
            navigate('/app/users', { replace: false });
        }else if(link === 'logout'){
              // get data from database
                axios.post("/logout")
                .then(function (response) {
                    console.log(response.data)

                    if(response.data.res === 'done'){
                        navigate('/login', { replace: true });
                    }else if(response.data.res === 'redirect'){
                        navigate('/login', { replace: true });
                    }
                    else{
                        setServerError(true)
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        }
        setAnchorEl(null);
    };
    /* end mui config */

    const [ loading, setLoading] = useState(true)
    const [ serverError, setServerError] = useState(false)
    const [ userName, setUserName] = useState('')
    const [ email, setEmail] = useState('')
    const [ firstName, setFirstName] = useState('')
    const [ familyName, setFamilyName] = useState('')
    const [ isSuperAdmin, setIsSuperAdmin] = useState(false)

    function goToService(service){
        navigate(service);

    }

  useEffect(() => {

    // get data from database
    
  axios.post("/App")
  .then(function (response) {
    console.log(response.data)

    if(response.data.res === 'done'){
      setLoading(false)
      let name = response.data.user.first_name + ' ' + response.data.user.family_name
      setUserName(name )
      setFamilyName(response.data.user.family_name)
      setFirstName(response.data.user.first_name)
      setEmail(response.data.user.email )
      if(response.data.user.superAdmin && (response.data.user.superAdmin === true || response.data.user.superAdmin === false)){
        setIsSuperAdmin(response.data.user.superAdmin )
      }
    }else if(response.data.res === 'redirect'){
        navigate('/login', { replace: true });
    }
    else{
        setServerError(true)
    }
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })

}, [])


const MyApp = () => {
    return (
        <>
                        <BodyHeader>
                                <Title3>{Language['Welcome Hengxing Al Sinaiya Star ERP']}</Title3>
                                <Parag>{Language['The best factory management platform for you.']}</Parag>
                        </BodyHeader>

                        <BodyContent>
                                <Service onClick={() => goToService('/app/Inventory_management')}>
                                    <ServiceIcon><MyStockIcon></MyStockIcon></ServiceIcon>
                                    <ServiceTitle>{Language['Inventory management']}</ServiceTitle>
                                </Service>


                                <Service style={{display: isSuperAdmin === true? 'flex': 'none'}} onClick={() => goToService('/app/Costumer_management')}>
                                    <ServiceIcon><MyGroupIcon></MyGroupIcon></ServiceIcon>
                                    <ServiceTitle>{Language['Costumer management']}</ServiceTitle>
                                </Service>


                                <Service style={{display: isSuperAdmin === true? 'flex': 'none'}} onClick={() => goToService('/app/Invoice_management')}>
                                    <ServiceIcon><MyFacturationIcon></MyFacturationIcon></ServiceIcon>
                                    <ServiceTitle>{Language['Invoice management']}</ServiceTitle>
                                </Service>
                                

                        </BodyContent>
        </>
    )
}


if(!loading && !serverError ){
    const url = '../assets/img/logo192.png'
    return (
        <Body>
            <Header>
                <Logo src={url} onClick={() => { navigate('')}} />
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'end'}}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 20 }}>
                        <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Language"
                        defaultValue={languageSelected}
                        onChange={(e)=> {changeLanguage(e.target.value); localStorage.setItem('language', e.target.value)} }
                        >
                        <MenuItem value={"english"}>English</MenuItem>
                        <MenuItem value={"chinese"}>Chinese</MenuItem>
                        <MenuItem value={"french"}>French</MenuItem>
                        </Select>
                    </FormControl>
                    
                    <PersonIconStyled 
                        fontSize="large"
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    ></PersonIconStyled>
                    
                </div>
                
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => handleClose()}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    
                    <Title2 style={{width: '300px'}}>{userName} </Title2>
                    <Title4>{email}</Title4>
                    {/* <MenuItem onClick={handleClose}></MenuItem> */}
                    <MenuItem style={{ flexDirection: 'center'}} onClick={() => handleClose('account')} > <PersonIcon style={{marginRight: '10px'}}></PersonIcon> {Language['My account']}</MenuItem>
                    {isSuperAdmin ?  (<MenuItem style={{marginBottom: '40px', flexDirection: 'center', marginTop: '5px'}} onClick={() => handleClose('users')} > <Group style={{marginRight: '10px'}}/> {Language['Users management']}</MenuItem>) : ''}
                    <MenuItem style={{ borderTop: '1px whitesmoke solid',}} onClick={ () => handleClose('logout')}><LogoutRounded style={{marginRight: '10px'}}/> {Language['Logout']}</MenuItem>
                </Menu>
                
            </Header>
            <Routes>
                <Route exact path='/profile' element={<Profile setUserName={setUserName} Language={Language} setFirstName={setFirstName} setFamilyName={setFamilyName} email={email} firstName={firstName} familyName={familyName}  />} ></Route>
                <Route exact path='/' element={<MyApp  />} ></Route>
                <Route exact path='/users' element={<Users  setUserName={setUserName} Language={Language} setFirstName={setFirstName} setFamilyName={setFamilyName} email={email} firstName={firstName} familyName={familyName}  />} ></Route>
                <Route exact path="/*" element={<NotFound Language={Language}   />} ></Route>
        
                </Routes>
            

        </Body>
    )
}else if(serverError && loading){
    return(
        <ServerError></ServerError>
    )
}
else{
    return(
        <Loading/>
    )
    
}

  
}
