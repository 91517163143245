import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Body = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0%;
    left: 0;
    bottom: 0;
    right: 0%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const HeaderImage = styled.div`
  width: 100px;
  height: 100px;
  background-image: url('http://localhost:5000/billing_logo.png');
  background-position: center;
  background-size: cover;
  display: block;
  border: 2px black;
`
const HeaderDisc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-grow: 1;
`
const Title = styled.p`
  margin: 0% 10px;
  padding: 0%;
  text-align: center;
  font-size: 30px;
  width: calc(100% - 20px);
  font-weight: 600;
`

const Disc = styled.p`
  margin: 10px;
  padding: 0%;
  text-align: center;
  font-size: 15px;
  width: calc(100% - 20px);
  font-weight: 400;
`

export default function Print({handleHeader, handleError}) {

  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [ openAlert, setOpenAlert] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  

  useEffect(() => {
    handleHeader('History')
  }, []);

  return (
    <Body>
      <HeaderContainer>
        <HeaderImage/>
        <HeaderDisc>
          <Title style={{borderBottom: '1px gray solid'}}>EURL ELAI WALL COMMERCE TECHNOLOGIE</Title>
          <Disc>CONSEILS ET SERVICES Eurl  Au Capital Social De : 500 000.00</Disc>
        </HeaderDisc>
      </HeaderContainer>
    </Body>
  )
}
