import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import styled from 'styled-components'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CategoryIcon from '@mui/icons-material/Category';
import StorageIcon from '@mui/icons-material/Storage';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import PaymentIcon from '@mui/icons-material/Payment';
import SquareIcon from '@mui/icons-material/Square';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Input } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import Tab from '@mui/material/Tab';
import PublishIcon from '@mui/icons-material/Publish';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from '../Loading';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { DataGrid } from '@mui/x-data-grid';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { LoadingButton } from '@mui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormLabel from '@mui/material/FormLabel';
import { Empty } from 'antd';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Body = styled.div`
    width: 100%;
`

const Header = styled.div`
    height: 35px;
    width: 96%;
    padding: 10px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px #e1e1e1 solid;
    @media only screen and (max-width: 850px) {
        flex-direction: column !important;
    }
`
const HeaderTitle = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: center;
    @media only screen and (max-width: 850px) {
        width: 100% !important;
        flex-direction: row;
        flex-wrap: wrap;
    }
`


const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    @media only screen and (max-width: 850px) {
      width: max-content;
      margin-right: 20px;
    }
`

const BodyContent = styled.div`
    position: relative;
    width: 100%;
    background-color: #f6f7fa;
    min-height: calc(100vh - 122px - 20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    flex-grow: 3;
    padding-bottom: 25px;
    @media only screen and (max-width: 850px) {
    }
`

const Form = styled.div`
    width: calc(90% - 40px) ;
    margin: 0px 5%;
    background-color: white;
    min-height: max-content;
    box-shadow: 0px 0px 1px 0px grey;
    padding: 10px 20px;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
    @media only screen and (max-width: 850px) {
      width: calc(90% - 10px) ;
      padding: 10px 5px;

    }
`

const ProductContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  background-color: #dcdcdc15;
  border: 1px solid #bbbaba52;
  height: 105px;
  margin: 10px ;
  cursor: pointer;
  transition: 200ms;
  &:hover{
    box-shadow: 0px 0px 3px 0px #ce59d438;
  }
`
const ProductContainerImage = styled.div`
  width: 30%;
  height: 105px;
  background-image: url(${props => props.url ? props.url : '/emptyImage.png'});
  background-position: center;
  background-size: cover;
`
const ProductContainerDetails = styled.div`
  width: calc(70% - 20px);
  padding: 10px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
`
const ProductTitle = styled.p`
  margin: 0px 0px 5px 0px;
  padding: 0%;
  color: black;
  font-weight: 600;
  font-size: medium;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: end;
`

const ProductDesc = styled.p`
  margin: 0px 0%;
  padding: 0%;
  color: #00000091;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`

const ProductType = styled.p`
  margin: 7px 0px 0px 0%;
  padding: 0%;
  color: #141214d2;
  font-weight: 400;
  font-size: medium;
  text-align: right;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: center;
`

const ProductHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 850px) {
    flex-direction: column;
  }
`

const ProductHeaderContainerImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 28%;
  @media only screen and (max-width: 850px) {
    width: 100%;
  }
`



const ProductHeaderImage = styled.div`
  width: 100%;
  margin-bottom: 10px;
  height: 250px;
  background-color: #8080804f;
  border-radius: 6px;
  background-image: url(${props => props.url});
  background-position: center;
  background-size: cover;
  @media print {
    background-image: url(${props => props.url});
    /* Additional styling properties for printing */
  }
`

const ProductHeaderContainerDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 70%;
  @media only screen and (max-width: 850px) {
    width: 100%;
  }
`

const ProductHeaderContainerDetailsElement = styled.div`
  width: 100%;
  display: flex;
  flex-direction: rows;
  justify-content: start;
  align-items: center;
  margin-bottom: 10px;
  //width: 60%;
  //flex-wrap: wrap;
  //align-self: center;
`

const MyLabel = styled.p`
  width: max-content;
  min-width: 80px;
  padding: 0%;
  margin: 0%;
  text-align: left;
  padding-right: 10px;
`


const MyTextField = styled(TextField)`
  //width: 85%;
  min-width: 300px !important;
  padding: 0%;
  margin: 0%;
  flex-grow: 1;
  border-color: red;
`
const AddedComponents = styled.div`
  position: relative;
  padding-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  //background-color: #fdf0ffae;
`

const AddedComponentsContainer = styled.div`
  width: calc(100% -0px);
  //padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: end;
  margin-bottom: 10px;
  //background-color: #fdf0ff8c;
  //border: 1px #60606085 solid;
  transition: 200ms ease-in-out;
`

const SmallTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 25px 0px 10px 0px;
`
const SmallTitleText = styled.p`
  margin: 0%;
  padding: 0% 10px;
  text-align: center;
  width: max-content;
  color: #a947c7
`
const SmallTitleSpan = styled.span`
  height: 1px;
  width: 50%;
  background-color: #dcaeedc2;
`


const AvailableNumberContainer = styled.div`
  position: absolute;
  top: -15px;
  right: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-left: 10px;
`

const AvailableNumberTitle = styled.p`
  margin: 0% 10px 0px 0px;
  padding: 0;
  color: #808080;
  font-size: medium;
`
const AvailableNumberDesc = styled.p`
  margin: 0%;
  padding: 0;
  color: #000000;
  font-size: medium;
`
const ProductComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
`

const ProductComponentContainerTitle = styled.p`
  margin: 0%;
  padding: 0;
  color: #000000;
  font-size: medium;
`





export default function Products({handleHeader, handleError, Language}) {

  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [ openAlert, setOpenAlert] = useState(false)

  const navigate = useNavigate()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  function LinkTo(link){
    navigate(link, {
      replace: false
    })
  }
  

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

      
  function exportData(){
    if(selectedRows.length === 0){
      setAlertMessage(Language['No row selected.'])
      setAlertType('error')
      setOpenAlert(true)
      setInterval(() => {
        setOpenAlert(false)
      }, 3000);
    }else{
      handleCloseModal()
      const columnTitles = ['type', 'name', 'reference', 'stock', 'price', 'relatedTo', 'quantity_needed'];
      const rowData = [];

      selectedRows.forEach(({ type, name, reference, quantity, price, createdByAdmin, createdAt, subProducts }) => {
        // Add the parent product to the rowData array
        rowData.push([type, name, reference, quantity, price, '', '']);

        // Add the subproducts to the rowData array
        subProducts.forEach(subProduct => {
          rowData.push([subProduct.type, subProduct.name, subProduct.reference, subProduct.quantity, subProduct.price, reference, subProduct.productPack.quantity]);
        });
      });

      const csvContent = "data:text/csv;charset=utf-8," + [columnTitles.join(","), ...rowData.map(row => row.join(","))].join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", 'selected_rows.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    

  }

  function printData(){
    if(selectedRows.length === 0){
      setAlertMessage(Language['No row selected.'])
      setAlertType('error')
      setOpenAlert(true)
      setInterval(() => {
        setOpenAlert(false)
      }, 3000);
    }else{
      handleCloseModal()
      const columnTitles = ['id', 'name', 'reference', 'price', 'quantity', 'createdByAdmin', 'createdAt'];
      const rowData = selectedRows.map(({ id, name, reference, price, quantity, createdByAdmin, createdAt}) => [id, name, reference, price, quantity, createdByAdmin, createdAt]);
      const csvContent = "data:text/csv;charset=utf-8," + [columnTitles.join(","), ...rowData.map(row => row.join(","))].join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", 'selected_rows.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const fileInputRef = useRef(null);

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const getRowClassName = (params) => {
  const rowData = params.row;
  
  // Check the row attributes and return the appropriate class name
  if (rowData.valid === true) {
    if(rowData.case === 'exist'){
      return 'update_row'
    }else{
      return 'create_row';
    }
  } else {
    return 'invalid_row';
  }
};

  const [importedDataLoading, setImportedDataLoading] = React.useState(false);
  const [importedData, setImportedData] = React.useState([]);

  const handleFileChange = (event) => {
    if(event.target.files[0]){
      console.log('worked')
      setOpenImportedDataModal(true)
      setImportedDataLoading(true)
      const selectedFile = event.target.files[0];
      console.log(event.target.files[0])
      const formData = new FormData();
      formData.append('file', selectedFile);
      const fileInput = document.getElementById('fileInput');
      fileInput.value = '';
      axios
        .post('/inventory_management/import_operations', formData)
        .then((response) => {
          // Handle success
          console.log(response.data);
          setImportedData(response.data.products)
          setImportedDataLoading(false)
          event.target.files = []
        })
        .catch((error) => {
          // Handle error
          event.target.files = []
          handleError(error)
          setOpenImportedDataModal(false)
          setImportedDataLoading(false)
      });
    }
};

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openFullModal, setOpenFullModal] = React.useState(false);

  const handleClickOpenFullModal = () => {
    setOpenFullModal(true);
  };

  const handleCloseFullModal = () => {
    setOpenFullModal(false);
  };



  const [openImportedDataModal, setOpenImportedDataModal] = React.useState(false);
  const handleClickOpenImportedDataModal = () => {
    setOpenImportedDataModal(true);
  };
  const handleCloseImportedDataModal = () => {
    setOpenImportedDataModal(false);
  };



  const [openModal, setOpenModal] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };


  function formatNumberWithMinimumDigits(number) {
    let decimalPlaces = parseFloat(number).toString().split('.')[1];
    let minDigits = decimalPlaces ? Math.max(decimalPlaces.length, 2) : 2;
    let formattedNumber = parseFloat(number).toFixed(minDigits);
    return formattedNumber.replace(/(\.\d*?[1-9])0+$/, '$1');
  }

  const Productcolumns = [
    { field: 'id', headerName: Language['Id'], width: 100 },
    { field: 'name', headerName: Language['Name'], width: 180 },
    { field: 'reference', headerName: Language['Reference'], width: 180 },
    {
      field: 'price',
      headerName: Language['Price'],
      width: 180,
      renderCell: (params) => {
        if(params.row.price){
          return formatNumberWithMinimumDigits(params.row.price)
        }else{
          return ''
        }
      },
    },
    {
      field: 'quantity',
      headerName: Language['Quantity'],
      width: 180,
    },
    //{ field: 'quantity', headerName: 'quantity', width: 180 },
    { field: 'type', headerName: Language['Type'], width: 120 },
  ];


  const ImportedDataColumns = [
    { field: 'type', headerName: Language['Type'], width: 120 },
    { field: 'name', headerName: Language['Name'], width: 150 },
    { field: 'reference', headerName: Language['Reference'], width: 150 },
    {
      field: 'price',
      headerName: Language['Price'],
      width: 150,
      renderCell: (params) => {
        if(params.row.price){
          return formatNumberWithMinimumDigits(params.row.price)
        }else{
          return ''
        }
      },
    },
    {
      field: 'stock',
      headerName: Language['Quantity'],
      width: 130,
    },
    { field: 'relatedTo', headerName: Language['Related To'], width: 150 },
    {
      field: 'case',
      headerName: Language['Message'],
      width: 280,
      renderCell: (params) => {
        if(params.row.case === "exist"){
          return (
            <Button variant='outlined' color='success'>{Language['Already exist']}</Button>
          ) 
        }else if(params.row.case === "parentNotExist") {
          return <Button variant='outlined' color='error'>{Language['Related to unfound product']}</Button>
        } else{
          return ""
        }
      },
    },
  ];


  const [selectedRows, setSelectedRows] = useState([]);

  function handleSelectionChange(selectionModel) {
    setSelectedRows(selectionModel.map(rowId => products.find(row => row.id === rowId)));
  }



  const [products, setProducts] = useState([]);

  function getProducts(){
    axios
      .post("/inventory_management/products", {
        
      })
      .then(function (response) {
        console.log(response)
        setProducts(response.data.products)
        setIsLoading(false)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        handleError(error)
      })
  
  }


  const [filter, setFilter] = React.useState('product');
  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
  };
  

  const productColumns = [
    { field: 'id', headerName: Language['Id'], width: 100 },
    { field: 'admin', headerName:Language['Admin'], width: 180 },
    {
      field: 'createdAt',
      headerName: 'Date',
      width: 130,
      renderCell: (params) => {
        const year = params.row.createdAt.slice(0, 4);
        const month = params.row.createdAt.slice(5, 7);
        const day = params.row.createdAt.slice(8, 10);
        const hour = params.row.createdAt.slice(11, 13);
        const minute = params.row.createdAt.slice(14, 16);
        return  <p  style={{margin: '0px', padding: '0px'}} >{year}/{month}/{day} {hour}:{minute}</p>
      },
    },
    {
      field: 'Quantity',
      headerName: Language['Quantity'],
      width: 130,
      renderCell: (params) => {
        return <p style={{width: '100%', textAlign: 'center'}}>{params.row.operationProduct.quantity}</p>
      },
    },
    {
      field: 'operation',
      headerName: Language['Operation'],
      width: 150,
      renderCell: (params) => {
        if(params.row.type === 'received'){
          return <Button startIcon={<ArrowCircleDownOutlinedIcon/ >} style={{backgroundColor: 'rgb(162, 159, 97)'}} variant='contained' >RECEIVED</Button>
        } else if(params.row.type === 'delivered'){
          return <Button  startIcon={<ArrowCircleUpOutlinedIcon/ >} style={{backgroundColor: 'rgb(134, 192, 133)'}} variant='contained' >DELIVERED</Button>
        } else if(params.row.type === 'returned'){
          return <Button  startIcon={<ArrowCircleLeftOutlinedIcon/ >} style={{backgroundColor: 'rgb(232, 137, 137)'}} variant='contained' >RETURNED</Button>
        }
      },
        
    },
    {
      field: 'status',
      headerName: Language['Status'],
      width: 130,
      renderCell: (params) => {
        if(params.row.valid === true){
          return <Button color={"info"} startIcon={<CheckCircleOutlineIcon/ >} style={{color: '#bea4c0fc'}} variant='text' >{Language['Valid']} </Button>
        }else{
          return <Button color={"info"} variant='text' startIcon={<ModeEditOutlineOutlinedIcon/>} style={{color: '#bea4c0fc'}} >{Language['Draft']} </Button>
        }
      },
        
    },
    
        

  ];

  const [isLoading, setIsLoading] = useState(true);
  const [action, setAction] = useState("");

  useEffect(() => {
    handleHeader('Products')
    getProducts()
  }, []);

  const [updateBool, setUpdateBool] = useState(false);
  const [loadingSaveData, setLoadingSaveData] = useState(false);

  function saveImportedData(){
    console.log(updateBool)
    setLoadingSaveData(true)
    axios
      .post("/inventory_management/saveImportedData", {
        importedData, update: updateBool
      })
      .then(function (response) {
        console.log(response)
        setProducts(response.data.products)
        setLoadingSaveData(false)
        setOpenImportedDataModal(false)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        setOpenImportedDataModal(false)
        setLoadingSaveData(false)
        handleError(error)
      })
  }

  if(isLoading){
    return(
      <Body>
        <Loading></Loading>
      </Body>
    )
  }else{
    return (
      <Body>
          <Header>
              <HeaderTitle>
                  <H1>{Language['Products']} </H1>
                  <Button variant='contained' onClick={()=> LinkTo('/app/Inventory_management/product?draft=true')} style={{fontSize: 'small'}} color='success'>{Language['New Product']} </Button>
              </HeaderTitle>
              <HeaderTitle style={{justifyContent: 'end'}}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={filter}
                  onChange={handleChangeFilter}
                  label="filter"
                >
                  <MenuItem value={'product'}>{Language['Products']}</MenuItem>
                  <MenuItem value={'component'}>{Language['Components']}</MenuItem>
                </Select>
              </FormControl>
                    <Tooltip title={Language['Print']}>
                      <IconButton style={{ color: 'white', margin: '5px', height :'min-content', backgroundColor: '#007bff'}} onClick={() => {setAction('Print') ;handleClickOpenModal()}} variant="contained" size='small'><PrintIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title={Language['Export']}>
                      <IconButton style={{color: 'white', margin: '5px', height :'min-content', backgroundColor: '#00cc00'}} onClick={() => {setAction('Export') ;handleClickOpenModal()}} variant="contained" size='small'><PublishIcon/></IconButton>
                    </Tooltip>
                    <Tooltip title={Language['Import']}>
                      <IconButton style={{color: 'white', margin: '5px', height :'min-content', backgroundColor: '#f1c40f'}} variant="contained" size='small' onClick={handleFileSelect}><FileDownloadIcon/></IconButton>
                    </Tooltip>
                    <input
                        type="file"
                        id='fileInput'
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
              </HeaderTitle>
          </Header>
          <BodyContent>
              <Form>
              {products.map((item) => {
                    var name = item.name
                    var title = ''
                    if(item.name){
                      if(item.name.length > 17){
                        name = name.slice(0, 17) + '..'
                        title = item.name
                      }
                    }
                    function formatNumberWithMinimumDigits(number) {
                      let decimalPlaces = parseFloat(number).toString().split('.')[1];
                      let minDigits = decimalPlaces ? Math.max(decimalPlaces.length, 2) : 2;
                      let formattedNumber = parseFloat(number).toFixed(minDigits);
                      return formattedNumber.replace(/(\.\d*?[1-9])0+$/, '$1');
                    }
                    var price = 0
                    if(item.price){
                      price= item.price
                    }
                    var url = '/app/Inventory_management/product?id=' + item.id
                    var subName = ''
                    if(item.valid === false){
                      url += '&draft=true'
                      subName = Language['draft']
                    }
                    if(item.type === filter){
                      return (
                        <ProductContainer onClick={()=> LinkTo(url)}>
                          <ProductContainerImage url={item.image}></ProductContainerImage>
                          <ProductContainerDetails >
                            <Tooltip title={title}>
                              <ProductTitle>{name}  <span style={{color: '#707070ad', fontWeight: "400", marginLeft: "5px"}}> {subName}</span> </ProductTitle>
                            </Tooltip>
                            <ProductDesc> {item.quantity} {Language['Pieces']}  </ProductDesc>
                            <ProductDesc> {formatNumberWithMinimumDigits(price)} DZD</ProductDesc>
                            <ProductType><SquareIcon style={{color: '#ab3fabb1', fontSize: 'small', paddingRight: '4px'}}> </SquareIcon> <span style={{color: '#b449b4d9', fontSize: 'medium', textAlign: 'left', width: '100%', fontSize: 'small'}}>{Language[item.type]}</span>  </ProductType>
                          </ProductContainerDetails>
                        </ProductContainer>
                      )
                    }
                
              })}
              
              </Form>
          </BodyContent>
  

              {/* dialog */}

              <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={openModal}
                onClose={handleCloseModal}
              >
                <DialogTitle>{Language['Choose products']}</DialogTitle>
                <DialogContent>
                  <Box
                    noValidate
                    component="form"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      m: 'auto',
                      width: '100%',
                    }}
                  >
                    <div style={{ height: '400px', width: '100%', marginTop: '10px' }}>            
                      <DataGrid
                        style={{backgroundColor: "#cdcdcd10"}}
                        rows={products}
                        columns={Productcolumns}
                        checkboxSelection
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 7 },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        onSelectionModelChange={handleSelectionChange}
                      />
                    </div>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleCloseModal()}>{Language['Cancel']}</Button>
                  {
                    [''].map(()=>{
                      if(action === 'Export'){
                        return(
                          <Button onClick={() => exportData()}>{Language['Export']}</Button>
                        )
                      }else if(action === 'Print'){
                        return(
                          <Button onClick={() => setOpenFullModal(true)}>{Language['Print']}</Button>
                        )
                      }
                    })
                  }
                </DialogActions>
              </Dialog>



                {/* dialog of imported products*/}

                <Dialog
                  fullWidth={true}
                  maxWidth={'lg'}
                  open={openImportedDataModal}
                  onClose={handleCloseImportedDataModal}
                >
                  <DialogTitle>{Language['Imported products']}
                      <FormControlLabel
                        style={{float: 'right'}}
                        value="start"
                        control={<Switch checked={updateBool} onChange={(e) => setUpdateBool(e.target.checked)} color="primary" />}
                        label={Language['Update existing products']}
                        labelPlacement="start"
                      />  
                  </DialogTitle>
                  <DialogContent>
                    <Box
                      noValidate
                      component="form"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: '100%',
                      }}
                    >
                      <div style={{ height: '400px', width: '100%', marginTop: '10px' }}>            
                        <DataGrid
                          style={{backgroundColor: "#cdcdcd10"}}
                          rows={importedData}
                          columns={ImportedDataColumns}
                          loading={importedDataLoading}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 7 },
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                          getRowClassName={getRowClassName}
                          disableSelectionOnClick
                        />
                      </div>
                    </Box>
                    
                  </DialogContent>

                  
                  <DialogActions>
                      <Button onClick={() => handleCloseImportedDataModal()}>{Language['Cancel']}</Button>
                      <LoadingButton loading={loadingSaveData} loadingPosition='center' onClick={() => saveImportedData()}>{Language['Import']}</LoadingButton>
                  </DialogActions>
                </Dialog>



            {/* full screen modal */}
            <Dialog
              fullScreen
              open={openFullModal}
              onClose={handleCloseFullModal}
              TransitionComponent={Transition}
            >
              <AppBar sx={{ position: 'relative' }} style={{backgroundColor: 'rgb(100, 37, 126)'}}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleCloseFullModal}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  {Language['Products details']}
                  </Typography>
                  <Button variant='contained' autoFocus startIcon={<PrintIcon/>} onClick={() => window.print()}>
                  {Language['Print']}
                  </Button>
                </Toolbar>
              </AppBar>
              {
                selectedRows.map((product, index) => {
                  var operations = product.operations
                  var subProducts = product.subProducts
                  var showAddComponentsContainer = 'none'
                  if(product.type === 'product'){
                    showAddComponentsContainer = 'flex'
                  }
                  return(
                    <Form>
                      <ProductHeader>
                        <ProductHeaderContainerImage>
                          <ProductHeaderImage url={product.image} onload="window.print()"></ProductHeaderImage>
                        </ProductHeaderContainerImage>
                        <ProductHeaderContainerDetails>
                            <SmallTitle>
                              <SmallTitleSpan></SmallTitleSpan>
                              <SmallTitleText>{Language['General']}</SmallTitleText>
                              <SmallTitleSpan></SmallTitleSpan>
                            </SmallTitle>
                          <ProductHeaderContainerDetailsElement style={{flexWrap: 'wrap'}}>
                            <MyTextField id="standard-basic" variant="standard" label={Language['Name']} defaultValue={product.name}  value={product.name} />
                            <FormControl variant="standard" style={{width: '200px', alignSelf: 'end', marginLeft: '20px', '@media (min-width: 768px)':  '{ background-color: #ddd; padding: 30px }' }} >
                              <InputLabel id="demo-simple-select-standard-label">{Language['Type']}</InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  label={Language['Type']}
                                  defaultValue={product.type}
                                  value={product.type}
                                >
                                <MenuItem value={'component'}>{Language['Component']}</MenuItem>
                                <MenuItem value={'product'}>{Language['Product']}</MenuItem>
                              </Select>
                            </FormControl>
                          </ProductHeaderContainerDetailsElement>

                          <ProductHeaderContainerDetailsElement>
                            {/* <MyLabel>Reference</MyLabel> */}
                            <MyTextField   id="standard-basic" variant="standard" label={Language['Reference']} value={product.reference} />
                          </ProductHeaderContainerDetailsElement>

                          <ProductHeaderContainerDetailsElement>
                            {/* <MyLabel>Price</MyLabel> */}
                            <MyTextField  id="standard-basic" variant="standard" label={Language['Price']}  value={formatNumberWithMinimumDigits(product.price)} />
                          </ProductHeaderContainerDetailsElement>


                        </ProductHeaderContainerDetails>
                      </ProductHeader>

                      
                      <AddedComponentsContainer style={{display: showAddComponentsContainer}}>
                            <SmallTitle>
                              <SmallTitleSpan></SmallTitleSpan>
                              <SmallTitleText>{Language['Components']}</SmallTitleText>
                              <SmallTitleSpan></SmallTitleSpan>
                            </SmallTitle>
                            <List
                                sx={{ width: 'calc(100% - 20px)',  bgcolor: 'background.paper' }}
                                style={{backgroundColor: "#cdcdcd10", border: '1px solid #6a696938', padding: '10px'}}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                              >
                                <ListItemButton onClick={handleClick}>
                                  <ListItemIcon>
                                    <CategoryIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={Language['Components']} />
                                  {open ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                  <List component="div" disablePadding>
                                  {
                                    subProducts.map((data) => {
                                      var quantity = 0
                                      if (data.productPack){
                                        quantity = data.productPack.quantity
                                      }
                                      return (
                                        <ProductComponentContainer style={{width: '90%', padding: "5px 5%", borderBottom: '1px solid whitesmoke'}} sx={{ pl: 4 }}>
                                          <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                          </ListItemIcon>
                                          <ListItemText primary={<ProductComponentContainer><ProductComponentContainerTitle>{data.name}<span style={{color: 'gray', fontSize: "small"}}> {quantity} {Language['pieces']}</span> </ProductComponentContainerTitle><IconButton disabled={false} ><DeleteIcon /></IconButton> </ProductComponentContainer>} />
                                        </ProductComponentContainer>

                                      

                                      )
                                      
                                    })
                                  }
                                
                                  </List>
                                </Collapse>
                              </List>

                      </AddedComponentsContainer>

                      <SmallTitle>
                        <SmallTitleSpan></SmallTitleSpan>
                        <SmallTitleText>{Language['Operation']}</SmallTitleText>
                        <SmallTitleSpan></SmallTitleSpan>
                      </SmallTitle>


                      <Box sx={{ width: '100%', typography: 'body1' }} style={{ backgroundColor: "#cdcdcd10", border: '1px solid #6a696938'}}>
                          <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                              <Tab label={Language['Received']} value="1" />
                              <Tab label={Language['Delivered']} disabled={true} value="2" />
                              <Tab label={Language['Returned']} disabled={true} value="3" />
                            </TabList>
                          </Box>

                          
                          <TabPanel value="1">
                            <AddedComponents style={{ paddingBottom: '20px'}}>
                              <MyTextField type='number'  id="standard-basic" helperText={Language['unlimited']}   variant="standard" label={Language['Quantity']} style={{width: '30% ', marginRight: "10px", marginBottom: '10px', flexGrow: 'unset'}}/>
                              <LoadingButton variant='contained' disabled={false}  style={{backgroundColor: '#64257e', color: 'white',marginBottom: '20px',}}>{Language['Received']}</LoadingButton>
                              <AvailableNumberContainer>
                                  <AvailableNumberTitle>{Language['Total inventory']}</AvailableNumberTitle>
                                  <AvailableNumberDesc>{product.quantity} {Language['pieces']}</AvailableNumberDesc>
                              </AvailableNumberContainer>
                            </AddedComponents>
                          </TabPanel>


                          <TabPanel value="2">
                            <AddedComponents style={{ paddingBottom: '20px'}}>
                              <MyTextField type='number' value={'deliveredQuantity'} id="standard-basic" helperText={'limitDelivered' + Language['units']}   variant="standard" label={Language['Quantity']} style={{width: '30% ', marginRight: "10px", marginBottom: '10px', flexGrow: 'unset'}}/>
                              <LoadingButton variant='contained' disabled={false}  loading={false} loadingPosition='start' startIcon={<AddCircleOutlineIcon/>} style={{backgroundColor: '#64257e', color: 'white',marginBottom: '20px',}}>{Language['Delivered']}</LoadingButton>
                              <AvailableNumberContainer>
                                  <AvailableNumberTitle>{Language['Total inventory']}</AvailableNumberTitle>
                                  <AvailableNumberDesc>{'total'} {Language['pieces']}</AvailableNumberDesc>
                              </AvailableNumberContainer>
                            </AddedComponents>
                          </TabPanel>



                          <TabPanel value="3">
                            <AddedComponents style={{ paddingBottom: '20px'}}>
                              <MyTextField type='number' value={'returnedQuantity'} id="standard-basic" helperText={ 'limitReturned' + Language['units']}   variant="standard" label='Quantity' style={{width: '30% ', marginRight: "10px", marginBottom: '10px', flexGrow: 'unset'}}/>
                              <LoadingButton variant='contained' disabled={false} loading={false} loadingPosition='start' startIcon={<AddCircleOutlineIcon/>} style={{backgroundColor: '#64257e', color: 'white',marginBottom: '20px',}}>{Language['Returned']}</LoadingButton>
                              <AvailableNumberContainer>
                                  <AvailableNumberTitle>{Language['Total inventory']}</AvailableNumberTitle>
                                  <AvailableNumberDesc>{'total'} {Language['pieces']}</AvailableNumberDesc>
                              </AvailableNumberContainer>
                            </AddedComponents>
                          </TabPanel>


                        </TabContext>
                      </Box>

                      
                      <SmallTitle>
                        <SmallTitleSpan></SmallTitleSpan>
                        <SmallTitleText>{Language['History']}</SmallTitleText>
                        <SmallTitleSpan></SmallTitleSpan>
                      </SmallTitle>

                      <div 
                      style={{
                        width: '100',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                        margin: '10px 0px',
                      }}
                      >
                        <LocalizationProvider variant='standard' dateAdapter={AdapterDayjs} >
                          <DatePicker
                            label={Language['Select Date']}
                            renderInput={(params) => (
                              <TextField {...params} style={{height: 'max-content'}} />
                            )}
                          />
                        </LocalizationProvider>
                        <Button disabled={true}  variant='outlined' style={{marginLeft: '10px'}}>{Language['Filter']}</Button>
                        <IconButton disabled={true} size='small'  variant='outlined' style={{marginLeft: '10px', height: 'min-content !important', width: 'min-content', alignSelf: 'end', display: 'none'}}></IconButton>
                      </div>


                      <div style={{ height: '400px', width: '100%', marginTop: '10px' }}>            

                        <DataGrid
                          style={{backgroundColor: "#cdcdcd10"}}
                          rows={operations}
                          columns={productColumns}
                          disableSelectionOnClick
                          disableSelection
                          loading={false}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 7 },
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                          sortModel={[{field: 'createdAt',sort: 'desc'}]}
                        />
                      </div>

                    

                    </Form>

                  )
                })
              }

              
             
            </Dialog>
              
            {/* // snackbar */}
            <Snackbar
              open={openAlert}
              autoHideDuration={4000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                {alertMessage}
              </Alert>
            </Snackbar>
  
      </Body>
    )
  }


}
