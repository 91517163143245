import React, {useState, useEffect} from 'react'
import axios from 'axios';
import styled from 'styled-components'
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
//import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate} from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import LoadingContainer from './reusableComponent/loadingContainer';
import AlertComp from './alert';
import { getResponseMessage } from './responding';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Body = styled.div`
position: relative;
  width: 100%;
  height: 100vh;
  margin: 0px 0%;
  display: flex;
  flex-direction: row;
`;

const BodyLogin = styled.div`
  width: 50%;
  height: 100vh;
  margin: 0px 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 900px) {
      width: 100% ;
      
    }
`;
const BodyLoginContent = styled.div`
  width: 60%;
  min-height: 300px;
  height: 100vh;
  margin: 0px 0%;
  display: flex;
  flex-direction: column;
  height: 80%;
  margin-top: 20%;
  max-width: 400px;
  padding: 10px;
  @media only screen and (max-width: 900px) {
      width: 80% ;
      margin-top: 20%;
      background-color: white;
    }
`;

const BodyImage = styled.div`
  width: 42%;
  padding: 15px 4%;
  height: calc(100vh - 30px);
  margin: 0px 0%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center ;
  //background-image: url("/assets/img/login6.png") ;
  background-position: center;
  background-size: cover;
  background-color: #64257e;
  @media only screen and (max-width: 900px) {
      display:none ;
    }
`;


const Title = styled.h1`
  font-size: x-large;
  padding: 0px 0px;
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 0%;
`;

const Paragr = styled.p`
  margin: 0%;
  padding: 10px 0px;
  margin-bottom: 30px;
`;

const Logo = styled.img`
  width: 80px;
  height: auto;
  align-self: center;
  
`;

const MyLink = styled(Link)`
    //text-decoration-line: none;
    color: black;
`
// import logo from './assets/img/logo192.png'

export default function ForgotPassword({setOpenTermsModal}) {


  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [btnContent, setBtnContent] = useState('Reset Password')
  const [isEmailError, setIsEmailError] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [openAlert, setOpenAlert] = useState(false)
  const [disableBtn, setDisableBtn] = useState(false);
  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')

  function resetPassword(email){
    setDisableBtn(true)
    setBtnContent(<CircularProgress style={{color: 'white', width: '30px', height:'30px'}}></CircularProgress>)
    setIsEmailError(false)
    setEmailError('')
    console.log(email)
    let formData = new FormData();
    formData.append('email', email)

    axios.post("/forgot_password", {
      email
    })
        .then(function (response) {
          setDisableBtn(false)
          // handle success
          //setSousCategory(response.data.souscategories)
          setBtnContent('reset password')
          if(response.data.res === "done"){
            document.getElementById('BodyLoginContent').style.display= 'none'
            document.getElementById('BodyLogin_confirm').style.display= 'flex'
          }
          else if(response.data.res === "input_error") {
            console.log(response.data.error)
            console.log(response.data.error.length)
            setIsEmailError(true)
            setEmailError(response.data.error[0].error)
            console.log(response.data)
          }
          else if(response.data.res === "server_error") {
            setOpenAlert(true)
            setAlertMessage(response.data.error)
            setAlertType("error")
            console.log(response.data)
          }
          else{

          }
          
        })
        .catch(function (error) {
          // handle error
          setDisableBtn(false)
          setBtnContent('reset password')
          if(error.response){
            setOpenAlert(true)
            setAlertMessage(getResponseMessage(error.response.status))
            setAlertType("error")
          }else{
            setOpenAlert(true)
            setAlertMessage('internal server error')
            setAlertType("error")
          }

        })
    
  }

  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  function emailInputReset(){
    setIsEmailError(false)
    setEmailError('')
  }


  const [isLoading, setisLoading] = useState(true);
  
  useEffect(() => {
    // get data from database
    axios.post("/check_login")
    .then(function (response) {
      console.log(response.data)
      if(response.data.redirect === false){
        navigate('/App', { replace: true });
      }
      else{
          setisLoading(false)
      }
    })
    .catch(function (error) {
      setisLoading(false)
      // handle error
      console.log(error);
      if(error.response){
        setOpenAlert(true)
        setAlertMessage(getResponseMessage(error.response.status))
        setAlertType("error")
      }else{
        setOpenAlert(true)
        setAlertMessage('internal server error')
        setAlertType("error")
      }
    })
  
  }, [])
 

  if(isLoading){
    return(
      <Body>
        <LoadingContainer open={isLoading}></LoadingContainer>
      </Body>
    )  
 }else{
    return (
      <Body>
          <BodyLogin>
              <BodyLoginContent id='BodyLoginContent' >
                  <Logo src={"./assets/img/logo192.png"} />
                  
                  <Title style={{textAlign: 'left', fontSize: 'x-large'}}>Reset your password</Title>
                  <Paragr style={{width: "100%", textAlign: 'left', color: 'grey'}} >You can reset your password using email to find your account.</Paragr>

                  <FormControl error={isEmailError} variant="standard">
                      <InputLabel htmlFor="component-error">Email</InputLabel>
                      <Input
                      onKeyDown={ (event) => { if (event.key === 'Enter') { resetPassword(email)}}}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        emailInputReset()
                      }}
                      id="component-error"
                      defaultValue=""
                      aria-describedby="component-error-text"
                      />
                      <FormHelperText id="component-error-text">{emailError}</FormHelperText>
                  </FormControl>

                  <Button variant="contained" disabled={disableBtn}  disableElevation  style={{marginTop: '10px', backgroundColor: '#64257e'}} onClick={() => resetPassword(email)}>
                        {btnContent}
                  </Button>
                  
                  <Paragr style={{width: "100%", display: 'flex', flexDirection: 'column',justifyContent: "end" , textAlign: 'center',alignSelf: 'end', color: 'grey', flexGrow: "1"}} ><span>You don't have an account? <MyLink to="/signup" >Create account</MyLink></span></Paragr>

                  <Snackbar
                    open={open}
                    autoHideDuration={4000}
                    onClose={handleClose}
                  >
                    <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                      {alertMessage}
                    </Alert>
                  </Snackbar>
              </BodyLoginContent>


              <BodyLoginContent id='BodyLogin_confirm' style={{display: 'none'}}>
                  <Logo src={"./assets/img/logo192.png"} />
                  
                  <Title>Reset your password</Title>
                  <Paragr style={{width: "100%", textAlign: 'left', color: 'grey'}} >We have already send you a password reset link to your email address, check your mail inbox to reset your password.</Paragr>
                  
                  


              </BodyLoginContent>

          </BodyLogin>
          <BodyImage>
            <Logo style={{alignSelf: 'center', width: '70px'}} src={"./assets/img/logo192.png"} />
            <div style={{justifyContent: 'center'}}>
              <Title style={{color: 'white', fontSize: '40px', textAlign: 'left', width: '100%', marginTop: '0px'}}>Let us help you grow your business</Title>
              <Paragr style={{color: '#ffffffcc', fontSize: '17px', width: "100%"}}>We offre a very helpful marketing tool to help you grow your business fastly and professionaly. the fast you fill up, the fast your business grow.</Paragr>
            </div>
            <>
            <Paragr style={{color: 'white', fontSize: '15px', width: "100%", textAlign:'center', margin: 0}}><MyLink style={{color: "white"}} onClick={()=> setOpenTermsModal(true)} >Our Conditions & Terms.</MyLink></Paragr>
            </>
          </BodyImage>
          <AlertComp message={alertMessage} open={openAlert} handleClose={handleClose} type={alertType} ></AlertComp>
      </Body>
    )
 }
  
}
