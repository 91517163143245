import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import {Button} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import Loading from '../Loading';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import NotFound from '../notFound';
import ReactToPrint from 'react-to-print';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Body = styled.div`
    width: 100%;
`

const Header = styled.div`
    height: 35px;
    width: 96%;
    padding: 10px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px #e1e1e1 solid;
    @media only screen and (max-width: 850px) {
        flex-direction: column !important;
    }
`
const HeaderTitle = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: center;
    @media only screen and (max-width: 850px) {
        width: 100% !important;
        flex-direction: row;
        flex-wrap: wrap;
    }
`


const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    @media only screen and (max-width: 850px) {
      width: 100%;
    }
`

const BodyContent = styled.div`
    position: relative;
    width: 100%;
    background-color: #f6f7fa;
    min-height: calc(100vh - 122px - 20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    flex-grow: 3;
    padding-bottom: 25px;
    @media only screen and (max-width: 850px) {
    }
`

const Form = styled.div`
    width: calc(90% - 40px) ;
    margin: 0px 5%;
    background-color: white;
    min-height: max-content;
    box-shadow: 0px 0px 1px 0px grey;
    padding: 10px 20px;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    @media only screen and (max-width: 850px) {
      width: calc(90% - 10px) ;
      padding: 10px 5px;

    }
`

const DetailContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-left: 0px;

`

const DetailTitle = styled.p`
  margin: 0% 10px 0px 0px;
  padding: 0%;
  color: #808080;
  font-size: medium;
`
const DetailDesc = styled.p`
  margin: 0%;
  padding: 0;
  color: #000000;
  font-size: medium;
`

const ClinetContainer = styled.div`
  padding: 10px;
  width: calc(100% - 20px);
  margin-bottom: 0px;
  transition: 200ms ease-in-out;
  
`

const ClinetContainer1 = styled.div`
  background-color: #cdcdcd10;
  border: 1px solid #6a696938;
  padding: 10px;
  width: calc(100% - 20px);
  margin-bottom: 10px;
  
`

const MyTextField = styled(TextField)`
  //width: 85%;
  min-width: 300px !important;
  padding: 0%;
  margin: 0%;
  flex-grow: 1;
  width: 100%;
`

const SmallTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 25px 0px 10px 0px;
`
const SmallTitleText = styled.p`
  margin: 0%;
  padding: 0% 10px;
  text-align: center;
  width: max-content;
  color: #a947c7
`
const SmallTitleSpan = styled.span`
  height: 1px;
  width: 50%;
  background-color: #dcaeedc2;
`

const AddedComponents = styled.div`
  position: relative;
  padding-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  margin-bottom: 10px;
  flex-wrap: wrap;
  //background-color: #fdf0ffae;
`


const AvailableNumberContainer = styled.div`
  position: absolute;
  top: -5px;
  right: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-left: 10px;
`

const AvailableNumberTitle = styled.p`
  margin: 0% 10px 0px 0px;
  padding: 0;
  color: #808080;
  font-size: medium;
`
const AvailableNumberDesc = styled.p`
  margin: 0%;
  padding: 0;
  color: #000000;
  font-size: medium;
`



export default function Operation({handleHeader, handleError, Language}) {

  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [ openAlert, setOpenAlert] = useState(false)

  const [ loading, setLoading] = useState(true)
  const [ notFound, setNotFound] = useState(false)


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const [checked, setChecked] = React.useState(true);
  const [localFactory, setLocalFactory] = React.useState('flex');
  const [externClient, setExternClient] = React.useState('none');
  
  useEffect(() => {
    if(checked){
      setLocalFactory('flex')
      setExternClient('none')
    }else{
      setLocalFactory('none')
      setExternClient('flex')
    }
  }, [checked]);
  
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };


  function formatNumberWithMinimumDigits(number) {
    let decimalPlaces = parseFloat(number).toString().split('.')[1];
    let minDigits = decimalPlaces ? Math.max(decimalPlaces.length, 2) : 2;
    let formattedNumber = parseFloat(number).toFixed(minDigits);
    return formattedNumber.replace(/(\.\d*?[1-9])0+$/, '$1');
  }


  const columns = [
    { field: 'id', headerName: Language['Id'], width: 100 },
    { field: 'name', headerName: Language['Name'], width: 180 },
    { field: 'reference', headerName: Language['Reference'], width: 180 },
    {
      field: 'price',
      headerName: Language['Price'],
      width: 180,
      renderCell: (params) => {
        if(params.row.price){
          return formatNumberWithMinimumDigits(params.row.price)
        }else{
          return ''
        }
      },
    },
    {
      field: 'qq',
      headerName: Language['Quantity'],
      width: 180,
      renderCell: (params) => {
        if(params.row.operationProduct){
          return params.row.operationProduct.quantity
        }else{
          return '--'
        }
      },
    },
    { field: 'quantity', headerName: Language['Stock'], width: 180 },
    //{ field: 'quantity', headerName: 'quantity', width: 180 },
    { field: 'type', headerName: Language['Type'], width: 120 },
  ];
  
  
  const [ Operation, setOperation] = useState({})
  const [ Costumer, setCostumer] = useState({})
  
  function getDetails (operationId){
    axios
      .post("/inventory_management/operation_details", { operationId })
      .then(function (response) {
          console.log(response.data)
          setOperation(response.data.operation)
          if(response.data.operation.operationCostumer != null ){
            setCostumer(response.data.operation.operationCostumer )
          }
          
          if(response.data.operation.externClient === false){
            setLocalFactory('none')
            setExternClient('flex')
          }else {
            setLocalFactory('flex')
            setExternClient('none')
          }
          setLoading(false)
      })
      .catch(function (error) {
        // handle error
        if(error.response.status === 404){
          setNotFound(true)
          setLoading(false)
        }
        console.log(error.response.status)
        handleError(error)
      })
  }

  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const operationId = queryParams.get('id');

  useEffect(() => {
    handleHeader('Operation')
    getDetails(operationId)
  }, []);

  if(loading === true ){
    return (
      <Body>
          <Loading></Loading>
      </Body>
    )
  }else if (notFound === true ){
    return (
      <Body>
          <NotFound></NotFound>
      </Body>
    )
  }else{
    return (
        <Body>

            
            <Header>
                <HeaderTitle>
                    <H1>{Language['Operation details']} 
                      <Tooltip title={Language['Print']}>
                        <IconButton color='info' onClick={() => window.print()} style={{ margin: '5px 5px 5px 10px', height :'min-content', alignItems: 'right'}} variant="contained" size='small'><PrintIcon /></IconButton>
                      </Tooltip>
                    </H1>
                    
                </HeaderTitle>
            </Header>
            

            
            <BodyContent >
                <Form>

                  <ClinetContainer>
                    <DetailContainer>
                      <DetailTitle>{Language['Operation type']}:</DetailTitle>
                      <DetailDesc>{Language[Operation.type]}</DetailDesc>
                    </DetailContainer>

                    <DetailContainer>
                      <DetailTitle>{Language['Operation reference']}:</DetailTitle>
                      <DetailDesc>{Operation.reference}</DetailDesc>
                    </DetailContainer>

                    <DetailContainer>
                      <DetailTitle>{Language['Admin']}:</DetailTitle>
                      <DetailDesc>{Operation.admin}</DetailDesc>
                    </DetailContainer>

                    <DetailContainer>
                      <DetailTitle>{Language['Date']}:</DetailTitle>
                      <DetailDesc>{  Operation.createdAt.split('T')[0] + '  ' +  Operation.createdAt.split('T')[1].slice(0, 5)  }</DetailDesc>
                    </DetailContainer>
                  </ClinetContainer>
                  
                  

                  <ClinetContainer>
                    
                    <ClinetContainer1>

                      <DetailContainer style={{justifyContent: 'space-between'}}>
                      <DetailTitle>{Language['Client']}</DetailTitle>
                      <DetailDesc>
                        <FormControlLabel control={
                          <Switch
                            checked={Operation.externClient}
                            disabled
                            //onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        } 
                        label={Language['Local operation']}
                        labelPlacement="start" />
                      </DetailDesc>
                    </DetailContainer>

                    <ClinetContainer >
                      <div style={{display: localFactory, width: '100%', transition: '200ms ease-in-out'}}>
                        <DetailDesc>EURL ELAI WALL COMMERCE</DetailDesc>
                      </div>
                      <div style={{display: externClient, flexDirection:'column', transition: '200ms ease-in-out'}}>
                        <MyTextField id="standard-basic" value={Costumer.name} variant="standard" label={Language['Full name']} />
                        <MyTextField id="standard-basic" value={Costumer.address} variant="standard" label={Language['Address']} />
                        <MyTextField id="standard-basic" value={Costumer.rc} variant="standard" label='R.C N°' />
                        <MyTextField id="standard-basic" value={Costumer.nif} variant="standard" label='N.I.F N°' />
                        <MyTextField id="standard-basic" value={Costumer.ai} variant="standard" label='A. I  N°' />
                        <MyTextField id="standard-basic" value={Costumer.nis} variant="standard" label='N.I.S N°' />
                      </div>
                    </ClinetContainer>
                      
                    </ClinetContainer1>
                    
                  </ClinetContainer>

                <SmallTitle>
                    <SmallTitleSpan></SmallTitleSpan>
                    <SmallTitleText>{Language['Products']}</SmallTitleText>
                    <SmallTitleSpan></SmallTitleSpan>
                  </SmallTitle> 

                


                  <div style={{ height: '400px', width: '100%', marginTop: '10px' }}>            

                  <DataGrid
                    style={{backgroundColor: "#cdcdcd10"}}
                    rows={Operation.products}
                    columns={columns}
                    disableSelectionOnClick
                    disableSelection
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 7 },
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                  />
                  </div>

                </Form>




            </BodyContent>

              {/* // snackbar */}
              <Snackbar
                open={openAlert}
                autoHideDuration={4000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                  {alertMessage}
                </Alert>
              </Snackbar>

        </Body>
      )
  }

  
}
