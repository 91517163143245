import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import PublishIcon from '@mui/icons-material/Publish';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Loading from '../Loading';
import DescriptionIcon from '@mui/icons-material/Description';
import Billing from './print';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Dialog from '@mui/material/Dialog';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Body = styled.div`
    width: 100%;
`

const Header = styled.div`
    height: 35px;
    width: 96%;
    padding: 10px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px #e1e1e1 solid;
    @media only screen and (max-width: 850px) {
        flex-direction: column !important;
    }
`


const ClinetContainer = styled.div`
  padding: 10px;
  width: calc(100% - 20px);
  margin-bottom: 0px;
  margin-top: 20px;
  transition: 200ms ease-in-out;
  
`

const ClinetContainer1 = styled.div`
  background-color: #cdcdcd10;
  border: 1px solid #6a696938;
  padding: 10px;
  width: calc(100% - 20px);
  margin-bottom: 10px;
  
`

const MyTextField = styled(TextField)`
  //width: 85%;
  min-width: 300px !important;
  padding: 0%;
  margin: 0%;
  flex-grow: 1;
  width: 100%;
`

const SmallTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 25px 0px 10px 0px;
`
const SmallTitleText = styled.p`
  margin: 0%;
  padding: 0% 10px;
  text-align: center;
  width: max-content;
  color: #a947c7
`
const SmallTitleSpan = styled.span`
  height: 1px;
  width: 50%;
  background-color: #dcaeedc2;
`


const HeaderTitle = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: center;
    @media only screen and (max-width: 850px) {
        width: 100% !important;
        flex-direction: row;
        flex-wrap: wrap;
    }
`


const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    @media only screen and (max-width: 850px) {
      width: 100%;
    }
`

const BodyContent = styled.div`
    position: relative;
    width: 100%;
    background-color: #f6f7fa;
    min-height: calc(100vh - 122px - 20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    flex-grow: 3;
    padding-bottom: 25px;
    @media only screen and (max-width: 850px) {
    }
`

const Form = styled.div`
    width: calc(90% - 40px) ;
    margin: 0px 5%;
    background-color: white;
    min-height: max-content;
    box-shadow: 0px 0px 1px 0px grey;
    padding: 10px 20px;
    margin-top: 25px;
    @media only screen and (max-width: 850px) {
      width: calc(90% - 10px) ;
      padding: 10px 5px;

    }
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
`

const DetailContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-left: 0px;

`

const DetailTitle = styled.p`
  margin: 0% 10px 0px 0px;
  padding: 0%;
  color: #808080;
  font-size: medium;
`
const DetailDesc = styled.p`
  margin: 0%;
  padding: 0;
  color: #000000;
  font-size: medium;
`


export default function History({handleHeader, handleError, Language}) {

  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [ openAlert, setOpenAlert] = useState(false)
  const navigate = useNavigate()


  // mui



  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };


  const [selectedRows, setSelectedRows] = useState([]);

  function handleSelectionChange(selectionModel) {
    setSelectedRows(selectionModel.map(rowId => history.find(row => row.id === rowId)));
  }


  const [openModal, setOpenModal] = React.useState(false);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  // mui

  function exportSelectedToCsv(selectedRows, columns, filename) {
    const columnTitles = ['id', 'reference', 'type', 'admin', 'costumer', 'createdAt'];
    const rowData = selectedRows.map(({ id, reference, type, admin, costumer, createdAt }) => [id, reference, type, admin, costumer, createdAt]);
    const csvContent = "data:text/csv;charset=utf-8," + [columnTitles.join(","), ...rowData.map(row => row.join(","))].join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  function handleExportClick() {
    if(selectedRows.length === 0){
      setAlertMessage(Language['No row selected.'])
      setAlertType('error')
      setOpenAlert(true)
      setInterval(() => {
        setOpenAlert(false)
      }, 3000);
    }else{
      exportSelectedToCsv(selectedRows, columns, 'selected_rows.csv');
    }
  }



  const fileInputRef = useRef(null);

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };


  const handleFileChange = (event) => {
    console.log('worked')
    const selectedFile = event.target.files[0];
    console.log(event.target.files[0])
    const formData = new FormData();
    formData.append('file', selectedFile);
    axios
      .post('/inventory_management/import_operations', formData)
      .then((response) => {
        // Handle success
        console.log(response.data);
      })
      .catch((error) => {
        // Handle error
        handleError(error)
    });
};


  function handleprintClick() {
    if(selectedRows.length === 0){
      setAlertMessage(Language['No row selected.'])
      setAlertType('error')
      setOpenAlert(true)
      setInterval(() => {
        setOpenAlert(false)
      }, 3000);
    }else{
      // console.log(selectedRows)
      setOpenModal(true)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  
  const columns = [
    { field: 'id', headerName: Language['Id'], width: 100 },
    { field: 'admin', headerName: Language['Admin'], width: 180 },
    {
      field: 'reference',
      headerName: Language['Reference'],
      width: 180,
      renderCell: (params) => {
        if(params.row.reference){
          return params.row.reference.replace('/', '-')
        }
        return '--'
      },
    },
    {
      field: 'createdAt',
      headerName: Language['Date'] ,
      width: 130,
      renderCell: (params) => {
        const year = params.row.createdAt.slice(0, 4);
        const month = params.row.createdAt.slice(5, 7);
        const day = params.row.createdAt.slice(8, 10);
        const hour = params.row.createdAt.slice(11, 13);
        const minute = params.row.createdAt.slice(14, 16);
        return  <p  style={{margin: '0px', padding: '0px'}} >{year}/{month}/{day} {hour}:{minute}</p>
      },
    },
    {
      field: 'costumer',
      headerName: Language['Costumer'],
      width: 180,
      renderCell: (params) => {
        var costumer = '--'
        if(params.row.costumer){
          costumer = params.row.costumer
        }
        return <span style={{width: '100%', textAlign: 'center'}}>{costumer}</span>
      },
    },
    {
      field: 'valid',
      headerName: Language['Type'],
      width: 130,
      renderCell: (params) => {
        var status = Language['draft']
        if(params.row.valid === true){
          status = Language['complited']
          return  <Button disabled variant='text' color={'info'} style={{color: "#35648d", borderColor: '#35648d'}} >{status}</Button>
        }else{
          return  <Button disabled variant='text' color={'info'} style={{color: "#358d68", borderColor: '#358d68'}} >{status}</Button>
        }
      },
    },
    {
      field: 'action',
      headerName: Language['Action'],
      width: 130,
      renderCell: (params) => {
        var type = params.row.type
        var valid = params.row.valid
        if(params.row.valid === true){
          return <Button  variant='contained' endIcon={<OpenInNewIcon/>} onClick={(e) => {e.stopPropagation(); navigate('/app/inventory_management/operation?id=' + params.row.id)}} color={'info'} style={{backgroundColor: "#007BFF", borderColor: '#358d68'}} >{Language['Details']}</Button>
        }else{
          return  <Button  variant='contained' endIcon={<OpenInNewIcon/>} onClick={(e) => {e.stopPropagation(); navigate('/app/inventory_management/new_operation?type='+ params.row.type +'&id=' + params.row.id)}} color={'info'} style={{backgroundColor: "#007BFF", borderColor: '#358d68'}} >{Language['Details']}</Button>
        }
      },
    },
    {
      field: 'Facturation',
      headerName: Language['Facturation'],
      width: 130,
      renderCell: (params) => {
        var type = params.row.type
        var valid = params.row.valid
        if(params.row.valid === true){
          return (
            <>
            <Button  variant='contained' endIcon={<DescriptionIcon/>} 
            // onClick={(e) => {e.stopPropagation(); navigate('/app/invoice_management/invoice?id=' + params.row.id)}} 
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={(e)=> {e.stopPropagation(); setInvoiceId(params.row.id); handleClick(e)}}
            color={'info'} style={{backgroundColor: "#358d68", borderColor: '#358d68', color: 'white'}} >{Language['Billing']}</Button>
            
          </>
          )
        }else{
          return  Language['Draft'] + '..'
        }
      },
    }
  ];


  function formatNumberWithMinimumDigits(number) {
    let decimalPlaces = parseFloat(number).toString().split('.')[1];
    let minDigits = decimalPlaces ? Math.max(decimalPlaces.length, 2) : 2;
    let formattedNumber = parseFloat(number).toFixed(minDigits);
    return formattedNumber.replace(/(\.\d*?[1-9])0+$/, '$1');
  }


  const Productcolumns = [
    { field: 'id', headerName: Language['Id'], width: 100 },
    { field: 'name', headerName: Language['Name'], width: 180 },
    { field: 'reference', headerName: Language['Reference'], width: 180 },
    {
      field: 'price',
      headerName: Language['Price'],
      width: 180,
      renderCell: (params) => {
        if(params.row.price){
          return formatNumberWithMinimumDigits(params.row.price)
        }else{
          return ''
        }
      },
    },
    {
      field: 'qq',
      headerName: Language['Quantity'],
      width: 180,
      renderCell: (params) => {
        if(params.row.operationProduct){
          return params.row.operationProduct.quantity
        }else{
          return '--'
        }
      },
    },
    { field: 'quantity', headerName: Language['Stock'], width: 180 },
    //{ field: 'quantity', headerName: 'quantity', width: 180 },
    { field: 'type', headerName: Language['Type'], width: 120 },
  ];


  const [invoiceId, setInvoiceId] = useState('');

  

  const [history, setHistory] = useState([]);
  const [historyCount, setHistoryCount] = useState(0);

  const [checked, setChecked] = React.useState(true);
  const [localFactory, setLocalFactory] = React.useState('flex');
  const [externClient, setExternClient] = React.useState('none');
  
  function getHistory (){
    axios
      .post("/inventory_management/history", { operation: type})
        .then(function (response) {
          setIssLoading(false)
          console.log(response.data)
          setHistory(response.data.history)
          setHistoryCount(response.data.history.length)
      })
      .catch(function (error) {
        // handle error
        handleError(error)
      })
  }

  /* print billing */
  function handlePrint(type) {
    console.log(type)
      if(type === 'print'){
        navigate('/app/invoice_management/invoice?id=' + invoiceId)
      }else if(type === 'save'){
        axios
          .post("/invoice_management/save", { operationId: invoiceId})
            .then(function (response) {
              console.log(response.data)
              handlePrint('print')
          })
          .catch(function (error) {
            // handle error
            handleError(error)
          })
      }
  }


  const [isLoading, setIssLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const [pageTitle, setPageTitle] = useState('');

  const [ Operation, setOperation] = useState({})
  const [ Costumer, setCostumer] = useState({})


  useEffect(() => {
    handleHeader('History')
    if(type !== 'received' && type !== 'delivered' && type !== 'returned'){
      navigate('/app/Inventory_management')
    }else {
      setPageTitle(type)
      getHistory(type)
    }
  }, []);


  if(isLoading){
    return (
      <Body>
        <Loading></Loading>
      </Body>
    )
  }else{
    return (
      <Body>
          <Header>
              <HeaderTitle>
                  <H1>{Language['Operation']} : {Language[pageTitle]}  <span style={{color: 'gray', fontSize: '15px'}}>( {historyCount} {Language['operations']}   )</span></H1>
              </HeaderTitle>
          </Header>
          <BodyContent>
              <Form>
                <HeaderContainer>
                  <Button style={{margin: '5px', height :'min-content', backgroundColor: '#64257e'}} startIcon={<AddCircleOutlineIcon/>} onClick={()=> navigate('/app/Inventory_management/new_operation?type=' + pageTitle)} variant="contained" size='small'>{Language['New Operation']} </Button>
                  <div>
                    <Tooltip title={Language['Print']}>
                      <IconButton style={{ color: 'white', margin: '5px', height :'min-content', backgroundColor: '#007bff'}} onClick={() => handleprintClick()} variant="contained" size='small'><PrintIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title={Language['Export']}>
                      <IconButton style={{color: 'white', margin: '5px', height :'min-content', backgroundColor: '#00cc00'}} variant="contained" size='small' onClick={handleExportClick}><PublishIcon/></IconButton>
                    </Tooltip>
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                  </div>
                </HeaderContainer>
                <div style={{ height: 'calc(100vh - 255px)', width: '100%', marginTop: '10px' }}>
                  <DataGrid
                    rows={history}
                    columns={columns}
                    checkboxSelection
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                    //onRowClick={handleRowClick}
                    rowClassName={(params) => 'custom-row-class'}
                    sortModel={[{field: 'createdAt',sort: 'desc'}]}
                    onSelectionModelChange={handleSelectionChange}
                  />
                </div>
                <Menu
                  style={{boxShadow: 'red 0px 0px 5px 5px'}}
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={()=> handlePrint('print')}>
                    <ListItemIcon>
                      <PrintIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{Language['Print']}</ListItemText>
                  </MenuItem>

                  <MenuItem onClick={()=> handlePrint('save')}>
                    <ListItemIcon>
                      <SaveIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{Language['Save & Print']}</ListItemText>
                  </MenuItem>
                </Menu>
              </Form>
          </BodyContent>

          {/* dialog */}
          <Dialog
            fullScreen
            open={openModal}
            onClose={handleCloseModal}
            TransitionComponent={Transition}
          >
            <AppBar sx={{ position: 'relative' }} style={{backgroundColor: 'rgb(100, 37, 126)'}}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseModal}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  {Language['Operations details']}
                </Typography>
                <Button variant='contained' autoFocus startIcon={<PrintIcon/>} onClick={() => window.print()}>
                  {Language['Print']}
                </Button>
              </Toolbar>
            </AppBar>

            {selectedRows.map((Operation, index) => {
              var Costumer = {}
              if(Operation.operationCostumer){
                Costumer = Operation.operationCostumer
              }

              var checked = true
              var localFactory = 'flex'
              var externClient = 'none'
              console.log("extern client? ", (Operation.internClient === 'false'))
              if(Operation.externClient === false){
                localFactory = 'none'
                externClient = 'flex'
              }
              return (
                <Form>

                <SmallTitle style={{borderBottom: '2px solid black', paddingBottom: '5px'}}>
                    <SmallTitleText>Operation_{index}</SmallTitleText>
                  </SmallTitle> 

                  <ClinetContainer>
                    <DetailContainer>
                      <DetailTitle>{Language['Operation type']}:</DetailTitle>
                      <DetailDesc>{Language[Operation.type]}</DetailDesc>
                    </DetailContainer>

                    <DetailContainer>
                      <DetailTitle>{Language['Operation reference']}:</DetailTitle>
                      <DetailDesc>{Operation.reference}</DetailDesc>
                    </DetailContainer>

                    <DetailContainer>
                      <DetailTitle>{Language['Admin']}:</DetailTitle>
                      <DetailDesc>{Operation.admin}</DetailDesc>
                    </DetailContainer>

                    <DetailContainer>
                      <DetailTitle>{Language['Date']}:</DetailTitle>
                      <DetailDesc>{  Operation.createdAt.split('T')[0] + '  ' +  Operation.createdAt.split('T')[1].slice(0, 5)  }</DetailDesc>
                    </DetailContainer>
                  </ClinetContainer>



                  <ClinetContainer>
                    
                    <ClinetContainer1>

                      <DetailContainer style={{justifyContent: 'space-between'}}>
                      <DetailTitle>{Language['Client']}</DetailTitle>
                      <DetailDesc>
                        <FormControlLabel control={
                          <Switch
                            checked={Operation.externClient}
                            disabled
                            //onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        } 
                        label={Language['Local operation']}
                        labelPlacement="start" />
                      </DetailDesc>
                    </DetailContainer>

                    <ClinetContainer >
                      <div style={{display: localFactory, width: '100%', transition: '200ms ease-in-out'}}>
                        <DetailDesc>EURL ELAI WALL COMMERCE</DetailDesc>
                      </div>
                      <div style={{display: externClient, flexDirection:'column', transition: '200ms ease-in-out'}}>
                        <MyTextField id="standard-basic" value={Costumer.name} variant="standard" label={Language['Full name']} />
                        <MyTextField id="standard-basic" value={Costumer.address} variant="standard" label={Language['Address']} />
                        <MyTextField id="standard-basic" value={Costumer.rc} variant="standard" label='R.C N°' />
                        <MyTextField id="standard-basic" value={Costumer.nif} variant="standard" label='N.I.F N°' />
                        <MyTextField id="standard-basic" value={Costumer.ai} variant="standard" label='A. I  N°' />
                        <MyTextField id="standard-basic" value={Costumer.nis} variant="standard" label='N.I.S N°' />
                      </div>
                    </ClinetContainer>
                      
                    </ClinetContainer1>
                    
                  </ClinetContainer>

                  <SmallTitle>
                    <SmallTitleSpan></SmallTitleSpan>
                    <SmallTitleText>{Language['Products']}</SmallTitleText>
                    <SmallTitleSpan></SmallTitleSpan>
                  </SmallTitle> 




                  <div style={{ height: '400px', width: '100%', marginTop: '10px' }}>            
                  <DataGrid
                    style={{backgroundColor: "#cdcdcd10"}}
                    rows={Operation.products}
                    columns={Productcolumns}
                    disableSelectionOnClick
                    disableSelection
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 7 },
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                  />
                
                  </div>

                </Form>
              )
            })}
            
            
          </Dialog>
  
            {/* // snackbar */}
            <Snackbar
              open={openAlert}
              autoHideDuration={4000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                {alertMessage}
              </Alert>
            </Snackbar>
  
      </Body>
    )
  }
}
