import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import PublishIcon from '@mui/icons-material/Publish';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Loading from '../Loading';
import DescriptionIcon from '@mui/icons-material/Description';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Body = styled.div`
    width: 100%;
`

const Header = styled.div`
    height: 35px;
    width: 96%;
    padding: 10px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px #e1e1e1 solid;
    @media only screen and (max-width: 850px) {
        flex-direction: column !important;
    }
`
const HeaderTitle = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: center;
    @media only screen and (max-width: 850px) {
        width: 100% !important;
        flex-direction: row;
        flex-wrap: wrap;
    }
`


const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    @media only screen and (max-width: 850px) {
      width: 100%;
    }
`

const BodyContent = styled.div`
    position: relative;
    width: 100%;
    background-color: #f6f7fa;
    min-height: calc(100vh - 122px - 20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    flex-grow: 3;
    padding-bottom: 25px;
    @media only screen and (max-width: 850px) {
    }
`

const Form = styled.div`
    width: calc(90% - 40px) ;
    margin: 0px 5%;
    background-color: white;
    min-height: max-content;
    box-shadow: 0px 0px 1px 0px grey;
    padding: 10px 20px;
    margin-top: 25px;
    @media only screen and (max-width: 850px) {
      width: calc(90% - 10px) ;
      padding: 10px 5px;

    }
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
`


export default function History({handleHeader, handleError, Language}) {

  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [ openAlert, setOpenAlert] = useState(false)
  const navigate = useNavigate()

  // mui

  const [openModal, setOpenModal] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };



  // mui

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  
  const columns = [
    { field: 'name', headerName: Language['Name'], width: 180 },
    {
      field: 'address',
      headerName: Language['Address'],
      width: 180,
      renderCell: (params) => {
        return params.row.address
      },
    },
    {
      field: 'tax',
      headerName: Language['Tax identification details'],
      width: 180,
      renderCell: (params) => {
        return '--'
      },
    },
    {
      field: 'email',
      headerName: Language['Email'],
      width: 180,
      renderCell: (params) => {
        return '--'
      },
    },
    {
      field: 'company',
      headerName: Language['Company'],
      width: 180,
      renderCell: (params) => {
        return '--'
      },
    },

    
    {
      field: 'action',
      headerName: Language['Bills'],
      width: 130,
      renderCell: (params) => {
          return <Button  variant='contained' endIcon={<OpenInNewIcon/>} onClick={(e) => {e.stopPropagation(); setOperations(params.row.costumerOperations); setOpenModal(true)}} color={'info'} style={{backgroundColor: "#007BFF", borderColor: '#358d68'}} >{Language['Details']}</Button>
      },
    },

    {
      field: 'createdAt',
      headerName: Language['First collaboration'],
      width: 130,
      renderCell: (params) => {
        const year = params.row.createdAt.slice(0, 4);
        const month = params.row.createdAt.slice(5, 7);
        const day = params.row.createdAt.slice(8, 10);
        const hour = params.row.createdAt.slice(11, 13);
        const minute = params.row.createdAt.slice(14, 16);
        return  <p  style={{margin: '0px', padding: '0px'}} >{year}/{month}/{day} {hour}:{minute}</p>
      },
    },
    
    
  ];




  const columnsOperations = [
    {
      field: 'createdAt',
      headerName: Language['First collaboration'],
      width: 130,
      renderCell: (params) => {
        const year = params.row.createdAt.slice(0, 4);
        const month = params.row.createdAt.slice(5, 7);
        const day = params.row.createdAt.slice(8, 10);
        const hour = params.row.createdAt.slice(11, 13);
        const minute = params.row.createdAt.slice(14, 16);
        return  <p  style={{margin: '0px', padding: '0px'}} >{year}/{month}/{day} {hour}:{minute}</p>
      },
    },
    { field: 'reference', headerName: Language['Reference'], width: 180 },    
    { field: 'admin', headerName: Language['Admin'], width: 180 },    
    {
      field: 'operation',
      headerName: Language['Operation'],
      width: 150,
      renderCell: (params) => {
        if(params.row.type === 'received'){
          return <Button startIcon={<ArrowCircleDownOutlinedIcon/ >} style={{backgroundColor: 'rgb(162, 159, 97)'}} variant='contained' >{Language['Received']}</Button>
        } else if(params.row.type === 'delivered'){
          return <Button  startIcon={<ArrowCircleUpOutlinedIcon/ >} style={{backgroundColor: 'rgb(134, 192, 133)'}} variant='contained' >{Language['Delivered']}</Button>
        } else if(params.row.type === 'returned'){
          return <Button  startIcon={<ArrowCircleLeftOutlinedIcon/ >} style={{backgroundColor: 'rgb(232, 137, 137)'}} variant='contained' >{Language['Returned']}</Button>
        }
      },
        
    },
    {
      field: 'valid',
      headerName: Language['Status'],
      width: 130,
      renderCell: (params) => {
        var status = Language['draft']
        if(params.row.valid === true){
          status = Language['complited']
          return  <Button disabled variant='text' color={'info'} style={{color: "#35648d", borderColor: '#35648d'}} >{Language[status]}</Button>
        }else{
          return  <Button disabled variant='text' color={'info'} style={{color: "#358d68", borderColor: '#358d68'}} >{Language[status]}</Button>
        }
      },
    },
    {
      field: 'action',
      headerName: Language['Action'],
      width: 130,
      renderCell: (params) => {
        var type = params.row.type
        var valid = params.row.valid
        if(params.row.valid === true){
          return <Button  variant='contained' endIcon={<OpenInNewIcon/>} onClick={(e) => {e.stopPropagation(); navigate('/app/inventory_management/operation?id=' + params.row.id)}} color={'info'} style={{backgroundColor: "#007BFF", borderColor: '#358d68'}} >{Language['Details']}</Button>
        }else{
          return  <Button  variant='contained' endIcon={<OpenInNewIcon/>} onClick={(e) => {e.stopPropagation(); navigate('/app/inventory_management/new_operation?type='+ params.row.type +'&id=' + params.row.id)}} color={'info'} style={{backgroundColor: "#007BFF", borderColor: '#358d68'}} >{Language['Details']}</Button>
        }
      },
    },
    
  ];
  

 


  const [Costumer, setCostumer] = useState([]);
  const [Operations, setOperations] = useState([]);
  const [costumerCount, setCostumerCount] = useState(0);
  
  function getHistory (){
    axios
      .post("/costumer_management/costumers", { operation: type})
        .then(function (response) {
          setIssLoading(false)
          console.log(response.data)
          setCostumer(response.data.costumers)
          setCostumerCount(response.data.costumers.length)
      })
      .catch(function (error) {
        // handle error
        handleError(error)
      })
  }

  const [isLoading, setIssLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    handleHeader('History')
    if(! (type !== 'received' && type !== 'delivered' && type !== 'returned')){
      navigate('/app/Inventory_management')
    }else {
      setPageTitle(type)
      getHistory(type)
    }
  }, []);
  if(isLoading){
    return (
      <Body>
        <Loading></Loading>
      </Body>
    )
  }else{
    return (
      <Body>
          <Header>
              <HeaderTitle>
                  <H1>{Language['Costumer']}: {pageTitle} <span style={{color: 'gray', fontSize: '15px'}}>( {costumerCount} {Language['costumers']} )</span></H1>
              </HeaderTitle>
          </Header>
          <BodyContent>
              <Form>
                <div style={{ height: 'calc(100vh - 255px)', width: '100%', marginTop: '10px' }}>
                  <DataGrid
                    rows={Costumer}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                    //onRowClick={handleRowClick}
                    rowClassName={(params) => 'custom-row-class'}
                    sortModel={[{field: 'createdAt',sort: 'desc'}]}
                  />
                </div>
              </Form>
          </BodyContent>



          {/* dialog */}

          <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={openModal}
            onClose={handleCloseModal}
          >
            <DialogTitle>{Language['Costumer operations']}</DialogTitle>
            <DialogContent>
              <div style={{ height: 'calc(100vh - 255px)', width: '100%', marginTop: '10px' }}>
                  <DataGrid
                    rows={Operations}
                    columns={columnsOperations}
                    checkboxSelection
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                    //onRowClick={handleRowClick}
                    rowClassName={(params) => 'custom-row-class'}
                    sortModel={[{field: 'createdAt',sort: 'desc'}]}
                  />
                </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal}>{Language['Close']}</Button>
            </DialogActions>
          </Dialog>


  
            {/* // snackbar */}
            <Snackbar
              open={openAlert}
              autoHideDuration={4000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                {alertMessage}
              </Alert>
            </Snackbar>
  
      </Body>
    )
  }
}
