import React, {  useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Tooltip from '@mui/material/Tooltip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { getResponseMessage } from './responding';
import SettingsIcon from '@mui/icons-material/Settings';
import { DataGrid } from '@mui/x-data-grid';
import { AddCircle, Delete, EditAttributes, Lock, LockSharp } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LoadingButton } from '@mui/lab';
import { IconButton } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Body = styled.div`
    width: 100%;
`




const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    @media only screen and (max-width: 850px) {
      width: 40%;
    }
`

const P = styled.p`
    margin: 0%;
    padding: 0%;
    font-size: medium;
    font-weight: 400;
    margin-right: 20px;
    color: grey;
    @media only screen and (max-width: 850px) {
      width: 40%;
    }
`

const BodyContent = styled.div`
    position: relative;
    width: 100%;
    background-color: #f6f7fa;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    flex-grow: 3;
    @media only screen and (max-width: 850px) {
    }
`
const Form = styled.div`
    width: calc(90% - 40px) ;
    margin: 2% 5%;
    background-color: white;
    min-height: max-content;
    box-shadow: 0px 0px 1px 0px grey;
    padding: 10px 20px;
    @media only screen and (max-width: 850px) {
      width: calc(90% - 10px) ;
      padding: 10px 5px;

    }
`

const BtnContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 10px;
`

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};





export default function Users({email, firstName, familyName, setUserName, setFamilyName, setFirstName, Language}) {
    

  // mui
  const [openAddUserModal, setOpenAddUserModal] = React.useState(false);
  const handleClickOpenAddUserModal = () => {
    setOpenAddUserModal(true);
  };
  const handleCloseAddUserModal = () => {
    setOpenAddUserModal(false);
  };


  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const handleClickOpenConfirmModal = () => {
    setOpenConfirmModal(true);
  };
  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const [openEditUserModal, setOpenEditUserModal] = React.useState(false);
  const handleClickOpenEditUserModal = () => {
    setOpenEditUserModal(true);
  };
  const handleCloseEditUserModal = () => {
    setOpenEditUserModal(false);
  };

  const [privilege, setPrivilege] = React.useState('');
  const handleChangePrivilege = (event) => {
    setPrivilege(event.target.value);
  };

  const [state, setState] = React.useState({
    add: true,
    edit: false,
    delete: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const [stateSelected, setStateSelected] = React.useState({
    add: true,
    edit: false,
    delete: false,
  });


  const handleChangeState = (event) => {
    setStateSelected({
      ...stateSelected,
      [event.target.name]: event.target.checked,
    });
  };


  function handleError(error){
    if(error.response.status === 400 && error.response.data.response === 'access_denied'){
      setAlertMessage(Language[error.response.data.errors])
      navigate('/app', { replace: true });
      // redirect      
    }else if(error.response.status === 400 && error.response.data.response === 'server_error'){
      setAlertMessage(error.response.data.errors)      
    }else if(error.response.status === 429 ){
      // too many requests
      setAlertMessage(Language['too many requests.'])      
    }else if(error.response.status && error.response.statusText !== '' ){
      setAlertMessage(Language[error.response.statusText])      
    }else{
      setAlertMessage(Language['An error has occured.'])      
    }
    setOpenAlert(true)
    setAlertType("error")
    setTimeout(() => {
      setOpenAlert(false)
    }, 3000);
  }


  const [createUserLoading, setCreateUserLoading] = useState(false);
  const [editUserLoading, setEditUserLoading] = useState(false);
  const [deleteUserLoading, setDeleteUserLoading] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  function createUser() {
    setCreateUserLoading(true)
    axios
      .post("/users/createUser", { email: userEmail, privilege: state})
        .then(function (response) {
          setUsers(response.data.users)
          setCreateUserLoading(false)
          setOpenAddUserModal(false)
      })
      .catch(function (error) {
        // handle error
        setCreateUserLoading(false)
        if(error.response.status === 400 && error.response.data.response === 'input_error'){
          for (let i = 0; i < error.response.data.error.length; i++) {
            const err = error.response.data.error[i];
            if(err.path === 'email'){
              setEmailError(Language[err.error])
              setIsErrorEmail(true)
            }
          }
        }else{
          handleError(error)
        }
        
      })
  }


  function deleteUser() {
    setCreateUserLoading(true)
    axios
      .post("/users/deleteUser", { id: selectedUser.id })
        .then(function (response) {
          setUsers(response.data.users)
          setDeleteUserLoading(false)
          setOpenConfirmModal(false)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        setDeleteUserLoading(false)
        handleError(error)
      })
  }


  function editUser() {
    setEditUserLoading(true)
    axios
      .post("/users/editUser", { id: selectedUser.id, privileges: stateSelected})
        .then(function (response) {
          console.log(response)
          setUsers(response.data.users)
          setEditUserLoading(false)
          setOpenEditUserModal(false)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        setEditUserLoading(false)
        handleError(error)
      })
  }


  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [ openAlert, setOpenAlert] = useState(false)
  const [ serverError, setServerError] = useState(false)
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = (link) => {
      if(link === 'account'){
          navigate('/app/profile');
      }else if(link === 'logout'){
            // get data from database
              axios.post("/logout")
              .then(function (response) {
                  console.log(response.data)

                  if(response.data.res === 'done'){
                      navigate('/login', { replace: true });
                  }else if(response.data.res === 'redirect'){
                      navigate('/login', { replace: true });
                  }
                  else{
                      setServerError(true)
                  }
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
                  if(error.response){
                      setOpenAlert(true)
                      setAlertMessage(Language[error.response.statusText])
                      setAlertType("error")
                    }else{
                      setOpenAlert(true)
                      setAlertMessage(Language['internal server error.'])
                      setAlertType("error")
                    }
              })
      }
      setAnchorEl(null);
  };

  const [selectedUserPrivilege, setSelectedUserPrivilege] = useState({});

  const columns = [
    { field: 'id', headerName: Language['Id'], width: 100 },
    { field: 'family_name', headerName: Language['Family name'], width: 120 },
    { field: 'first_name', headerName: Language['First name'], width: 120 },
    { field: 'email', headerName: Language['Email'], width: 270 },
    {
      field: 'active',
      headerName: Language['Status'],
      width: 130,
      renderCell: (params) => {
        var status = 'Invited'
        if(params.row.active === true){
          status = 'Active'
          return  <Button disabled variant='outlined' color={'info'}  style={{color: "#35648d", borderColor: '#35648d'}} >{Language[status]}</Button>
        }else{
          return  <Button disabled variant='outlined' color={'info'}  style={{color: "#358d68", borderColor: '#358d68'}} >{Language[status]}</Button>
        }
      },
    },
    {
      field: 'createdAt',
      headerName: Language['Date'] ,
      width: 130,
      renderCell: (params) => {
        const year = params.row.createdAt.slice(0, 4);
        const month = params.row.createdAt.slice(5, 7);
        const day = params.row.createdAt.slice(8, 10);
        const hour = params.row.createdAt.slice(11, 13);
        const minute = params.row.createdAt.slice(14, 16);
        return  <p  style={{margin: '0px', padding: '0px'}} >{year}/{month}/{day} {hour}:{minute}</p>
      },
    },
    {
      field: 'edit',
      headerName: Language["Privileges"],
      width: 130,
      renderCell: (params) => {
          var createP = true
          var editP = false
          var deleteP = false
          for (let i = 0; i < params.row.privileges.length; i++) {
            const item = params.row.privileges[i];
            if(item.name === "create_product"){
              createP = item.userPrivilege.status
            }else if(item.name === "edit_product"){
              editP = item.userPrivilege.status
            }else if(item.name === "delete_product"){
              deleteP = item.userPrivilege.status
            }
          }
          if(params.row.superAdmin === true){
            return  <Button disabled  variant='contained' color={'success'} endIcon={<SettingsIcon/>} onClick={(e)=> { e.stopPropagation(); setOpenEditUserModal(true); setStateSelected({add: createP, edit: editP, delete: deleteP}); setSelectedUser(params.row)} }   >{Language['Edit']} </Button>
          }else{
            return  <Button  variant='contained' color={'success'} endIcon={<SettingsIcon/>} onClick={(e)=> { e.stopPropagation(); setOpenEditUserModal(true); setStateSelected({add: createP, edit: editP, delete: deleteP}); setSelectedUser(params.row)} }   >{Language['Edit']}</Button>
          }

      },
    },
    {
      field: 'Action',
      headerName: Language["Action"],
      width: 130,
      renderCell: (params) => {
        if(params.row.superAdmin === true){
          return <Button  disabled variant='contained' color={'error'} onClick={(e)=> { e.stopPropagation(); setSelectedUser(params.row); setOpenConfirmModal(true)} } endIcon={<Delete/>}  >{Language['Delete']}</Button>
        }else{
          return <Button  variant='contained' color={'error'} onClick={(e)=> { e.stopPropagation(); setSelectedUser(params.row); setOpenConfirmModal(true)} } endIcon={<Delete/>}  >{Language['Delete']}</Button>
        }       
      },
    },
  ];

  const [selectedUser, setSelectedUser] = useState({});

  const [users, setUsers] = useState([]);
  const [emailError, setEmailError] = useState('')
  const [isErrorEmail, setIsErrorEmail] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);

  function handleSelectionChange(selectionModel) {
    setSelectedRows(selectionModel.map(rowId => users.find(row => row.id === rowId)));
  }

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // get data from database
    axios.post("/users/getUsers")
    .then(function (response) {
      if(response.data.res === 'redirect'){
        navigate('/login', { replace: true });
      } else{
        setLoading(false)
        setUsers(response.data.users)
      }
      
    })
    .catch(function (error) {
      // handle error
      console.log(error);
      handleError(error)
    })
  
  }, [])

    return (
        <Body>
            
            <BodyContent>
              
                <Form >
                  <Button variant='contained' startIcon={<AddCircle/>} color='success' onClick={handleClickOpenAddUserModal}>{Language['Create user']}</Button>
                  <div style={{ height: 'calc(100vh - 255px)', width: '100%', marginTop: '10px' }}>
                    <DataGrid
                      rows={users}
                      columns={columns}
                      
                      //checkboxSelection
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 10 },
                        },
                      }}
                      pageSizeOptions={[5, 10]}
                      //onRowClick={handleRowClick}
                      sortModel={[{field: 'createdAt',sort: 'desc'}]}
                      disableSelectionOnClick
                      //onSelectionModelChange={handleSelectionChange}
                    />
                  </div>
                </Form>
            </BodyContent>


            {/* add user modal  */}

            <Dialog open={openAddUserModal} onClose={handleCloseAddUserModal}>
              <DialogTitle>{Language['Create user']}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                {Language['After clicking create button, the user will receive an email with a personalized signup link.']}
                
                </DialogContentText>
                <TextField
                  error={isErrorEmail}
                  helperText={emailError}
                  autoFocus
                  margin="dense"
                  id="name"
                  label={Language['Email Address']}
                  type="email"
                  fullWidth
                  variant="standard"
                  value={userEmail}
                  onChange={(e)=> {setUserEmail(e.target.value); setEmailError(''); setIsErrorEmail(false)}}
                />

                <FormControl component="fieldset" variant="standard" style={{marginTop: '30px'}}>
                      <FormLabel component="legend">{Language['User privilege']}</FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch checked={true} disabled name="add" />
                          }
                          label={Language['Add products']}
                        />
                        <FormControlLabel
                          control={
                            <Switch checked={state.edit} onChange={handleChange} name="edit" />
                          }
                          label={Language['Edit products']}
                        />
                        <FormControlLabel
                          control={
                            <Switch checked={state.delete} onChange={handleChange} name="delete" />
                          }
                          label={Language['Delete products']}
                        />
                      </FormGroup>
                      <FormHelperText>{Language['Be careful']}</FormHelperText>
                    </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAddUserModal}>{Language['Cancel']}</Button>
                <LoadingButton loading={createUserLoading} loadingPosition='center' onClick={() => createUser()}>{Language['Create']}</LoadingButton>
              </DialogActions>
            </Dialog>



              {/* Edit user modal  */}

              <Dialog open={openEditUserModal} onClose={handleCloseEditUserModal} fullWidth={true}
               maxWidth={'sm'}>
              <DialogTitle>{Language['Edit user']}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                {Language['After clicking save, privileges for this user will be changed.']}
                </DialogContentText>
                <FormControl component="fieldset" variant="standard" style={{marginTop: '30px'}}>
                      <FormLabel component="legend">{Language['User privilege']}</FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch checked={stateSelected.add} disabled name="add" />
                          }
                          label={Language['Add products']}
                        />
                        <FormControlLabel
                          control={
                            <Switch checked={stateSelected.edit} onChange={handleChangeState} name="edit" />
                          }
                          label={Language['Edit products']}
                        />
                        <FormControlLabel
                          control={
                            <Switch checked={stateSelected.delete} onChange={handleChangeState} name="delete" />
                          }
                          label={Language['Delete products']}
                        />
                      </FormGroup>
                      <FormHelperText>{Language['Be careful']}</FormHelperText>
                    </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseEditUserModal}>{Language['Cancel']}</Button>
                <LoadingButton loading={editUserLoading} loadingPosition='center' onClick={() => editUser()}>{Language['Save']}</LoadingButton>
              </DialogActions>
            </Dialog>



            {/* confirm deletion modal */}
            <Dialog
              open={openConfirmModal}
              onClose={handleCloseConfirmModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {Language['Delete user']}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {Language['Please be aware that this action is irreversible and you are about to permanently delete']}
                <span style={{backgroundColor: '#9c9595d8', color: "white"}}>{selectedUser.email}</span>.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button color='error' onClick={handleCloseConfirmModal}>{Language['Cancel']}</Button>
                <LoadingButton loading={deleteUserLoading} loadingPosition='center' variant='contained' color='error' onClick={deleteUser} autoFocus>
                {Language['Delete']}
                </LoadingButton>
              </DialogActions>
            </Dialog>
    
    
            {/* // snackbar */}
            <Snackbar
              open={openAlert}
              autoHideDuration={4000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                {alertMessage}
              </Alert>
            </Snackbar> 
        </Body>
      )
}