const english = {
        'Welcome Hengxing Al Sinaiya Star ERP': 'Welcome Hengxing Al Sinaiya Star ERP',
        'The best factory management platform for you.': 'The best factory management platform for you.',
        'Inventory management':'Inventory management',
        'Costumer management': 'Costumer management',
        'Invoice management': 'Invoice management',
        'TOTAL INVENTORY': 'TOTAL INVENTORY',
        'Total products': 'Total products',
        'RECEIVED': 'RECEIVED',
        'Pieces': 'Pieces',
        'Delivered': 'Delivered',
        'Total delivered product': 'Total delivered product',
        'Returned': 'Returned',
        'Total returned products': 'Total returned products',
        'Home': 'Home',
        'Products': 'Products',
        'New Product': 'New Product',
        'received': 'received',
        'delivered': 'delivered',
        'returned': 'returned',
        'Operation': 'Operation',
        'operations': 'operations',
        'New Operation': 'New Operation',
        'Print': 'Print',
        'Export': 'Export',
        'Import': 'Import',
        'Id': 'Id',
        'Admin': 'Admin',
        'Reference': 'Reference',
        'Date': 'Date',
        'Costumer': 'Costumer',
        'Type': 'Type',
        'draft': 'draft',
        'complited': 'complited',
        'Action': 'Action',
        'Details': 'Details',
        'Facturation': 'Facturation',
        'Billing': 'Billing',
        'Draft': 'Draft',
        'Quantity': 'Quantity',
        'Status': 'Status',
        'Valid': 'Valid',
        'Product details': 'Product details',
        'upload image': 'upload image',
        'General': 'General',
        'Name': 'Name',
        'Component': 'Component',
        'Product': 'Product',
        'Price': 'Price',
        'Delete': 'Delete',
        'Save': 'Save',
        'Components': 'Components',
        'Add': 'Add',
        'pieces': 'pieces',
        'Received': 'Received',
        'unlimited': 'unlimited',
        'Total inventory': 'Total inventory',
        'units': 'units',
        'History': 'History',
        'Select Date': 'Select Date',
        'Filter': 'Filter',
        'Delete product': 'Delete product',
        'Are you sure you want to permanently delete this product?': 'Are you sure you want to permanently delete this product?',
        'Cancel': 'Cancel',
        'Stock': 'Stock',
        'Operation details': 'Operation details',
        'Operation type': 'Operation type',
        'Operation reference': 'Operation reference',
        'Client': 'Client',
        'Local operation': 'Local operation',
        'Full name': 'Full name',
        'Address': 'Address',
        'Created by': 'Created by',
        'Add operation': 'Add operation',
        'It will be generated after clicking save..': 'It will be generated after clicking save..',
        'User': 'User',
        'Maximum units': 'Maximum units',
        'URL Not Found!': 'URL Not Found!',
        'The requested url not found, it seems like it changed or deleted.': 'The requested url not found, it seems like it changed or deleted.',
        'Apps': 'Apps',
        'Profile': 'Profile',
        'Logout': 'Logout',
        'My account': 'My account',
        'Tax identification details': 'Tax identification details',
        'Email': 'Email',
        'Company': 'Company',
        'Bills': 'Bills',
        'First collaboration': 'First collaboration',
        'costumers': 'costumers',
        'Costumer operations': 'Costumer operations',
        'Close': 'Close',
        'paid': 'paid',
        'unpaid': 'unpaid',
        'canceled': 'canceled',
        'Total': 'Total',
        'Show': 'Show',
        'All': 'All',
        'Paid': 'Paid',
        'Unpaid': 'Unpaid',
        'Canceled': 'Canceled',
        'Save & Print': 'Save & Print',
        'Choose products': 'Choose products',
        'Imported products': 'Imported products',
        'Update existing products': 'Update existing products',
        'Products details': 'Products details',
        'Related To': 'Related To',
        'Message': 'Message',
        'Already exist': 'Already exist',
        'Related to unfound product': 'Related to unfound product',
        'Operations details': 'Operations details',
        'an error has occured.': 'an error has occured.',
        'internal server error.': 'internal server error.',
        'too many requests.': 'too many requests.',
        'No row selected.': 'No row selected.',
        'You should select a product.': 'You should select a product.',
        'Changed successfully': 'Changed successfully',
        'Auto-save not allowed.': 'Auto-save not allowed.',
        'Not allowed.': 'Not allowed.',
        'Deleted successfully.': 'Deleted successfully.',
        'Maximum units reached.': 'Maximum units reached.',
        'The image size is too large.': 'The image size is too large.',
        'Image only.': 'Image only.',
        'An error has occured while uploading the image.': 'An error has occured while uploading the image.',
        'Not found.': 'Not found.',
        'you should select a product.': 'you should select a product.',
        'Product not found.': 'Product not found.',
        'Already added!': 'Already added!',
        'Operation not found.': 'Operation not found.',
        'CSV file only.': 'CSV file only.',
        'Type value is not valid.': 'Type value is not valid.',
        'Type is required.': 'Type is required.',
        'Invalid status.': 'Invalid status.',
        'InvoiceId is required.': 'InvoiceId is required.',
        'Saved successfully.': 'Saved successfuly.',
        'save': 'save',
        'Disconnect': 'Disconnect',
        'Personal information': 'Personal information',
        'Security': 'Security',
        'First name': 'First name',
        'Family name': 'Family name',
        'Save your information': 'Save your information',
        'Actuel password': 'Actuel password',
        'New password': 'New password',
        'Confirm new password': 'Confirm new password',
        'Save password': 'Save password',
        'Bad Request: The server cannot process the request due to an invalid syntax.': 'Bad Request: The server cannot process the request due to an invalid syntax.',
        'Unauthorized: Authentication is required, and the user has not provided valid credentials.': 'Unauthorized: Authentication is required, and the user has not provided valid credentials.',
        'Forbidden: The server understood the request but refuses to authorize it.': 'Forbidden: The server understood the request but refuses to authorize it.',
        'Not Found: The server cannot find the requested resource.': 'Not Found: The server cannot find the requested resource.',
        'Method Not Allowed: The request method is not supported for the requested resource.': 'Method Not Allowed: The request method is not supported for the requested resource.',
        'Too Many Requests: The user has sent too many requests in a given amount of time.': 'Too Many Requests: The user has sent too many requests in a given amount of time.',
        'Internal Server Error: The server has encountered a situation it does not know how to handle.': 'Internal Server Error: The server has encountered a situation it does not know how to handle.',
        'Service Unavailable: The server is currently unable to handle the request.': 'Service Unavailable: The server is currently unable to handle the request.',
        'Error: There was an error processing your request.': 'Error: There was an error processing your request.',
        'An error has occured.': 'An error has occured.',
        'Logout successfully.': 'Logout successfully.',
        'string only': 'string only',
        'name is required.': 'name is required.',
        'enter a valid name': 'enter a valid name',
        'family name is required.': 'family name is required.',
        'enter a valid family name': 'enter a valid family name',
        'your information saved succesfully.': 'your information saved.',
        'password is required.': 'password is required.',
        'enter a valid password': 'enter a valid password',
        'new password is required.': 'new password is required.',
        'password confirmation is required.': 'password confirmation is required.',
        'Passwords do not match': 'Passwords do not match',
        'incorrect password': 'incorrect password',
        '': '',
        'Privileges': 'Privileges',
        'Edit': 'Edit',
        'Create user': 'Create user',
        'After clicking create button, the user will receive an email with a personalized signup link.': 'After clicking create button, the user will receive an email with a personalized signup link.',
        'Email Address': 'Email Address',
        'User privilege': 'User privilege',
        'Add products': 'Add products',
        'Edit products': 'Edit products',
        'Delete products': 'Delete products',
        'Create': 'Create',
        'Edit user': 'Edit user',
        'After clicking save, privileges for this user will be changed.': 'After clicking save, privileges for this user will be changed.',
        'Be careful': 'Be careful',
        'Delete user': 'Delete user',
        'Please be aware that this action is irreversible and you are about to permanently delete': 'Please be aware that this action is irreversible and you are about to permanently delete',
        'Users management': 'Users management',
        'Email is not valid.': 'Email is not valid.',
        'Email already exists.': 'Email already exists.',
        'home': 'Home',
        'products': 'Products',
        'Active': 'Active',
        'Invited': 'Invited',
        '': '',
        'Total delivered products': 'Total delivered products',
        'DELIVERED': 'DELIVERED',
        'RETURNED': 'RETURNED',
        'Changes saved': 'Changes saved',
        'Product is undefined, refresh your page and try again.': 'Product is undefined, refresh your page and try again.',
        'You should select a component first.': 'You should select a component first.',
        'Reset': 'Reset',
        'Change the quantity': 'Change the quantity',
        'The super admin is the only user who can change the quantity.': 'The super admin is the only user who can change the quantity.',
        'modified': 'modified',
        'Item not found.': 'Item not found.',
        'The quantity value should be a non-negative number.': 'The quantity value should be a non-negative number.',
        'Can not perform this operation.': 'Can not perform this operation.',
        'component': 'component',
        'product': 'product',
        'Create a new component': 'Create a new component',

}

export default english;

    