import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SwipeDownAltIcon from '@mui/icons-material/SwipeDownAlt';
import SwipeUpAltIcon from '@mui/icons-material/SwipeUpAlt';
import SwipeLeftAltIcon from '@mui/icons-material/SwipeLeftAlt';
import CategoryIcon from '@mui/icons-material/Category';
import { useNavigate } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Body = styled.div`
    width: 100%;
`

const Header = styled.div`
    width: 96%;
    padding: 10px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px #e1e1e1 solid;
    @media only screen and (max-width: 850px) {
        flex-direction: column !important;
    }
`
const HeaderTitle = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: center;
    @media only screen and (max-width: 850px) {
        width: 100% !important;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: end;

    }
`

const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    @media only screen and (max-width: 850px) {
      width: 100%;
    }
`
const BodyContent = styled.div`
    width: 100%;
    background-color: #f6f7fa;
    //min-height: 320px;
    min-height: calc(100vh - 115px);
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: start;
`
const Form = styled.div`
    width: calc(90% - 40px) ;
    margin: 25px 5%;
    background-color: white;
    min-height: 80%;
    height: max-content;
    min-height: 300px;
    box-shadow: 0px 0px 1px 0px grey;
    padding: 10px 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: start;
    flex-wrap: wrap;
`

const Element = styled.div`
  width: 18%;
  min-width: 250px;
  height: 120px;
  background-color: #f5f5f5b3;
  padding: 5px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 25px;
  box-shadow: 0px 0px 1px 0px #0000007e;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover{
    box-shadow: 0px 0px 3px 0px #0000006b;
  }
`

const ElementTitle = styled.p`
  padding: 0;
  margin: 0;
  color: #8895d9;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  text-align: start;
  align-self: start;
`

const ElementNumber = styled.p`
  padding: 0;
  margin: 0;
  width: calc(100% - 5px);
  text-align: center;
  color: #000000c2;
  font-size: 35px;
  padding: 5px 5px 0px 5px;
  font-weight: 600;
`

const ElementDesc = styled.p`
  padding: 0;
  margin: 0;
  width: calc(100% - 5px);
  text-align: center;
  color: #00000090;
  font-size: medium;
  padding: 0px 5px;
  margin-bottom: 10px;
`


const ElementDouble = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ElementDoubleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export default function HomeInventory({Language, handleError, handleHeader}) {


    // mui config

    
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenAlert(false);
    };


    // end mui config

    const navigate = useNavigate()
    const [ alertMessage, setAlertMessage] = useState('')
    const [ alertType, setAlertType] = useState('')
    const [ openAlert, setOpenAlert] = useState(false)
  
    const [productsCount, setProductsCount] = useState(0);
    const [receivedCount, setReceivedCount] = useState(0);
    const [deliveredCount, setDeliveredCount] = useState(0);
    const [returnedCount, setReturnedCount] = useState(0);
    
    useEffect(() => {
      // get data from database
      axios.post("/inventory_management/app")
      .then(function (response) {
          console.log(response.data)
          setProductsCount(response.data.productsCount)
          setReceivedCount(response.data.receivedCount)
          setDeliveredCount(response.data.deliveredCount)
          setReturnedCount(response.data.returnedCount)
      })
      .catch(function (error) {
        // handle error
        handleError(error)
      })
    
    }, [])

    useEffect(() => {
      handleHeader('Home')
    }, []);

  return (
    <Body>
        <Header>
            <HeaderTitle>
                <H1>{Language['Inventory management']}</H1>
            </HeaderTitle>
        </Header>
        <BodyContent>
          
            <Form style={{paddingTop :'30px'}}>


              <Element onClick={()=> navigate('/app/Inventory_management/products')}>
                <ElementTitle><CategoryIcon></CategoryIcon>{Language['TOTAL INVENTORY']}</ElementTitle>
                <ElementNumber>{productsCount}</ElementNumber>
                <ElementDesc>{Language['Total products']}</ElementDesc>
              </Element>

              <Element onClick={()=> navigate('/app/Inventory_management/history?type=received')}>
                <ElementTitle style={{color: '#a29f61'}}><SwipeDownAltIcon></SwipeDownAltIcon>{Language['RECEIVED']}</ElementTitle>
                <ElementNumber>{receivedCount}</ElementNumber>
                <ElementDesc>{Language['Total returned products']}</ElementDesc>
              </Element>

              <Element onClick={()=> navigate('/app/Inventory_management/history?type=delivered')}>
                <ElementTitle style={{color: '#86c085'}}><SwipeUpAltIcon></SwipeUpAltIcon>{Language['DELIVERED']}</ElementTitle>
                <ElementNumber>{deliveredCount}</ElementNumber>
                <ElementDesc>{Language['Total delivered products']}</ElementDesc>
              </Element>

              <Element onClick={()=> navigate('/app/Inventory_management/history?type=returned')}>
                <ElementTitle style={{color: '#e88989'}}><SwipeLeftAltIcon></SwipeLeftAltIcon>{Language['RETURNED']} </ElementTitle>
                <ElementNumber>{returnedCount}</ElementNumber>
                <ElementDesc>{Language['Total returned products']}</ElementDesc>
              </Element>

            </Form>

        </BodyContent>



                <Snackbar
                  open={openAlert}
                  autoHideDuration={4000}
                  onClose={handleClose}
                >
                  <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                    {alertMessage}
                  </Alert>
                </Snackbar>
    </Body>
  )
}

