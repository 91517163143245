import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Page, Text, View, Document, StyleSheet, pdf, Image, Input } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { print } from 'pdfjs-dist';
import PdfToTextConverter from './PDFToTextConverter';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../Loading';
import NotFound from '../notFound';

// Create styles


const MyPDFViewer = styled(PDFViewer)`
    width: 100%;
    height: 90vh;
`


const Home = styled.div`

`
const MyPdf = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
`
const Header = styled.div`
    width: 100%;
    margin-top: 50px;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
`

const HeaderImage = styled(Image)`
    width: 50px !important;
    height: 50px !important;
    background-color: red;
`
const HeaderTitle = styled(Text)`
    font-weight: 600;
    font-size: 30px;
    color: red;
`

const Table = styled(View)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
`

const TableHeader = styled(View)`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const TableTwoCases = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`



export default function PdfPrinter({handleError, Language}) {



    const [isLoading, setIsLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [pageTitle, setPageTitle] = useState('');


  const navigate = useNavigate()

  const [invoice, setInvoice] = useState({});
  const [costumer, setCostumer] = useState({});
 
 
  function getHistory (){
    axios
      .post("/inventory_management/operation_details", { operationId: id})
        .then(function (response) {
            console.log(response.data)
            var total = 0
            setIsLoading(false)
            if(response.data.operation.operationCostumer){
                setCostumer(response.data.operation.operationCostumer)
            }
            setInvoice(response.data.operation)
            for (let i = 0; i < response.data.operation.products.length; i++) {
                const product = response.data.operation.products[i];
                    total += (parseInt(product.operationProduct.quantity) * parseInt(product.operationProduct.price))
            }
            setTotal((total).toFixed(2))
            setTotalTVA((total + total * 0.2).toFixed(2))
      })
      .catch(function (error) {
        if(error.response.status === 404){
            setNotFound(true)
            setIsLoading(false)
          }
        // handle error
        handleError(error)
      })
  }


    useEffect(() => {
        if(id && id.length > 0){
            setPageTitle(id)
            getHistory(id)
        }else {
          navigate('/app/Invoice_management')
         
        }
      }, []);
      
      
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1; // Month is zero-based, so add 1
        const year = currentDate.getFullYear();

        var date = day + '/' + month + '/' + year

      const [total, setTotal] = useState(0);
      const [totalTVA, setTotalTVA] = useState(0);

      function numberToLetters(number) {
        const units = ['', 'un', 'deux', 'trois', 'quatre', 'cinq', 'six', 'sept', 'huit', 'neuf', 'dix', 'onze', 'douze', 'treize', 'quatorze', 'quinze', 'seize'];
        const tens = ['', 'dix ', 'vingt ', 'trente ', 'quarante ', 'cinquante ', 'soixante ', 'soixante-', 'quatre-vingt ', 'quatre-vingt-'];
        const scale = ['', 'mille', 'million'];
      
        if (number === 0) {
          return 'zéro';
        }
      
        if (number < 0) {
          return 'moins ' + numberToLetters(-number);
        }
      
        if (number < 17) {
          return units[number];
        }
      
        if (number < 20) {
          return 'dix-' + numberToLetters(number - 10);
        }
      
        if (number < 100) {
          const unit = number % 10;
          const ten = Math.floor(number / 10);
      
          if (unit === 0) {
            return tens[ten];
          }
      
          if (ten === 7 || ten === 9) {
            return tens[ten] + '-' + units[10 + unit];
          }
      
          return tens[ten] + units[unit];
        }
      
        if (number < 1000) {
          const hundred = Math.floor(number / 100);
          const remainder = number % 100;
      
          if (remainder === 0) {
            return units[hundred] + ' cent';
          }
      
          if (hundred === 1) {
            return 'cent ' + numberToLetters(remainder);
          }
      
          return units[hundred] + ' cent ' + numberToLetters(remainder);
        }
      
        for (let i = 1; i < scale.length; i++) {
          if (number < Math.pow(1000, i + 1)) {
            const base = Math.pow(1000, i);
            const current = Math.floor(number / base);
            const remainder = number % base;
      
            if (remainder === 0) {
              return numberToLetters(current) + ' ' + scale[i];
            }
      
            if (remainder < 100) {
              return numberToLetters(current) + ' ' + scale[i] + ' ' + numberToLetters(remainder);
            }
      
            return numberToLetters(current) + ' ' + scale[i] + ' ' + numberToLetters(remainder);
          }
        }
      
        return 'number out of range';
      }
      
      
      if(isLoading === true){
        return(
            <Home>
                <Loading></Loading>
            </Home>
        )
      }else if(notFound === true){
        return(
            <Home>
                <NotFound />
            </Home>
        )
      }else {
        return (
            <Home>
                <MyPDFViewer>
                    <Document>
                        <Page size="A4" >
                            <MyPdf style={{width: '90%', margin: '10px 5%'}}>
                                <View style={{display: 'flex', paddingBottom: '5px',  flexDirection: 'row', justifyContent: 'space-between', width: '100%', borderBottom: '0px black solid', alignItems: 'center'}}>
                                    <HeaderImage src='./../../logo.png' style={{ height: '40px',  alignItems:'center',}} />
                                    <View style={{display: 'flex', flexDirection: 'column',  width: 'calc(100% - 20px)', padding: '0px 10px'}}>
                                        <HeaderTitle style={{padding: '10px 10px 5px 10px', fontSize: '18px', fontWeight: '800', width: '100%', textAlign: 'center'}}> SARL HENGXING AL SINAIYA STAR  </HeaderTitle>
                                        <HeaderTitle style={{padding: '5px 10px', fontSize: '9px', fontWeight: '400', width: '100%', textAlign: 'center', color: "black"}}>SOCIETE DE PRODUCTION DES BRIQUETS DE TRANSFORAMTION DE PLASTIQUE</HeaderTitle>
                                        <HeaderTitle style={{padding: '0px 10px', fontSize: '9px', fontWeight: '400', width: '100%', textAlign: 'center', color: "black"}}>Sarl au Capital Social de: 19 000 0000.00 </HeaderTitle>
                                        
                                    </View>
                                </View>
        
                                <View>
                                    <HeaderTitle style={{padding: '5px 0px', fontSize: '10px', fontWeight: '400', width: '100%', textAlign: 'center', color: "black"}}> R.C. N° : 35/00-0466237B20  NIF N° : 002034046623715  N.AI  N° : 35204380431  NIS N°: 002034010004347  </HeaderTitle>
                                </View>
        
                                <View style={{border: '1px solid black', paddingTop: '20px'}}>
                                    <HeaderTitle style={{padding: '5px 5px', fontSize: '10px', fontWeight: '600', width: '100%', textAlign: 'right', color: "black"}}>Ouled Moussa, Le {date}</HeaderTitle>
                                </View>
        
        
                          
                                <View>
                                    <HeaderTitle style={{padding: '5px 0px', fontSize: '15px', fontWeight: '600', width: '100%', textAlign: 'left', color: "black", borderBottom: '1px solid whitesmoke'}}>Client :</HeaderTitle>
                                    <HeaderTitle style={{padding: '2px 0px', fontSize: '12px', fontWeight: '400', width: '100%', textAlign: 'left', color: "grey"}}><Text style={{color: 'black', fontWeight: "600"}}>Mr:</Text>      {costumer.name}</HeaderTitle>
                                    <HeaderTitle style={{padding: '2px 0px', fontSize: '12px', fontWeight: '400', width: '100%', textAlign: 'left', color: "grey"}}><Text style={{color: 'black', fontWeight: "600"}}>Add:</Text>     {costumer.address}</HeaderTitle>
                                    <HeaderTitle style={{padding: '2px 0px', fontSize: '12px', fontWeight: '400', width: '100%', textAlign: 'left', color: "grey"}}><Text style={{color: 'black', fontWeight: "600"}}>R.C N°:</Text>     {costumer.rc}</HeaderTitle>
                                    <HeaderTitle style={{padding: '2px 0px', fontSize: '12px', fontWeight: '400', width: '100%', textAlign: 'left', color: "grey"}}><Text style={{color: 'black', fontWeight: "600"}}>N.I.S N°:</Text>     {costumer.nis}</HeaderTitle>
                                    <HeaderTitle style={{padding: '2px 0px', fontSize: '12px', fontWeight: '400', width: '100%', textAlign: 'left', color: "grey"}}><Text style={{color: 'black', fontWeight: "600"}}>N.I.F N°:</Text>     {costumer.nif}</HeaderTitle>
                                    <HeaderTitle style={{padding: '2px 0px', fontSize: '12px', fontWeight: '400', width: '100%', textAlign: 'left', color: "grey"}}><Text style={{color: 'black', fontWeight: "600"}}>A.I N°:</Text>     {costumer.ai}</HeaderTitle>
                                </View>
        
                                <View>
                                    <HeaderTitle style={{padding: '5px 0px', fontSize: '17px', fontWeight: '600', width: '100%', textAlign: 'center', color: "black"}}> Facture N° {invoice.reference}  </HeaderTitle>
                                </View>
        
        
        
                                <View style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                    
                                   
                                    <TableHeader style={{width: '100%', height: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <View style={{ width: '10%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}>N°</Text>
                                        </View>
                                        <View style={{ width: '30%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}>Désignation</Text>
                                        </View>
                                        <View style={{ width: '20%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}>Code</Text>
                                        </View>
                                        <View style={{ width: '20%', display: 'flex', flexDirection: 'column', justifyContent: 'start', border: '1px solid gray'}}>
                                            <View style={{  height: '50%', borderBottom: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                <Text style={{ fontSize: '15px'}}>Quantite</Text>
                                            </View>
                                            <View style={{  height: '50%', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                <Text style={{ fontSize: '15px'}}></Text>
                                            </View>
                                        </View>
        
                                        <View style={{ width: '20%', display: 'flex', flexDirection: 'column', justifyContent: 'start', border: '1px solid gray'}}>
                                            <View style={{  height: '50%', borderBottom: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '0px'}}>
                                                <Text style={{ fontSize: '15px'}}>Prix U</Text>
                                            </View>
                                            <View style={{  height: '50%', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                <Text style={{ fontSize: '15px'}}>H.T</Text>
                                            </View>
                                        </View>
        
                                        <View style={{ width: '20%', display: 'flex', flexDirection: 'column', justifyContent: 'start', border: '1px solid gray'}}>
                                            <View style={{  height: '50%', borderBottom: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                <Text style={{ fontSize: '15px'}}>Montant</Text>
                                            </View>
                                            <View style={{  height: '50%', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                <Text style={{ fontSize: '15px'}}>H.T</Text>
                                            </View>
                                        </View>
                                        
                                    </TableHeader>
        

                                    {
                                        invoice.products.map((item, index) => (
                                            <TableHeader style={{width: '100%', height: '25px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '-1px'}}>
                                                <View style={{ width: '10%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                    <Text style={{ fontSize: '13px', fontWeight:"400"}}>{index} </Text>
                                                </View>
                                                <View style={{ width: '30%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                    <Text style={{ fontSize: '13px', fontWeight:"400"}}>{item.name} </Text>
                                                </View>
                                                <View style={{ width: '20%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                    <Text style={{ fontSize: '13px', fontWeight:"400"}}>{item.reference} </Text>
                                                </View>
                
                                                <View style={{ width: '20%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                    <Text style={{ fontSize: '13px', fontWeight:"400"}}>{item.operationProduct.quantity}</Text>
                                                </View>
                
                                                <View style={{ width: '20%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                    <Text style={{ fontSize: '13px', fontWeight:"400"}}>{parseInt(item.operationProduct.price).toFixed(2)}</Text>
                                                </View>
                
                                                <View style={{ width: '20%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                    <Text style={{ fontSize: '13px', fontWeight:"400"}}>{(parseInt(item.operationProduct.quantity)*parseInt(item.operationProduct.price)).toFixed(2)}</Text>
                                                </View>
                                                
                                                
                                            </TableHeader>  
                                        ))
                                    }
        
                                    
        
        
                                    <TableHeader style={{width: '100%', height: '25px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '-1px'}}>
                                        <View style={{ width: '10%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}></Text>
                                        </View>
                                        <View style={{ width: '30%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}></Text>
                                        </View>
                                        <View style={{ width: '20%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}></Text>
                                        </View>
        
                                        <View style={{ width: '20%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}></Text>
                                        </View>
        
                                        <View style={{ width: '20%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}></Text>
                                        </View>
        
                                        <View style={{ width: '20%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}></Text>
                                        </View>
                                        
                                        
                                    </TableHeader>
        
                                    <TableHeader style={{width: '100%', height: '25px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '-1px'}}>
                                        <View style={{ width: '10%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}></Text>
                                        </View>
                                        <View style={{ width: '30%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}></Text>
                                        </View>
                                        <View style={{ width: '20%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}></Text>
                                        </View>
        
                                        <View style={{ width: '20%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}></Text>
                                        </View>
        
                                        <View style={{ width: '20%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}></Text>
                                        </View>
        
                                        <View style={{ width: '20%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}></Text>
                                        </View>
                                        
                                        
                                    </TableHeader>
        
        
                                    <TableHeader style={{width: '100%', height: '25px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '-1px'}}>
                                        
                                        <View style={{ width: '60%', height: '100%', border: '0px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}></Text>
                                        </View>
                                       
                                        <View style={{ width: '20%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}>Total H.T</Text>
                                        </View>
        
                                        <View style={{ width: '40%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}>{total}</Text>
                                        </View>
        
                                        
                                    </TableHeader>
        
                                    <TableHeader style={{width: '100%', height: '25px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '-1px'}}>
                                        
                                        <View style={{ width: '60%', height: '100%', border: '0px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}></Text>
                                        </View>
                                       
                                        <View style={{ width: '20%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}>T.V.A 19%</Text>
                                        </View>
        
                                        <View style={{ width: '40%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}>{parseFloat(total * 0.19 ).toFixed(2)}</Text>
                                        </View>
        
                                        
                                    </TableHeader>

                                    <TableHeader style={{width: '100%', height: '25px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '-1px'}}>
                                        
                                        <View style={{ width: '60%', height: '100%', border: '0px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}></Text>
                                        </View>
                                       
                                        <View style={{ width: '20%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}>Timbre 1%</Text>
                                        </View>
        
                                        <View style={{ width: '40%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}>{parseFloat(total * 0.01 ).toFixed(2)}</Text>
                                        </View>
        
                                        
                                    </TableHeader>
        
                                    <TableHeader style={{width: '100%', height: '25px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '-1px'}}>
                                        
                                        <View style={{ width: '60%', height: '100%', border: '0px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}></Text>
                                        </View>
                                       
                                        <View style={{ width: '20%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}>Total T.T.C</Text>
                                        </View>
        
                                        <View style={{ width: '40%', height: '100%', border: '1px solid gray', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Text style={{ fontSize: '15px'}}>{totalTVA} </Text>
                                        </View>
        
                                        
                                    </TableHeader>
                                    
                                </View>
        
        
                                <View>
                                    <HeaderTitle style={{padding: '20px 0px 0px 0px', fontSize: '10px', fontWeight: '400', width: '100%', textAlign: 'start', color: "black"}}> ARRETEE LA PRESENTE FACTURE A LA SOMME DE : {numberToLetters(totalTVA)} </HeaderTitle>
                                </View>
        
        
                                <View>
                                    <HeaderTitle style={{padding: '20px 0px 5px 0px', fontSize: '15px', fontWeight: '600', width: '100%', textAlign: 'left',borderBottom: '1px solid whitesmoke', color: "black"}}>Details bancaires:</HeaderTitle>
                                    <HeaderTitle style={{padding: '2px 0px', fontSize: '12px', fontWeight: '600', width: '100%', textAlign: 'left', color: "grey"}}><Text style={{color: 'black'}}>Banque:</Text>      The housing bank of trade and finance (Rouiba)</HeaderTitle>
                                    <HeaderTitle style={{padding: '2px 0px', fontSize: '12px', fontWeight: '600', width: '100%', textAlign: 'left', color: "grey"}}><Text style={{color: 'black'}}>Code agence:</Text>     00108</HeaderTitle>
                                    <HeaderTitle style={{padding: '2px 0px', fontSize: '12px', fontWeight: '600', width: '100%', textAlign: 'left', color: "grey"}}><Text style={{color: 'black'}}>Numéro de compte:</Text>     9120000189 / 79</HeaderTitle>
                                    <HeaderTitle style={{padding: '2px 0px', fontSize: '12px', fontWeight: '600', width: '100%', textAlign: 'left', color: "grey"}}><Text style={{color: 'black'}}>IBAN:</Text>     031 00108 9120000189 79</HeaderTitle>
                                </View>

                                <View style={{border: "1px solid black", padding: '0px 2px', marginTop: '10px'}}>
                                    <View>
                                        <HeaderTitle style={{padding: '5px 0px 0px 0px', fontSize: '10px', fontWeight: '400', width: '100%', textAlign: 'center', color: "black"}}>  Add : Cité Ouled Hamada, Section 05, G.P 752, Ouled Moussa - Boumerdes 35011 </HeaderTitle>
                                    </View>
                                    <View>
                                        <HeaderTitle style={{padding: '5px 0px', fontSize: '10px', fontWeight: '400', width: '100%', textAlign: 'center', color: "black"}}> E-mail:  hengxing.star2021@gmail.com     </HeaderTitle>
                                    </View>
                                </View>
        
                                
                           
        
                            </MyPdf>
                        {/* <div style={{display: 'flex', flexDirection: 'column'}} >
                            <View style={styles.section}>
                                <Text>Section #1</Text>
                            </View>
                            <View style={styles.section}>
                                <Text>Section #2</Text>
                            </View>
        
                        </div> */}
                        </Page>
                    </Document>
                </MyPDFViewer>
        
               
        
            </Home>
          )
      }


 
}
