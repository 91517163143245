import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import Button from '@mui/material/Button';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import Menu from '@mui/material/Menu';
import ImageIcon from '@mui/icons-material/Image';
import NotesIcon from '@mui/icons-material/Notes';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import PowerInputIcon from '@mui/icons-material/PowerInput';
import CodeIcon from '@mui/icons-material/Code';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';
import Crop32Icon from '@mui/icons-material/Crop32';
import TextField from '@mui/material/TextField';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import { Upload } from 'antd';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AlertComp from './alert';
import CloseIcon from '@mui/icons-material/Close';



const Home = styled.div`
    width: 100%;
    height: 100%;
    //min-height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: row-reverse;
    //align-items: center;
    position: relative;
`

const BuildArea = styled.div`
    position: relative;
    width: 72%;
    background-color: #f5f5f5cf;
    height: 100%;
    max-height: 80vh;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    min-height: 80vh;
    padding: 15px 2% 15px 2%;
    margin: 0px 2%;
    margin-top: 20px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    @media (max-width: 768px) {
        width: 100%;
    }
`


const EditArea = styled.div`
    width: 18%;
    margin-top: 0px;
    background-color: #050521cf;
    height:calc(100vh - 100px);
    padding: 10px 1%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    //position: relative;
    min-width: 220px;
    @media (max-width: 768px) {
        display: ${(props) => (props.open ? 'flex' : 'none')};
        width: 98%;
        position: absolute !important;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 5000;
        height: calc(100% - 20px);
        background-color: #09093d;
    }
`

const MyButton = styled(Button)`
    display: none !important;
    color: white !important;
    align-self: end;
    margin: 0px 10px;
    @media (max-width: 768px) {
       display: block !important;
    }
`


const DesingingArea = styled.div`
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    overflow-y: scroll;
`

const EditElements = styled.div`
    /* height: 100%; */
    width: 96%;
    margin: 0%;
    padding: 2%;
    height: calc(100vh - 150px);
    /* height: 50px; */
    overflow-y: scroll;
    overflow-x: hidden;
`


const BtnContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 10px;
    bottom: 10px;
    right: 10px;
`


const EditAreaType = styled.div`
    width: calc(100% - 40px);
    padding: 10px;
    margin: 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`
const EditTitle = styled.h1`
    margin: 0px;
    padding: 0px;
    color: white;
    text-align: left;
    font-size: small;
    width: 100%;
    font-weight: 400;
`

const HtmlTags = styled.div`
    width: 40% !important;
    min-width: 300px;
    max-width: 300px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
`
const Alignement = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const HtmlTag = styled.div`
    width: 40% !important;
    //min-width: 200px;
    padding: 4%;
    margin: 1% 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: medium;
    background-color: whitesmoke;
    border-radius: 3px;
    cursor: pointer;
`


export default function Editor({sendMails, pre_programmeMessage, disableDesignScheduleBtn, disableCodeScheduleBtn, disableCodeSendBtn, disableDesignSendBtn, DraggedElements, setDraggedElements, setMycode, mycode}) {

    const [openDialog, setOpenDialog] = React.useState(false);
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


   
    const [addIndex, setAddIndex] = useState('end');

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event, index) => {
        console.log('my index to add', index)
        setAddIndex(index)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    // const [DraggedElements, setDraggedElements] = useState([{id: '1', type: 'title', content: 'hello world', color: '#072929', backgroundColor: '#ffffff', size: '50', paddingH: '0', paddingV: '0', marginH: '0', marginV: '20', textAlign: 'left' }, {id: '1', type: 'title', content: 'second', color: '#c72929', backgroundColor: '#ffffff', size: '50', paddingH: '0', paddingV: '0', marginH: '0', marginV: '20', textAlign: 'left'  },{id: '1', type: 'paragraph', content: 'hello every body this is my first paragraph', color: 'black', backgroundColor: 'white', size: '50', paddingH: '0', paddingV: '0', marginH: '0', marginV: '20' , textAlign: 'left' }]);
    // const [DraggedElements, setDraggedElements] = useState([{type: 'paragraph', content: 'Write something..', color: '#4b4b4b', backgroundColor: '#ffffff', size: '20', paddingH: '0', paddingV: '0', marginH: '0', marginV: '5', textAlign: 'left' }]);
    const [EditElement, setEditElement] = useState("");

    function dragItem(type){
        var newIndex
        if(addIndex === 'end'){
            newIndex = DraggedElements.length
        }else{
            newIndex = addIndex
        }
        console.log('my index', newIndex)
        if(type === 'title'){
            var myArray = DraggedElements
            myArray.splice(newIndex , 0, {type: 'title', content: 'Write something..', color: '#000000', backgroundColor: '#ffffff', size: '40', paddingH: '0', paddingV: '0', marginH: '0', marginV: '5', textAlign: 'left' });
            //myArray.push({type: 'title', content: 'my second title', color: '#0046c7', backgroundColor: '#ffffff', size: '50', paddingH: '0', paddingV: '0', marginH: '0', marginV: '20', textAlign: 'left' })
            setDraggedElements(myArray)
            handleClose()
        }
        else if(type === 'paragraph'){
            var myArray = DraggedElements
            myArray.splice(newIndex , 0,{type: 'paragraph', content: 'write something..', color: '#4b4b4b', backgroundColor: '#ffffff', size: '20', paddingH: '0', paddingV: '0', marginH: '0', marginV: '5', textAlign: 'left', minHeight:"10%" })
            setDraggedElements(myArray)
            handleClose()
        }
        else if(type === 'line'){
            var myArray = DraggedElements
            myArray.splice(newIndex , 0,{type: 'line', content: 'my text', color: '#0046c7', backgroundColor: '#464545', height: '1', width: '100', paddingH: '0', paddingV: '0', marginH: '0', marginV: '20', alignSelf: 'center' })
            setDraggedElements(myArray)
            handleClose()
        }
        else if(type === 'space'){
            var myArray = DraggedElements
            myArray.splice(newIndex , 0,{type: 'space', content: 'my text', color: '#ffffff', backgroundColor: '#ffffff', height: '50', paddingH: '0', paddingV: '0', marginH: '0', marginV: '0', textAlign: 'left' })
            setDraggedElements(myArray)
            handleClose()
        }
        else if(type === 'image'){
            var myArray = DraggedElements
            myArray.splice(newIndex , 0,{type: 'image', content: 'https://cdn-icons-png.flaticon.com/512/716/716797.png', color: '#0046c7', backgroundColor: '#ffffff', size: '50', width: "20", height: "50", paddingH: '0', paddingV: '0', marginH: '0', marginV: '20', alignSelf: 'center' })
            setDraggedElements(myArray)
            handleClose()
        }
        else if(type === 'button'){
            var myArray = DraggedElements
            myArray.splice(newIndex , 0,{type: 'button', content: 'Button', url: '', borderColor: '#0046c7', borderRadius: "7", borderWidth: '1',  color: '#ffffff', backgroundColor: '#0046c7', size: '20', width: "20", height: "50", paddingH: '20', paddingV: '10', marginH: '0', marginV: '20', alignSelf: 'center' })
            setDraggedElements(myArray)
            handleClose()
        }
        else if(type === 'column'){
            var myArray = DraggedElements
            myArray.push({
                type: 'column',
                content: [
                    [
                        {type: 'title', content: 'my second title', color: '#0046c7', backgroundColor: '#ffffff', size: '30', paddingH: '0', paddingV: '0', marginH: '0', marginV: '20', textAlign: 'left' },
                        {type: 'paragraph', content: 'my second title', color: '#0046c7', backgroundColor: '#ffffff', size: '15', paddingH: '0', paddingV: '0', marginH: '0', marginV: '20', textAlign: 'left' }
                    ],
                    [
                        {type: 'title', content: 'my second title', color: '#0046c7', backgroundColor: '#ffffff', size: '30', paddingH: '0', paddingV: '0', marginH: '0', marginV: '20', textAlign: 'left' },
                        {type: 'paragraph', content: 'my second title', color: '#0046c7', backgroundColor: '#ffffff', size: '15', paddingH: '0', paddingV: '0', marginH: '0', marginV: '20', textAlign: 'left' }
                    ]
                ],
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between' })
            setDraggedElements(myArray)
            handleClose()
        }
    }

    const [title, setTitle] = useState('');


    function valueChanges(type, value, index){
        var array = [...DraggedElements]
        console.log(DraggedElements[index][type])
        setTitle(value)
        	
        // if(value == ''){
        //     value = 'Write Something..'
        // }
        array[index][type] = value
        console.log(array)
        setDraggedElements(array)
        console.log('hello brother')
    }


    const [content, setContent] = useState("");

   


    const [element, setElement] = useState({});
    const [index, setIndex] = useState("");
    const [action, setAction] = useState('design');
    const [codeVariant, setCodeVariant] = useState('');
    const [designVariant, setDesignVariant] = useState('outlined');     
    const [deleted, setDeleted] = useState('');


    const [ alertMessage, setAlertMessage] = useState('')
    const [ alertType, setAlertType] = useState('')
    const [ openAlert, setOpenAlert] = useState(false)



    function DeleteDragged(event, myindex){
        event.stopPropagation(); // Prevent the event from bubbling up to the outer element
        const INDEX = index
        console.log('same ? ', myindex === INDEX)
        console.log('myindex < INDEX ? ', myindex < INDEX)
        // setIndex('')
        setDeleted('deleted') 
        if(myindex !== INDEX ){
            setDeleted('') 
        }else if(myindex < INDEX){
            setIndex(INDEX - 1)
        }
        var myArray = [...DraggedElements]
        myArray.splice(myindex, 1);
        setDraggedElements(myArray)
        //console.log('index must deleted', index)
    }

    function generateHTML (){
        console.log('generating html..')
            var content = ''
            DraggedElements.forEach((element) => {
                    if(element.type === 'title'){
                        content += `<h1 style="font-size: `+element.size+`px; text-align: `+element.textAlign+`; color: `+element.color+`; margin: `+element.marginV+`px `+element.marginH+`px; padding: `+element.paddingV+`px `+element.paddingH+`px; background-color: `+element.backgroundColor+`;">`+element.content+`</h1>`
                            
                    }else if (element.type === 'paragraph'){
                        content += `<p style="font-size: `+element.size+`px; text-align: `+element.textAlign+`; color: `+element.color+`; margin: `+element.marginV+`px `+element.marginH+`px; padding: `+element.paddingV+`px `+element.paddingH+`px; background-color: `+element.backgroundColor+`;">`+element.content+`</p>`
                    }
                    else if (element.type === 'space'){
                        content += `<div style="height: `+element.height+`px; text-align: `+element.textAlign+`; color: `+element.color+`, margin: `+element.marginV +`px `+element.marginH+`px; padding: `+element.paddingV+`'px `+element.paddingH+`px; background-color: `+element.backgroundColor+`">`+element.content+`</div>`
                    }
                    else if (element.type === 'line'){
                        content += `<div  style="height: `+element.height+`px; width: `+element.width+`%; align-delf: `+element.alignSelf+`; color: `+element.color+`; margin: `+element.marginV+`px `+element.marginH+`px; padding: `+element.paddingV+`px `+element.paddingH+`px; background-color: `+element.backgroundColor+`" > </div>`
                    }
                    else if (element.type === 'image'){
                        content += `<div style="display: flex; flex-direction: column; position:relative; padding: 0%; margin: 0%; width: 100%">
                                    <img src="`+element.content+`" style="font-size: `+element.size+`px; color: `+element.color+`; margin: `+element.marginV+`px `+element.marginH+`px; padding: `+element.paddingV+`px `+element.paddingH+`px; background-color: `+element.backgroundColor+`; height: `+element.height+`%; width: `+element.width+`%; align-self: `+element.alignSelf+`" />
                                </div>`

                    }
                    else if (element.type === 'button'){
                        content += `<div style="display: flex; flex-direction: column; position:relative; padding: 0%; margin: 0%; width: 100%">
                                    <button style="width: max-content; font-size: `+element.size+`px; align-self: `+element.alignSelf+`; border: solid; border-color: `+element.borderColor+`; border-radius: `+element.borderRadius+`px; border-width: `+element.borderWidth+`px;  color: `+element.color+`; margin: `+element.marginV+`px `+element.marginH+`px', padding: `+element.paddingV+`px `+element.paddingH +`px; background-color: `+element.backgroundColor+`">`+element.content+`</button>
                                </div>`
                    }

            })

            var mypage  =`<!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>mailing</title>
            </head>
            <body style="width: 100%">` + content + `</body>
            </html>`
            setPage(mypage)
            return mypage
    }

    const [page, setPage] = useState("");
    function showmyCode(){
        setOpenDialog(true)
        console.log(action)
        if(action === 'design'){
            generateHTML()
        }else{
            console.log(mycode)
            setPage(mycode)
        }
    }


   


const [uploadedImages, setuploadedImages] = useState([]);
    const props = {
        name: 'file',
        multiple: false,
        action: '/mailing/upload_Email_image',
        method: 'post',
        onChange(info) {
          //console.log("hello")
          //setUploadCsvBtnDisabled(true)
          const { status } = info.file;
          if (status !== 'uploading') {
            //console.log(info.file, info.fileList);
            setuploadedImages([])
            for (let i = 0; i < info.fileList.length; i++) {
                const item = info.fileList[i];
                if(item.response.read === 'true'){
                    console.log(item.response.myImage)
                    setuploadedImages(prevArray => [...prevArray, item.response.myImage])
                    valueChanges('content', item.response.myImage, index);
                }
            }
          }
          if (status === 'done') {
            // setUploadCsvBtnDisabled(false)
            //message.success(`${info.file.name} file uploaded successfully.`);
            //console.log(info.file.response)
          } else if (status === 'error') {
            // setUploadCsvBtnDisabled(false)
            //console.log('comeonnnn')
            //console.log(info.file)
            console.log(`${info.file.name} file upload failed.`);
            if(info.file.response && info.file.response.read === 'false'){
              setAlertMessage(info.file.response.error)
              setAlertType('error')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000); 
            }
          }
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
          console.log('do something..')
        },
      };

    useEffect(() => {
        // Log the updated array whenever it changes
        console.log('data changes');
      }, [DraggedElements]);

      useEffect(() => {
        console.log("my content now: ", content)
    }, [content, EditElement]);

    useEffect(() => {
        console.log("myIndexIs ", index)
    }, [index, setIndex]);
    
    useEffect(() => {
        if(action === 'design'){
            setCodeVariant('')
            setDesignVariant('outlined')
        }else{
            setCodeVariant('outlined')
            setDesignVariant('')
        }
    }, [action]);

    useEffect(() => {
        console.log('my index', index)
        setDeleted('')
    }, [setIndex]);

    useEffect(() => {
        console.log('my deleted', deleted)
    }, [deleted]);


    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [openEditor, setOpenEditor] = useState(false);

    useEffect(() => {
        // Update screen width when the window is resized
        const handleResize = () => {
        setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);


  return (
    <Home>
        <EditArea width={screenWidth} open={openEditor} >
            <MyButton endIcon={<CloseIcon></CloseIcon>}  onClick={() => setOpenEditor(false)}> close </MyButton>
            <EditAreaType style={{display: "none"}}>
                <Button className='headerBtn' onClick={() => {setAction('code'); setElement({type: 'code', content: '', title: ''}); setIndex('code')}} variant={codeVariant} style={{color: 'white', borderColor: 'white'}}><CodeIcon ></CodeIcon></Button>

                <hr className='hr1'></hr>

                <Button className='headerBtn' onClick={() => {setAction('design'); setElement({}); setIndex('')}} variant={designVariant} style={{color: 'white', borderColor: 'white'}}> <DesignServicesIcon></DesignServicesIcon></Button>

            </EditAreaType>

            <EditElements>
                
                <EditTitle style={{marginLeft: '-5px', marginBottom: '10px'}}>Element Settings</EditTitle>
                {
                    [''].map((Item) => {
                        if(index === "" && index !== 0 || deleted === 'deleted'){
                            return (
                                <p style={{color: 'white'}}>no item selected</p>
                            )
                        }
                        else{
                            if(element.type === 'title' || element.type === 'paragraph'){
                                    return(
                                    <>
                                    <FormControl error={false} variant="standard" style={{color: 'white !important', width: '100%', margin: '0px 0px 20px 0px'}}>
                                        <InputLabel style={{ color: '#f5f5f5dc'}} htmlFor="title">Title</InputLabel>
                                        <Input
                                        type='text'
                                        id="title"
                                        onMouseOut={(e)=> {
                                            if(e.target.value == ''){
                                                valueChanges('content', 'write something..', index)
                                            }
                                        }}
                                        onChange={(e) =>{ valueChanges('content', e.target.value, index); }}
                                        defaultValue={element.content}
                                        value={element.content}
                                        aria-describedby="title_error"
                                        inputProps={{
                                            style: { color: 'white' },
                                        }}
                                        />
                                        <FormHelperText id="title_error"></FormHelperText>
                                    </FormControl>
                                    {/* <EditTitle>Styling</EditTitle> */}
                                    <EditTitle>Alignement</EditTitle>
                                    {
                                        [''].map((Item) => {
                                            if(element.textAlign === 'left'){
                                                return(
                                                    <Alignement>
                                                        <Button onClick={(e) =>{ valueChanges('textAlign', 'left', index); }}  className='headerBtn' variant='outlined' style={{color: 'white', borderColor: 'white'}}><FormatAlignLeftIcon></FormatAlignLeftIcon></Button>
                                                        <Button onClick={(e) =>{ valueChanges('textAlign', 'center', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignCenterIcon></FormatAlignCenterIcon></Button>
                                                        <Button onClick={(e) =>{ valueChanges('textAlign', 'right', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignRightIcon></FormatAlignRightIcon></Button>
                                                    </Alignement>
                                                )
                                            }
                                            else if(element.textAlign === 'center'){
                                                return (
                                                    <Alignement>
                                                        <Button onClick={(e) =>{ valueChanges('textAlign', 'left', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignLeftIcon></FormatAlignLeftIcon></Button>
                                                        <Button onClick={(e) =>{ valueChanges('textAlign', 'center', index); }} variant='outlined' className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignCenterIcon></FormatAlignCenterIcon></Button>
                                                        <Button onClick={(e) =>{ valueChanges('textAlign', 'right', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignRightIcon></FormatAlignRightIcon></Button>
                                                    </Alignement>
                                                )
                                            }
                                            else if(element.textAlign === 'right'){
                                                return (
                                                    <Alignement>
                                                        <Button onClick={(e) =>{ valueChanges('textAlign', 'left', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignLeftIcon></FormatAlignLeftIcon></Button>
                                                        <Button onClick={(e) =>{ valueChanges('textAlign', 'center', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignCenterIcon></FormatAlignCenterIcon></Button>
                                                        <Button onClick={(e) =>{ valueChanges('textAlign', 'right', index); }} variant='outlined' className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignRightIcon></FormatAlignRightIcon></Button>
                                                    </Alignement>
                                                )
                                            }
                                        })
                                    }




                                    <EditTitle>Size (px)</EditTitle>
                                    <Slider
                                        size="small"
                                        defaultValue={element.size}
                                        value={element.size}
                                        onChange={(e) =>{ valueChanges('size', e.target.value, index); }}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                    />

                                    <EditTitle>Padding Horizental (px)</EditTitle>
                                    <Slider
                                        size="small"
                                        defaultValue={element.paddingH}
                                        value={element.paddingH}
                                        onChange={(e) =>{ valueChanges('paddingH', e.target.value, index); }}

                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                    />

                                    <EditTitle>Padding Verical (px)</EditTitle>
                                    <Slider
                                        size="small"
                                        defaultValue={element.paddingV}
                                        value={element.paddingV}
                                        onChange={(e) =>{ valueChanges('paddingV', e.target.value, index); }}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                    />

                                    <EditTitle>Margin Horizental (px)</EditTitle>
                                    <Slider
                                        size="small"
                                        defaultValue={element.marginH}
                                        value={element.marginH}
                                        onChange={(e) =>{ valueChanges('marginH', e.target.value, index); }}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                    />

                                    <EditTitle>Margin Verical (px)</EditTitle>
                                    <Slider
                                        size="small"
                                        defaultValue={element.marginV}
                                        value={element.marginV}
                                        onChange={(e) =>{ valueChanges('marginV', e.target.value, index); }}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                    />

                                    <FormControl error={false} variant="standard" style={{color: 'white !important', width: '100%'}}>
                                        <InputLabel style={{ color: '#f5f5f5dc'}} htmlFor="title">Color</InputLabel>
                                        <Input
                                        type='color'
                                        id="title"
                                        defaultValue={element.color}
                                        value={element.color}
                                        onChange={(e) =>{ valueChanges('color', e.target.value, index); }}
                                        //onChange={(e) => console.log(e.target.value)}
                                        aria-describedby="title_error"
                                        inputProps={{
                                            style: { color: 'white' },
                                        }}
                                        />
                                        <FormHelperText id="title_error"></FormHelperText>
                                    </FormControl>

                                    <FormControl error={false} variant="standard" style={{color: 'white !important', width: '100%'}}>
                                        <InputLabel style={{ color: '#f5f5f5dc'}} htmlFor="title">Background Color</InputLabel>
                                        <Input
                                        type='color'
                                        id="title"
                                        defaultValue={element.backgroundColor}
                                        value={element.backgroundColor}
                                        onChange={(e) =>{ valueChanges('backgroundColor', e.target.value, index); }}
                                        aria-describedby="title_error"
                                        inputProps={{
                                            style: { color: 'white' },
                                        }}
                                        />
                                        <FormHelperText id="title_error"></FormHelperText>
                                    </FormControl>
                                    <Button startIcon={<DeleteIcon></DeleteIcon>} color='error' onClick={(e)=> DeleteDragged(e, index)}>Delete element</Button>

                                </>
                                )
                            }else if(element.type === 'space'){
                                return(
                                <>
                                {/* <EditTitle>Styling</EditTitle> */}
                                <EditTitle>Height (px)</EditTitle>
                                <Slider
                                    size="small"
                                    defaultValue={element.height}
                                    value={element.height}
                                    onChange={(e) =>{ valueChanges('height', e.target.value, index); }}
                                    aria-label="Small"
                                    valueLabelDisplay="auto"
                                />


                                <FormControl error={false} variant="standard" style={{color: 'white !important', width: '100%'}}>
                                    <InputLabel style={{ color: '#f5f5f5dc'}} htmlFor="title">Background Color</InputLabel>
                                    <Input
                                    type='color'
                                    id="title"
                                    defaultValue={element.backgroundColor}
                                    value={element.backgroundColor}
                                    onChange={(e) =>{ valueChanges('backgroundColor', e.target.value, index); }}
                                    aria-describedby="title_error"
                                    inputProps={{
                                        style: { color: 'white' },
                                    }}
                                    />
                                    <FormHelperText id="title_error"></FormHelperText>
                                </FormControl>
                                <Button startIcon={<DeleteIcon></DeleteIcon>} color='error' onClick={(e)=> DeleteDragged(e, index)}>Delete element</Button>

                            </>
                            )
                            }if(element.type === 'line'){
                                return(
                                <>
                                {/* <EditTitle>Styling</EditTitle> */}
                                <EditTitle>Alignement</EditTitle>
                                {
                                    [''].map((Item) => {
                                        if(element.alignSelf === 'start'){
                                            return(
                                                <Alignement>
                                                    <Button onClick={(e) =>{ valueChanges('alignSelf', 'start', index); }}  className='headerBtn' variant='outlined' style={{color: 'white', borderColor: 'white'}}><FormatAlignLeftIcon></FormatAlignLeftIcon></Button>
                                                    <Button onClick={(e) =>{ valueChanges('alignSelf', 'center', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignCenterIcon></FormatAlignCenterIcon></Button>
                                                    <Button onClick={(e) =>{ valueChanges('alignSelf', 'end', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignRightIcon></FormatAlignRightIcon></Button>
                                                </Alignement>
                                            )
                                        }
                                        else if(element.alignSelf === 'center'){
                                            return (
                                                <Alignement>
                                                    <Button onClick={(e) =>{ valueChanges('alignSelf', 'start', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignLeftIcon></FormatAlignLeftIcon></Button>
                                                    <Button onClick={(e) =>{ valueChanges('alignSelf', 'center', index); }} variant='outlined' className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignCenterIcon></FormatAlignCenterIcon></Button>
                                                    <Button onClick={(e) =>{ valueChanges('alignSelf', 'end', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignRightIcon></FormatAlignRightIcon></Button>
                                                </Alignement>
                                            )
                                        }
                                        else if(element.alignSelf === 'end'){
                                            return (
                                                <Alignement>
                                                    <Button onClick={(e) =>{ valueChanges('alignSelf', 'start', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignLeftIcon></FormatAlignLeftIcon></Button>
                                                    <Button onClick={(e) =>{ valueChanges('alignSelf', 'center', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignCenterIcon></FormatAlignCenterIcon></Button>
                                                    <Button onClick={(e) =>{ valueChanges('alignSelf', 'end', index); }} variant='outlined' className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignRightIcon></FormatAlignRightIcon></Button>
                                                </Alignement>
                                            )
                                        }
                                    })
                                }



                                <EditTitle>height (px)</EditTitle>
                                <Slider
                                    size="small"
                                    defaultValue={element.height}
                                    value={element.height}
                                    onChange={(e) =>{ valueChanges('height', e.target.value, index); }}
                                    aria-label="Small"
                                    valueLabelDisplay="auto"
                                />

                                <EditTitle>width (%)</EditTitle>
                                <Slider
                                    size="small"
                                    defaultValue={element.width}
                                    value={element.width}
                                    onChange={(e) =>{ valueChanges('width', e.target.value, index); }}
                                    aria-label="Small"
                                    valueLabelDisplay="auto"
                                />

                                <EditTitle>Padding Horizental (px)</EditTitle>
                                <Slider
                                    size="small"
                                    defaultValue={element.paddingH}
                                    value={element.paddingH}
                                    onChange={(e) =>{ valueChanges('paddingH', e.target.value, index); }}

                                    aria-label="Small"
                                    valueLabelDisplay="auto"
                                />

                                <EditTitle>Padding Verical (px)</EditTitle>
                                <Slider
                                    size="small"
                                    defaultValue={element.paddingV}
                                    value={element.paddingV}
                                    onChange={(e) =>{ valueChanges('paddingV', e.target.value, index); }}
                                    aria-label="Small"
                                    valueLabelDisplay="auto"
                                />

                                <EditTitle>Margin Horizental (px)</EditTitle>
                                <Slider
                                    size="small"
                                    defaultValue={element.marginH}
                                    value={element.marginH}
                                    onChange={(e) =>{ valueChanges('marginH', e.target.value, index); }}
                                    aria-label="Small"
                                    valueLabelDisplay="auto"
                                />

                                <EditTitle>Margin Verical (px)</EditTitle>
                                <Slider
                                    size="small"
                                    defaultValue={element.marginV}
                                    value={element.marginV}
                                    onChange={(e) =>{ valueChanges('marginV', e.target.value, index); }}
                                    aria-label="Small"
                                    valueLabelDisplay="auto"
                                />

                                <FormControl error={false} variant="standard" style={{color: 'white !important', width: '100%'}}>
                                    <InputLabel style={{ color: '#f5f5f5dc'}} htmlFor="title">Color</InputLabel>
                                    <Input
                                    type='color'
                                    id="title"
                                    defaultValue={element.backgroundColor}
                                    value={element.backgroundColor}
                                    onChange={(e) =>{ valueChanges('backgroundColor', e.target.value, index); }}
                                    aria-describedby="title_error"
                                    inputProps={{
                                        style: { color: 'white' },
                                    }}
                                    />
                                    <FormHelperText id="title_error"></FormHelperText>
                                </FormControl>
                                <Button startIcon={<DeleteIcon></DeleteIcon>} color='error' onClick={(e)=> DeleteDragged(e, index)}>Delete element</Button>

                            </>
                            )
                            }
                            else if( element.type === 'image'){

                                return(
                                    <>
                                    <Upload {...props}>
                                        <Button variant='outlined'   style={{ color: '#64257e', borderColor: "#64257e"}} startIcon={<FileUploadOutlinedIcon />}>Add Images</Button>
                                    </Upload>
                                   
                                    {/* <EditTitle>Styling</EditTitle> */}
                                    <EditTitle>Alignement</EditTitle>
                                    {
                                        [''].map((Item) => {
                                            if(element.alignSelf === 'start'){
                                                return(
                                                    <Alignement>
                                                        <Button onClick={(e) =>{ valueChanges('alignSelf', 'start', index); }}  className='headerBtn' variant='outlined' style={{color: 'white', borderColor: 'white'}}><FormatAlignLeftIcon></FormatAlignLeftIcon></Button>
                                                        <Button onClick={(e) =>{ valueChanges('alignSelf', 'center', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignCenterIcon></FormatAlignCenterIcon></Button>
                                                        <Button onClick={(e) =>{ valueChanges('alignSelf', 'end', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignRightIcon></FormatAlignRightIcon></Button>
                                                    </Alignement>
                                                )
                                            }
                                            else if(element.alignSelf === 'center'){
                                                return (
                                                    <Alignement>
                                                        <Button onClick={(e) =>{ valueChanges('alignSelf', 'start', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignLeftIcon></FormatAlignLeftIcon></Button>
                                                        <Button onClick={(e) =>{ valueChanges('alignSelf', 'center', index); }} variant='outlined' className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignCenterIcon></FormatAlignCenterIcon></Button>
                                                        <Button onClick={(e) =>{ valueChanges('alignSelf', 'end', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignRightIcon></FormatAlignRightIcon></Button>
                                                    </Alignement>
                                                )
                                            }
                                            else if(element.alignSelf === 'end'){
                                                return (
                                                    <Alignement>
                                                        <Button onClick={(e) =>{ valueChanges('alignSelf', 'start', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignLeftIcon></FormatAlignLeftIcon></Button>
                                                        <Button onClick={(e) =>{ valueChanges('alignSelf', 'center', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignCenterIcon></FormatAlignCenterIcon></Button>
                                                        <Button onClick={(e) =>{ valueChanges('alignSelf', 'end', index); }} variant='outlined' className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignRightIcon></FormatAlignRightIcon></Button>
                                                    </Alignement>
                                                )
                                            }
                                        })
                                    }



                                    {/* <FormControl error={false} variant="standard" style={{color: 'white !important', width: '100%'}}>
                                        <InputLabel style={{ color: '#f5f5f5dc'}} htmlFor="title">Size</InputLabel>
                                        <Input
                                        type='number'
                                        id="title"
                                        defaultValue=""
                                        aria-describedby="title_error"
                                        inputProps={{
                                            style: { color: 'white' },
                                        }}
                                        />
                                        <FormHelperText id="title_error"></FormHelperText>
                                    </FormControl> */}



                                    <EditTitle>size (%)</EditTitle>
                                    <Slider
                                        size="small"
                                        defaultValue={element.width}
                                        value={element.width}
                                        onChange={(e) =>{ valueChanges('width', e.target.value, index); }}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                    />



                                    <EditTitle>Padding Horizental (px)</EditTitle>
                                    <Slider
                                        size="small"
                                        defaultValue={element.paddingH}
                                        value={element.paddingH}
                                        onChange={(e) =>{ valueChanges('paddingH', e.target.value, index); }}

                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                    />

                                    <EditTitle>Padding Verical (px)</EditTitle>
                                    <Slider
                                        size="small"
                                        defaultValue={element.paddingV}
                                        value={element.paddingV}
                                        onChange={(e) =>{ valueChanges('paddingV', e.target.value, index); }}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                    />

                                    <EditTitle>Margin Horizental (px)</EditTitle>
                                    <Slider
                                        size="small"
                                        defaultValue={element.marginH}
                                        value={element.marginH}
                                        onChange={(e) =>{ valueChanges('marginH', e.target.value, index); }}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                    />

                                    <EditTitle>Margin Verical (px)</EditTitle>
                                    <Slider
                                        size="small"
                                        defaultValue={element.marginV}
                                        value={element.marginV}
                                        onChange={(e) =>{ valueChanges('marginV', e.target.value, index); }}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                    />

                                    <FormControl error={false} variant="standard" style={{color: 'white !important', width: '100%'}}>
                                        <InputLabel style={{ color: '#f5f5f5dc'}} htmlFor="title">Color</InputLabel>
                                        <Input
                                        type='color'
                                        id="title"
                                        defaultValue={element.color}
                                        value={element.color}
                                        onChange={(e) =>{ valueChanges('color', e.target.value, index); }}
                                        //onChange={(e) => console.log(e.target.value)}
                                        aria-describedby="title_error"
                                        inputProps={{
                                            style: { color: 'white' },
                                        }}
                                        />
                                        <FormHelperText id="title_error"></FormHelperText>
                                    </FormControl>

                                    <FormControl error={false} variant="standard" style={{color: 'white !important', width: '100%'}}>
                                        <InputLabel style={{ color: '#f5f5f5dc'}} htmlFor="title">Background Color</InputLabel>
                                        <Input
                                        type='color'
                                        id="title"
                                        defaultValue={element.backgroundColor}
                                        value={element.backgroundColor}
                                        onChange={(e) =>{ valueChanges('backgroundColor', e.target.value, index); }}
                                        aria-describedby="title_error"
                                        inputProps={{
                                            style: { color: 'white' },
                                        }}
                                        />
                                        <FormHelperText id="title_error"></FormHelperText>
                                    </FormControl>
                                    <Button startIcon={<DeleteIcon></DeleteIcon>} color='error' onClick={(e)=> DeleteDragged(e, index)}>Delete element</Button>

                                </>
                                )
                            }
                            else if( element.type === 'button'){

                                return(
                                    <>
                                    <FormControl error={false} variant="standard" style={{color: 'white !important', width: '100%', margin: '0px 0px 20px 0px'}}>
                                        <InputLabel style={{ color: '#f5f5f5dc'}} htmlFor="title">Title</InputLabel>
                                        <Input
                                        type='text'
                                        id="title"
                                        onChange={(e) =>{ valueChanges('content', e.target.value, index); console.log(e.target.value) }}
                                        defaultValue={element.content}
                                        value={element.content}
                                        aria-describedby="title_error"
                                        inputProps={{
                                            style: { color: 'white' },
                                        }}
                                        />
                                        <FormHelperText id="title_error"></FormHelperText>
                                    </FormControl>


                                    <FormControl error={false} variant="standard" style={{color: 'white !important', width: '100%', margin: '0px 0px 20px 0px'}}>
                                        <InputLabel style={{ color: '#f5f5f5dc'}} htmlFor="title">Link</InputLabel>
                                        <Input
                                        type='text'
                                        id="title"
                                        onChange={(e) =>{ valueChanges('url', e.target.value, index); console.log(e.target.url) }}
                                        defaultValue={element.url}
                                        value={element.url}
                                        aria-describedby="title_error"
                                        inputProps={{
                                            style: { color: 'white' },
                                        }}
                                        />
                                        <FormHelperText id="title_error"></FormHelperText>
                                    </FormControl>


                                    {/* <EditTitle>Styling</EditTitle> */}
                                    <EditTitle>Alignement</EditTitle>
                                    {
                                        [''].map((Item) => {
                                            if(element.alignSelf === 'start'){
                                                return(
                                                    <Alignement>
                                                        <Button onClick={(e) =>{ valueChanges('alignSelf', 'start', index); }}  className='headerBtn' variant='outlined' style={{color: 'white', borderColor: 'white'}}><FormatAlignLeftIcon></FormatAlignLeftIcon></Button>
                                                        <Button onClick={(e) =>{ valueChanges('alignSelf', 'center', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignCenterIcon></FormatAlignCenterIcon></Button>
                                                        <Button onClick={(e) =>{ valueChanges('alignSelf', 'end', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignRightIcon></FormatAlignRightIcon></Button>
                                                    </Alignement>
                                                )
                                            }
                                            else if(element.alignSelf === 'center'){
                                                return (
                                                    <Alignement>
                                                        <Button onClick={(e) =>{ valueChanges('alignSelf', 'start', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignLeftIcon></FormatAlignLeftIcon></Button>
                                                        <Button onClick={(e) =>{ valueChanges('alignSelf', 'center', index); }} variant='outlined' className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignCenterIcon></FormatAlignCenterIcon></Button>
                                                        <Button onClick={(e) =>{ valueChanges('alignSelf', 'end', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignRightIcon></FormatAlignRightIcon></Button>
                                                    </Alignement>
                                                )
                                            }
                                            else if(element.alignSelf === 'end'){
                                                return (
                                                    <Alignement>
                                                        <Button onClick={(e) =>{ valueChanges('alignSelf', 'start', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignLeftIcon></FormatAlignLeftIcon></Button>
                                                        <Button onClick={(e) =>{ valueChanges('alignSelf', 'center', index); }} className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignCenterIcon></FormatAlignCenterIcon></Button>
                                                        <Button onClick={(e) =>{ valueChanges('alignSelf', 'end', index); }} variant='outlined' className='headerBtn' style={{color: 'white', borderColor: 'white'}}><FormatAlignRightIcon></FormatAlignRightIcon></Button>
                                                    </Alignement>
                                                )
                                            }
                                        })
                                    }

                                    <EditTitle>size (px)</EditTitle>
                                    <Slider
                                        size="small"
                                        defaultValue={element.size}
                                        value={element.size}
                                        onChange={(e) =>{ valueChanges('size', e.target.value, index); }}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                    />

                                    <EditTitle>Padding Horizental (px)</EditTitle>
                                    <Slider
                                        size="small"
                                        defaultValue={element.paddingH}
                                        value={element.paddingH}
                                        onChange={(e) =>{ valueChanges('paddingH', e.target.value, index); }}

                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                    />

                                    <EditTitle>Padding Verical (px)</EditTitle>
                                    <Slider
                                        size="small"
                                        defaultValue={element.paddingV}
                                        value={element.paddingV}
                                        onChange={(e) =>{ valueChanges('paddingV', e.target.value, index); }}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                    />

                                    <EditTitle>Margin Horizental (px)</EditTitle>
                                    <Slider
                                        size="small"
                                        defaultValue={element.marginH}
                                        value={element.marginH}
                                        onChange={(e) =>{ valueChanges('marginH', e.target.value, index); }}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                    />

                                    <EditTitle>Margin Verical (px)</EditTitle>
                                    <Slider
                                        size="small"
                                        defaultValue={element.marginV}
                                        value={element.marginV}
                                        onChange={(e) =>{ valueChanges('marginV', e.target.value, index); }}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                    />

                                    <EditTitle>Border width (px)</EditTitle>
                                    <Slider
                                        size="small"
                                        defaultValue={element.borderWidth}
                                        value={element.borderWidth}
                                        onChange={(e) =>{ valueChanges('borderWidth', e.target.value, index); }}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                    />

                                    <EditTitle>Border Radius (px)</EditTitle>
                                    <Slider
                                        size="small"
                                        defaultValue={element.borderRadius}
                                        value={element.borderRadius}
                                        onChange={(e) =>{ valueChanges('borderRadius', e.target.value, index); }}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                    />


                                    <FormControl error={false} variant="standard" style={{color: 'white !important', width: '100%'}}>
                                        <InputLabel style={{ color: '#f5f5f5dc'}} htmlFor="title">Color</InputLabel>
                                        <Input
                                        type='color'
                                        id="title"
                                        defaultValue={element.color}
                                        value={element.color}
                                        onChange={(e) =>{ valueChanges('color', e.target.value, index); }}
                                        //onChange={(e) => console.log(e.target.value)}
                                        aria-describedby="title_error"
                                        inputProps={{
                                            style: { color: 'white' },
                                        }}
                                        />
                                        <FormHelperText id="title_error"></FormHelperText>
                                    </FormControl>

                                    <FormControl error={false} variant="standard" style={{color: 'white !important', width: '100%'}}>
                                        <InputLabel style={{ color: '#f5f5f5dc'}} htmlFor="title">Background Color</InputLabel>
                                        <Input
                                        type='color'
                                        id="title"
                                        defaultValue={element.backgroundColor}
                                        value={element.backgroundColor}
                                        onChange={(e) =>{ valueChanges('backgroundColor', e.target.value, index); }}
                                        aria-describedby="title_error"
                                        inputProps={{
                                            style: { color: 'white' },
                                        }}
                                        />
                                        <FormHelperText id="title_error"></FormHelperText>
                                    </FormControl>

                                    <FormControl error={false} variant="standard" style={{color: 'white !important', width: '100%'}}>
                                        <InputLabel style={{ color: '#f5f5f5dc'}} htmlFor="title">Border Color</InputLabel>
                                        <Input
                                        type='color'
                                        id="title"
                                        defaultValue={element.borderColor}
                                        value={element.borderColor}
                                        onChange={(e) =>{ valueChanges('borderColor', e.target.value, index); }}
                                        aria-describedby="title_error"
                                        inputProps={{
                                            style: { color: 'white' },
                                        }}
                                        />
                                        <FormHelperText id="title_error"></FormHelperText>
                                    </FormControl>
                                    <Button startIcon={<DeleteIcon></DeleteIcon>} color='error' onClick={(e)=> DeleteDragged(e, index)}>Delete element</Button>

                                </>
                                )
                            }
                            else if(element.type === 'code'){
                                return(
                                <>
                                <FormControl error={false} variant="standard" style={{color: 'white !important', width: '100%', margin: '0px 0px 20px 0px'}}>
                                    <InputLabel style={{ color: '#f5f5f5dc'}} htmlFor="title">Title</InputLabel>
                                    <Input
                                    type='text'
                                    id="title"
                                    onChange={(e) =>{ setElement({type: 'code', title: e.target.value}); }}
                                    defaultValue={element.title}
                                    value={element.title}
                                    aria-describedby="title_error"
                                    inputProps={{
                                        style: { color: 'white' },
                                    }}
                                    />
                                    <FormHelperText id="title_error"></FormHelperText>
                                </FormControl>
                                
                            </>
                            )
                        }
                            
                        }

                    })
                }

                {EditElement}
            </EditElements>

        </EditArea>
        <BuildArea>
            <EditAreaType>
                <Button className='headerBtn' onClick={() => {setAction('code'); setElement({type: 'code', content: '', title: ''}); setIndex('code')}} variant={codeVariant} style={{color: 'red !important', borderColor: 'gray'}}><CodeIcon ></CodeIcon></Button>

                <hr className='hr1'></hr>

                <Button className='headerBtn' onClick={() => {setAction('design'); setElement({}); setIndex('')}} variant={designVariant} style={{color: 'red !important', borderColor: 'gray'}}> <DesignServicesIcon></DesignServicesIcon></Button>

            </EditAreaType>
            {
                [""].map(() => {
                    if(action === 'code'){
                        return(
                            <>
                            <DesingingArea>
                                <p style={{margin: '0px', padding: '0px', width: '100%', color: 'gray', textAlign: 'left', borderBottom: '1px solid gray'}}>Code</p>
                                <TextField
                                style={{width: "calc(100% - 10px)" , backgroundColor: "white", padding: '5px'}}
                                id="standard-multiline-static"
                                multiline
                                rows={24}
                                defaultValue={mycode}
                                variant="standard"
                                onChange={(e) => setMycode(e.target.value)}
                                />
                                
                            </DesingingArea>
                            <BtnContainer style={{width: "100%"}}>
                                <Tooltip title='Show the message' >
                                    <IconButton onClick={() => showmyCode()} size='small' variant="contained"  style={{marginLeft: '10px', fontSize: "small", backgroundColor: '#85a5d6'}}  >  <RemoveRedEyeIcon /> </IconButton>
                                </Tooltip>

                                <Tooltip title='Programme the message' >
                                    <Button disable={disableCodeScheduleBtn} variant="contained"  style={{marginLeft: '10px', fontSize: "small", backgroundColor: '#85a5d6'}} onClick={() => { pre_programmeMessage('code', mycode)}} >  programme </Button>
                                </Tooltip>

                                <Tooltip title='Send the message' >
                                    <Button disable={disableCodeSendBtn} variant="contained" color="secondary" style={{marginLeft: '10px', fontSize: "small"}} onClick={() => sendMails('code', mycode)}  > send </Button>
                                </Tooltip>
                            </BtnContainer>
                            </>
                        )
                    }else{

                        return(
                        <>
                        <DesingingArea>
                        {
                            DraggedElements.map((element, index) => {
                                if(element.type === 'title'){
                                    return(
                                        <>
                                            <div onClick={(event) => {handleClick(event, index)}} className='newItemAdding' style={{backgroundColor: 'none', height: '5px'}}>
                                                <AddCircleIcon className='myAddIcon' style={{display: 'none', color: '#61dafb'}}></AddCircleIcon>
                                            </div>
                                            <h1 className='draggedElement' style={{fontSize: element.size + 'px', textAlign: element.textAlign  , color: element.color, margin: element.marginV + 'px ' + element.marginH + 'px', padding: element.paddingV + 'px ' + element.paddingH + 'px', backgroundColor: element.backgroundColor }} onClick={() => {setElement(element); setIndex(index); console.log('container', index); setDeleted(''); setOpenEditor(true)}} >{element.content} <Fab size="small" className='MyDeleteIcon' color="error" aria-label="delete" onClick={(e) => {DeleteDragged(e, index)}} ><DeleteIcon /></Fab></h1>
                                        </>
                                        )
                                }else if (element.type === 'paragraph'){
                                    return(
                                        <>
                                            <div onClick={(event) => handleClick(event, index)} className='newItemAdding' style={{backgroundColor: 'none', height: '5px'}}>
                                                <AddCircleIcon className='myAddIcon' style={{display: 'none', color: '#61dafb'}}></AddCircleIcon>
                                            </div>
                                            <p className='draggedElement'  style={{fontSize: element.size + 'px', textAlign: element.textAlign  , color: element.color, margin: element.marginV + 'px ' + element.marginH + 'px', padding: element.paddingV + 'px ' + element.paddingH + 'px', backgroundColor: element.backgroundColor }} onClick={() => {setElement(element); setIndex(index); setDeleted('');  setOpenEditor(true)}} >{element.content}  <Fab size="small" className='MyDeleteIcon' color="error" aria-label="delete" onClick={(e) => DeleteDragged(e, index)}><DeleteIcon /></Fab></p>
                                        </>
                                    )
                                }
                                else if (element.type === 'space'){
                                    return(
                                        <>
                                            <div onClick={(event) => handleClick(event, index)} className='newItemAdding' style={{backgroundColor: 'none', height: '5px'}}>
                                                <AddCircleIcon className='myAddIcon' style={{display: 'none', color: '#61dafb'}}></AddCircleIcon>
                                            </div>
                                            <div className='draggedElement'  style={{height: element.height + 'px', textAlign: element.textAlign  , color: element.color, margin: element.marginV + 'px ' + element.marginH + 'px', padding: element.paddingV + 'px ' + element.paddingH + 'px', backgroundColor: element.backgroundColor }} onClick={() => {setElement(element); setIndex(index); setDeleted('');  setOpenEditor(true)}} >{element.content}  <Fab size="small" className='MyDeleteIcon' color="error" aria-label="delete" onClick={(e) => {DeleteDragged(e, index)}}><DeleteIcon /></Fab></div>
                                        </>
                                    )
                                }
                                else if (element.type === 'line'){
                                    return(
                                        <>
                                            <div onClick={(event) => handleClick(event, index)} className='newItemAdding' style={{backgroundColor: 'none', height: '5px'}}>
                                                <AddCircleIcon className='myAddIcon' style={{display: 'none', color: '#61dafb'}}></AddCircleIcon>
                                            </div>
                                            <div onClick={() => {setElement(element); setIndex(index)}} className='draggedElement' style={{display: 'flex', flexDirection: 'column', position:'relative', padding: '10px 0px', margin: '0%'}}>
                                                <Fab size="small" className='MyDeleteIcon' color="error" aria-label="delete" onClick={() => DeleteDragged(index)}><DeleteIcon /></Fab>
                                                <div  style={{height: element.height + 'px', width: element.width + '%', alignSelf: element.alignSelf  , color: element.color, margin: element.marginV + 'px ' + element.marginH + 'px', padding: element.paddingV + 'px ' + element.paddingH + 'px', backgroundColor: element.backgroundColor }} onClick={() => {setElement(element); setIndex(index); setDeleted('');  setOpenEditor(true)}} > <Fab size="small" className='MyDeleteIcon' color="error" aria-label="delete" onClick={(e) => {DeleteDragged(e, index)}}><DeleteIcon /></Fab></div>
                                            </div>
                                        </>
                                    )
                                }
                                else if (element.type === 'image'){
                                    return(
                                        <>
                                            <div onClick={(event) => handleClick(event, index)} className='newItemAdding' style={{backgroundColor: 'none', height: '5px'}}>
                                                <AddCircleIcon className='myAddIcon' style={{display: 'none', color: '#61dafb'}}></AddCircleIcon>
                                            </div>
                                            <div onClick={() => {setElement(element); setIndex(index); setDeleted('');  setOpenEditor(true)}} className='draggedElement' style={{display: 'flex', flexDirection: 'column', position:'relative', padding: '0%', margin: '0%', width: '100%'}}>
                                                <Fab size="small" className='MyDeleteIcon' color="error" aria-label="delete" onClick={(e) => {DeleteDragged(e, index)}}><DeleteIcon /></Fab>
                                                <img src={element.content} style={{fontSize: element.size + 'px', color: element.color, margin: element.marginV + 'px ' + element.marginH + 'px', padding: element.paddingV + 'px ' + element.paddingH + 'px', backgroundColor: element.backgroundColor, height: element.height + '%', width: element.width + '%', alignSelf: element.alignSelf }} />
                                            </div>
                                        </>

                                    )
                                }
                                else if (element.type === 'button'){
                                    return(
                                        <>
                                            <div onClick={(event) => handleClick(event, index)} className='newItemAdding' style={{backgroundColor: 'none', height: '5px'}}>
                                                <AddCircleIcon className='myAddIcon' style={{display: 'none', color: '#61dafb'}}></AddCircleIcon>
                                            </div>
                                            <div onClick={() => {setElement(element); setIndex(index); setDeleted('');  setOpenEditor(true)}} className='draggedElement' style={{display: 'flex', flexDirection: 'column', position:'relative', padding: '0%', margin: '0%', width: '100%'}}>
                                                <Fab size="small" className='MyDeleteIcon' color="error" aria-label="delete" onClick={(e) => {DeleteDragged(e, index)}}><DeleteIcon /></Fab>
                                                <button className='draggedElement'  style={{width: "max-content", fontSize: element.size + 'px', alignSelf: element.alignSelf  ,border: 'solid', borderColor: element.borderColor, borderRadius: element.borderRadius + 'px', borderWidth: element.borderWidth + "px",  color: element.color, margin: element.marginV + 'px ' + element.marginH + 'px', padding: element.paddingV + 'px ' + element.paddingH + 'px', backgroundColor: element.backgroundColor }} onClick={() => {setElement(element); setIndex(index)}} >{element.content}</button>
                                            </div>
                                        </>
                                    )
                                }

                            })
                        }
                        </DesingingArea>

                        
                        <Button id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(event) => handleClick(event, 'end')}
                        style={{marginTop: '20px', backgroundColor: '#9c27b0', color:'white'}}
                        startIcon={<DesignServicesIcon />}>Add Element</Button>

                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                        >
                            <HtmlTags>

                                <HtmlTag onClick={() => dragItem('title')} ><TextFieldsIcon style={{fontSize:'40px'}}></TextFieldsIcon> Title</HtmlTag>
                                <HtmlTag onClick={() => dragItem('paragraph')}> <NotesIcon style={{fontSize:'40px'}}></NotesIcon>  Paragraph</HtmlTag>
                                <HtmlTag><ImageIcon onClick={() => dragItem('image')} style={{fontSize:'40px'}} ></ImageIcon> Image</HtmlTag>
                                {/* <HtmlTag><ViewColumnIcon  onClick={() => dragItem('column')} style={{fontSize:'40px'}} ></ViewColumnIcon> Column</HtmlTag> */}
                                <HtmlTag><SpaceBarIcon  onClick={() => dragItem('space')} style={{fontSize:'40px'}} ></SpaceBarIcon> Space</HtmlTag>
                                <HtmlTag><PowerInputIcon  onClick={() => dragItem('line')} style={{fontSize:'40px'}} ></PowerInputIcon> Line</HtmlTag>
                                <HtmlTag><Crop32Icon  onClick={() => dragItem('button')} style={{fontSize:'40px'}} ></Crop32Icon> Button</HtmlTag>
                            </HtmlTags>
                            {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                        </Menu>

                        <BtnContainer style={{position: "absolute"}}>
                                <Tooltip title='Show the message' >
                                    <IconButton onClick={() => showmyCode()} size='small' variant="contained"  style={{marginLeft: '10px', fontSize: "small", backgroundColor: '#85a5d6'}}  >  <RemoveRedEyeIcon /> </IconButton>
                                </Tooltip>
                            <Tooltip title='Programme the message' >
                                <Button variant="contained" disable={disableDesignScheduleBtn}  style={{marginLeft: '10px', fontSize: "small", backgroundColor: '#85a5d6'}}  onClick={() => {var code = generateHTML(); pre_programmeMessage('design', code)}}  >  programme </Button>
                            </Tooltip>

                            <Tooltip title='Send the message' >
                                <Button variant="contained" disable={disableDesignSendBtn} color="secondary" style={{marginLeft: '10px', fontSize: "small"}} onClick={() => {var code = generateHTML(); sendMails('design', code)}}  > send </Button>
                            </Tooltip>
                        </BtnContainer>
                        </>
                        )
                    }
                })
            }
            


        </BuildArea>



        <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={openDialog}
        onClose={handleCloseDialog}
        style={{minHeight: '50vh'}}
      >
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
            <div style={{width: '100vh'}} dangerouslySetInnerHTML={{ __html: page }} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>


    
      
      
      <AlertComp open={openAlert} message={alertMessage} type={alertType} handleClose={handleClose} ></AlertComp>

    </Home>
  )
}





