import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SwipeDownAltIcon from '@mui/icons-material/SwipeDownAlt';
import SwipeUpAltIcon from '@mui/icons-material/SwipeUpAlt';
import SwipeLeftAltIcon from '@mui/icons-material/SwipeLeftAlt';
import CategoryIcon from '@mui/icons-material/Category';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { ArrowDownward, ArrowDropDown, DetailsOutlined, OpenInBrowser, OpenInFull, OpenInFullSharp, OpenInNewOutlined } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Body = styled.div`
    width: 100%;
`

const Header = styled.div`
    width: 96%;
    padding: 10px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px #e1e1e1 solid;
    @media only screen and (max-width: 850px) {
        flex-direction: column !important;
    }
`
const HeaderTitle = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: center;
    @media only screen and (max-width: 850px) {
        width: 100% !important;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: end;

    }
`

const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    @media only screen and (max-width: 850px) {
      width: 100%;
    }
`
const BodyContent = styled.div`
    width: 100%;
    background-color: #f6f7fa;
    //min-height: 320px;
    min-height: calc(100vh - 115px);
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: start;
`
const Form = styled.div`
    width: calc(90% - 40px) ;
    margin: 25px 5%;
    background-color: white;
    min-height: 80%;
    height: max-content;
    min-height: 300px;
    box-shadow: 0px 0px 1px 0px grey;
    padding: 10px 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: start;
    flex-wrap: wrap;
`

const Element = styled.div`
  width: 18%;
  min-width: 250px;
  height: 120px;
  background-color: #f5f5f5b3;
  padding: 5px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 25px;
  box-shadow: 0px 0px 1px 0px #0000007e;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover{
    box-shadow: 0px 0px 3px 0px #0000006b;
  }
`

const ElementTitle = styled.p`
  padding: 0;
  margin: 0;
  color: #8895d9;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  text-align: start;
  align-self: start;
`

const ElementNumber = styled.p`
  padding: 0;
  margin: 0;
  width: calc(100% - 5px);
  text-align: center;
  color: #000000c2;
  font-size: 35px;
  padding: 5px 5px 0px 5px;
  font-weight: 600;
`

const ElementDesc = styled.p`
  padding: 0;
  margin: 0;
  width: calc(100% - 5px);
  text-align: center;
  color: #00000090;
  font-size: medium;
  padding: 0px 5px;
  margin-bottom: 10px;
`


const ElementDouble = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ElementDoubleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export default function HomeInvoice({Language, handleError, handleHeader}) {


    // mui config

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenAlert(false);
    };


    // end mui config

    const navigate = useNavigate()
    const [ alertMessage, setAlertMessage] = useState('')
    const [ alertType, setAlertType] = useState('')
    const [ openAlert, setOpenAlert] = useState(false)
  
    const [productsCount, setProductsCount] = useState(0);
    const [receivedCount, setReceivedCount] = useState(0);
    const [deliveredCount, setDeliveredCount] = useState(0);
    const [returnedCount, setReturnedCount] = useState(0);
    
    const [Invoice, setInvoice] = useState([]);
    const [Operations, setOperations] = useState([]);
    const [invoiceCount, setInvoiceCount] = useState(0);
    const [isLoading, setIssLoading] = useState(true);

    function getHistory (type){
      setrowLoading(true)
      axios
        .post("/invoice_management/invoices", {type})
          .then(function (response) {
            setIssLoading(false)
            console.log(response.data)
            setInvoice(response.data.invoices)
            setInvoiceCount(response.data.invoices.length)
            setrowLoading(false)
        })
        .catch(function (error) {
          // handle error
          setrowLoading(false)
          handleError(error)
        })
    }

    const [rowLoading, setrowLoading] = useState(false);
    const [invoiceId, setInvoiceId] = useState();
    
    function changeStatus(type){
      setrowLoading(true)
      axios
      .post("/invoice_management/change", {invoiceId, type})
        .then(function (response) {
          setIssLoading(false)
          console.log(response.data)
          //setInvoice(response.data.invoices)
          const objectToUpdate = Invoice.find((item) => item.id === invoiceId); // Replace objectIdToUpdate with the ID or any unique identifier of the object you want to update
          const objectIndex = Invoice.findIndex((item) => item.id === invoiceId);

          const updatedArray = [...Invoice]; // Create a shallow copy of the original array
          updatedArray[objectIndex] = { ...objectToUpdate, status: type };
          setInvoice(updatedArray);
          
          // setInvoiceCount(response.data.invoices.length)
          setAlertMessage(Language['Changed successfully'])
          setAlertType('success')
          setOpenAlert(true)
          setInterval(() => {
            setOpenAlert(false)
          }, 3000);
          setrowLoading(false)
      })
      .catch(function (error) {
        // handle error
        setrowLoading(false)
        handleError(error)
      })
    }


    useEffect(() => {
      handleHeader('Home')
      getHistory('all')
    }, []);


    const columns = [
      { field: 'id', headerName: Language['Id'], width: 180 },
      {
        field: 'status',
        headerName: Language['Status'],
        width: 160,
        renderCell: (params) => {
          if(params.row.status === 'paid'){
            return (
              <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <Button 
                style={{width: '100%'}}
                color='success'
                endIcon={<ArrowDropDown />}
                variant='contained'
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(e) => { setInvoiceId(params.row.id); handleClick(e); e.stopPropagation()}}
              >{Language[params.row.status]}</Button> 
            </div> 
            )
          }else if(params.row.status === 'unpaid'){
            return (
              <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <Button 
                style={{width: '100%'}}
                endIcon={<ArrowDropDown />}
                variant='contained'
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(e) => { setInvoiceId(params.row.id); handleClick(e); e.stopPropagation()}}
              >{Language[params.row.status]}</Button> 
            </div> 
            )
          }else if(params.row.status === 'canceled'){
            return (
              <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <Button 
                style={{width: '100%'}}
                color='error'
                endIcon={<ArrowDropDown />}
                variant='contained'
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(e) => { setInvoiceId(params.row.id); handleClick(e); e.stopPropagation()}}
              >{Language[params.row.status]}</Button> 
            </div> 
            )
          }
        },
      },
      {
        field: 'costumer',
        headerName: Language['Costumer'],
        width: 180,
        renderCell: (params) => {
          if(params.row.invoiceOperation){
            return params.row.invoiceOperation.costumer
          }
        },
      },
      {
        field: 'createdAt',
        headerName: Language['First collaboration'],
        width: 130,
        renderCell: (params) => {
          const year = params.row.createdAt.slice(0, 4);
          const month = params.row.createdAt.slice(5, 7);
          const day = params.row.createdAt.slice(8, 10);
          const hour = params.row.createdAt.slice(11, 13);
          const minute = params.row.createdAt.slice(14, 16);
          return  <p  style={{margin: '0px', padding: '0px'}} >{year}/{month}/{day} {hour}:{minute}</p>
        },
      },
      { field: 'total', headerName: Language['Total'] + ' (TTC)', width: 180 },
      {
        headerName: Language['Action'],
        width: 180,
        renderCell: (params) => {
          if(params.row.invoiceOperation){
            return <Button variant='outlined' style={{ color: 'rgb(100, 37, 126)', borderColor: 'rgb(100, 37, 126)'}} startIcon={<OpenInNewOutlined/>} onClick={() => navigate('/app/invoice_management/invoice?id='+ params.row.invoiceOperation.id)} >{Language['Show']}</Button>
          }
        },
      },

      
      
    ];
    const [FilterAll, setFilterAll] = useState('rgb(100, 37, 126)');
    const [FilterPaid, setFilterPaid] = useState('white');
    const [filterUnpaid, setFilterUnpaid] = useState('white');
    const [filterCanceled, setFilterCanceled] = useState('white');

    function handleFilter(type){
      if(type === 'all'){
        setFilterAll('rgb(100, 37, 126)')
        setFilterPaid('white')
        setFilterCanceled("white")
        setFilterUnpaid('white')
        getHistory('all')
      }else if (type === 'paid'){
        setFilterAll('white')
        setFilterPaid('rgb(100, 37, 126)')
        setFilterCanceled("white")
        setFilterUnpaid('white')
        getHistory('paid')
      }
      else if (type === 'unpaid'){
        setFilterAll('white')
        setFilterPaid('white')
        setFilterCanceled("white")
        setFilterUnpaid('rgb(100, 37, 126)')
        getHistory('unpaid')
      }
      else if (type === 'canceled'){
        setFilterAll('white')
        setFilterPaid('white')
        setFilterCanceled("rgb(100, 37, 126)")
        setFilterUnpaid('white')
        getHistory('canceled')
      }
    }

  return (
    <Body>
        <Header>
            <HeaderTitle>
                <H1>{Language['Invoice management']}</H1>
            </HeaderTitle>
        </Header>
        <BodyContent>
          
            <Form style={{paddingTop :'30px'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'none'}}>
                  <Button variant={"outlined"} style={{color: 'rgb(100, 37, 126)', borderColor: FilterAll, }} onClick={()=> handleFilter('all')}>{Language['All']}</Button>
                  <Button variant={"outlined"} style={{color: 'rgb(100, 37, 126)', borderColor: FilterPaid, }} onClick={()=> handleFilter('paid')}>{Language['Paid']}</Button>
                  <Button variant={"outlined"} style={{color: 'rgb(100, 37, 126)', borderColor: filterUnpaid, }} onClick={()=> handleFilter('unpaid')}>{Language['Unpaid']}</Button>
                  <Button variant={"outlined"} style={{color: 'rgb(100, 37, 126)', borderColor: filterCanceled, }} onClick={()=> handleFilter('canceled')}>{Language['Canceled']}</Button>
                </div>
                <div style={{ height: 'calc(100vh - 255px)', width: '100%', marginTop: '30px' }}>
                  <DataGrid
                    rows={Invoice}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                      },
                    }}
                    loading={rowLoading}
                    pageSizeOptions={[5, 10]}
                    //onRowClick={handleRowClick}
                    rowClassName={(params) => 'custom-row-class'}
                    sortModel={[{field: 'createdAt',sort: 'desc'}]}
                  />
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={(e) => {handleCloseMenu(e); changeStatus('paid')}}>{Language['Paid']}</MenuItem>
                  <MenuItem onClick={(e) => {handleCloseMenu(e); changeStatus('unpaid')}}>{Language['unpaid']}</MenuItem>
                  <MenuItem onClick={(e) => {handleCloseMenu(e); changeStatus('canceled')}}>{Language['Canceled']}</MenuItem>
                </Menu>
            </Form>

        </BodyContent>



                <Snackbar
                  open={openAlert}
                  autoHideDuration={4000}
                  onClose={handleClose}
                >
                  <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                    {alertMessage}
                  </Alert>
                </Snackbar>
    </Body>
  )
}

