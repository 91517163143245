const french = {
    'Welcome Hengxing Al Sinaiya Star ERP': 'Bienvenue dans Hengxing Al Sinaiya Star ERP',
    'The best factory management platform for you.': "La meilleure plateforme de gestion d'usine pour vous.",
    'Inventory management': 'Gestion des stocks',
    'Costumer management': 'Gestion des clients',
    'Invoice management': 'Gestion des factures',
    'TOTAL INVENTORY': 'INVENTAIRE TOTAL',
    'Total products': 'Total des produits',
    'RECEIVED': 'REÇU',
    'Pieces': 'Pièces',
    'Delivered': 'Livré',
    'Total delivered product': 'Total des produits livrés',
    'Returned': 'Retourné',
    'Total returned products': 'Total des produits retournés',
    'Home': 'Accueil',
    'Products': 'Produits',
    'New Product': 'Nouveau produit',
    'received': 'reçu',
    'delivered': 'livré',
    'returned': 'retourné',
    'Operation': 'Opération',
    'operations': 'opérations',
    'New Operation': 'Nouvelle opération',
    'Print': 'Imprimer',
    'Export': 'Exporter',
    'Import': 'Importer',
    'Id': 'ID',
    'Admin': 'Administrateur',
    'Reference': 'Référence',
    'Date': 'Date',
    'Costumer': 'Client',
    'Type': 'Type',
    'draft': 'brouillon',
    'completed': 'terminé',
    'Action': 'Action',
    'Details': 'Détails',
    'Billing': 'Facturation',
    'Draft': 'Brouillon',
    'Quantity': 'Quantité',
    'Status': 'Statut',
    'Valid': 'Valide',
    'Product details': 'Détails du produit',
    'upload image': 'Télécharger une image',
    'General': 'Général',
    'Name': 'Nom',
    'Component': 'Composant',
    'Product': 'Produit',
    'Price': 'Prix',
    'Delete': 'Supprimer',
    'Save': 'Enregistrer',
    'Components': 'Composants',
    'Add': 'Ajouter',
    'pieces': 'pièces',
    'Received': 'Reçu',
    'unlimited': 'illimité',
    'Total inventory': 'Inventaire total',
    'units': 'unités',
    'History': 'Historique',
    'Select Date': 'Sélectionner une date',
    'Filter': 'Filtrer',
    'Delete product': 'Supprimer le produit',
    'Are you sure you want to permanently delete this product?': 'Êtes-vous sûr de vouloir supprimer définitivement ce produit ?',
    'Cancel': 'Annuler',
    'Stock': 'Stock',
    'Operation details': "Détails de l'opération",
    'Operation type': "Type d'opération",
    'Operation reference': "Référence de l'opération",
    'Client': 'Client',
    'Local operation': 'Opération locale',
    'Full name': 'Nom complet',
    'Address': 'Adresse',
    'Created by': 'Créé par',
    'Add operation': 'Ajouter une opération',
    'It will be generated after clicking save..': 'Il sera généré après avoir cliqué sur Enregistrer.',
    'User': 'Utilisateur',
    'Maximum units': 'Unités maximales',
    'URL Not Found!': 'URL non trouvée !',
    'The requested URL not found, it seems like it changed or deleted.': "L'URL demandée n'a pas été trouvée, il semble qu'elle ait été modifiée ou supprimée.",
    'Apps': 'Applications',
    'Profile': 'Profil',
    'Logout': 'Déconnexion',
    'My account': 'Mon compte',
    'Tax identification details': "Détails d'identification fiscale",
    'Email': 'E-mail',
    'Company': 'Entreprise',
    'Bills': 'Factures',
    'First collaboration': 'Première collaboration',
    'costumers': 'Clients',
    'Costumer operations': 'Opérations clients',
    'Close': 'Fermer',
    'paid': 'payé',
    'unpaid': 'non payé',
    'canceled': 'annulé',
    'Total': 'Total',
    'Show': 'Afficher',
    'All': 'Tous',
    'Paid': 'Payé',
    'Unpaid': 'Non payé',
    'Canceled': 'Annulé',
    'Save & Print': 'Enregistrer et imprimer',
    'Choose products': 'Choisir des produits',
    'Imported products': 'Produits importés',
    'Update existing products': 'Mettre à jour les produits existants',
    'Products details': 'Détails des produits',
    'Related To': 'Lié à',
    'Message': 'Message',
    'Already exist': 'Existe déjà',
    'Related to unfound product': 'Lié à un produit introuvable',
    'Operations details': 'Détails des opérations',
    'An error has occurred.': "Une erreur s'est produite.",
    'Internal server error.': 'Erreur interne du serveur.',
    'Too many requests.': 'Trop de demandes.',
    'No row selected.': 'Aucune ligne sélectionnée.',
    'You should select a product.': 'Vous devez sélectionner un produit.',
    'Changed successfully': 'Modifié avec succès',
    'Auto-save not allowed.': 'Sauvegarde automatique non autorisée.',
    'Not allowed.': 'Non autorisé.',
    'Deleted successfully.': 'Supprimé avec succès.',
    'Maximum units reached.': "Nombre d'unités maximal atteint.",
    'The image size is too large.': "La taille de l'image est trop grande.",
    'Image only.': 'Image uniquement.',
    'An error has occurred while uploading the image.': "Une erreur s'est produite lors du téléchargement de l'image.",
    'Not found.': 'Non trouvé.',
    'You should select a product.': 'Vous devez sélectionner un produit.',
    'Product not found.': 'Produit introuvable.',
    'Already added!': 'Déjà ajouté !',
    'Operation not found.': 'Opération introuvable.',
    'CSV file only.': 'Fichier CSV uniquement.',
    'Type value is not valid.': "La valeur du type n'est pas valide.",
    'Type is required.': 'Le type est requis.',
    'Invalid status.': 'Statut invalide.',
    'InvoiceId is required.': 'InvoiceId est requis.',
    'Saved successfully.': 'Enregistré avec succès.',
    'save': 'enregistrer',
    'Disconnect': 'Déconnexion',
    'Personal information': 'Informations personnelles',
    'Security': 'Sécurité',
    'First name': 'Prénom',
    'Family name': 'Nom de famille',
    'Save your information': 'Sauvegarder vos informations',
    'Actuel password': 'Mot de passe actuel',
    'New password': 'Nouveau mot de passe',
    'Confirm new password': 'Confirmez le nouveau mot de passe',
    'Save password': 'Sauvegarder le mot de passe',
    'Bad Request: The server cannot process the request due to an invalid syntax.': "Mauvaise requête : Le serveur ne peut pas traiter la demande en raison d'une syntaxe invalide.",
    'Unauthorized: Authentication is required, and the user has not provided valid credentials.': "Non autorisé : L'authentification est requise, et l'utilisateur n'a pas fourni de justificatif valide.",
    'Forbidden: The server understood the request but refuses to authorize it.': "Interdit : Le serveur a compris la demande mais refuse de l'autoriser.",
    'Not Found: The server cannot find the requested resource.': 'Non trouvé : Le serveur ne peut pas trouver la ressource demandée.',
    'Method Not Allowed: The request method is not supported for the requested resource.': "Méthode non autorisée : La méthode de demande n'est pas prise en charge pour la ressource demandée.",
    'Too Many Requests: The user has sent too many requests in a given amount of time.': "Trop de demandes : L'utilisateur a envoyé trop de demandes dans un laps de temps donné.",
    'Internal Server Error: The server has encountered a situation it does not know how to handle.': "Erreur interne du serveur : Le serveur a rencontré une situation qu'il ne sait pas comment gérer.",
    'Service Unavailable: The server is currently unable to handle the request.': 'Service non disponible : Le serveur est actuellement incapable de traiter la demande.',
    'Error: There was an error processing your request.': "Erreur : Une erreur s'est produite lors du traitement de votre demande.",
    'An error has occurred.': "Une erreur s'est produite.",
    'Logout successfully.': 'Déconnexion réussie.',
    'string only': 'chaîne de caractères uniquement',
    'name is required.': 'le nom est requis.',
    'enter a valid name': 'entrez un nom valide',
    'family name is required.': 'le nom de famille est requis.',
    'enter a valid family name': 'entrez un nom de famille valide',
    'your information saved successfully.': 'vos informations ont été enregistrées avec succès.',
    'password is required.': 'le mot de passe est requis.',
    'enter a valid password': 'entrez un mot de passe valide',
    'new password is required.': 'le nouveau mot de passe est requis.',
    'password confirmation is required.': 'la confirmation du mot de passe est requise.',
    'Passwords do not match': 'Les mots de passe ne correspondent pas',
    'incorrect password': 'mot de passe incorrect',
    'Privileges': 'Privilèges',
    'Edit': 'Modifier',
    'Create user': 'Créer un utilisateur',
    'After clicking create button, the user will receive an email with a personalized signup link.': "Après avoir cliqué sur le bouton Créer, l'utilisateur recevra un e-mail avec un lien d'inscription personnalisé.",
    'Email Address': 'Adresse e-mail',
    'User privilege': "Privilège de l'utilisateur",
    'Add products': 'Ajouter des produits',
    'Edit products': 'Modifier des produits',
    'Delete products': 'Supprimer des produits',
    'Create': 'Créer',
    'Edit user': "Modifier l'utilisateur",
    'After clicking save, privileges for this user will be changed.': 'Après avoir cliqué sur Enregistrer, les privilèges de cet utilisateur seront modifiés.',
    'Be careful': 'Soyez prudent',
    'Delete user': "Supprimer l'utilisateur",
    'Please be aware that this action is irreversible and you are about to permanently delete': 'Veuillez noter que cette action est irréversible et que vous êtes sur le point de la supprimer définitivement',
    'Users management': 'Gestion des utilisateurs',
    'Email is not valid.': "L'adresse e-mail n'est pas valide.",
    'Email already exists.': "L'adresse e-mail existe déjà.",
    'home': 'Accueil',
    'products': 'Produits',
    'Active': 'Actif',
    'Invited': 'Invité',
    'Total delivered products': 'Total des produits livrés',
    'DELIVERED': 'LIVRÉ',
    'RETURNED': 'RETOURNÉ',
    'Changes saved': 'Modifications enregistrées',
    'Product is undefined, refresh your page and try again.': 'Le produit est indéfini, rafraîchissez votre page et réessayez.',
    'You should select a component first.': "Vous devez d'abord sélectionner un composant.",
    'Reset': 'Réinitialiser',
    'Change the quantity': 'Changer la quantité',
    'The super admin is the only user who can change the quantity.': 'Le super administrateur est le seul utilisateur qui peut changer la quantité.',
    'modified': 'modifié',
    'Item not found.': 'Article introuvable.',
    'The quantity value should be a non-negative number.': 'La valeur de la quantité doit être un nombre non négatif.',
    'Can not perform this operation.': "Impossible d'effectuer cette opération.",
    'component': 'composant',
    'product': 'produit',
    'Create a new component': 'Créer un nouveau composant',

}
  
   
export default french;

    