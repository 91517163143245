import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components'

const LoadingComp = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export default function Loading() {
  return (
    <LoadingComp>
      <CircularProgress />
    </LoadingComp>
  )
}
