import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import EngineeringIcon from '@mui/icons-material/Engineering';

const Body = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Title = styled.h1`
    font-size: large;
    color: #0f0b0e ;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 30px;
    text-align: center;
`
const SouTitle = styled.p`
    font-size: small;
    color: #707070;
    margin-top: 0px;
    width: 100%;
    padding-bottom: 20px;
    text-align: center;
`

const MyIcon = styled(EngineeringIcon)`
    font-size: 50px !important;
`

const MyLink = styled(Link)`
    //text-decoration-line: none;
    color: black;
`

export default function Hello() {
  return (
    <Body>
      <MyIcon></MyIcon>
      <Title>Home page is coming soon..</Title>
      <SouTitle>you can access directly to <MyLink to={'/app'}>Your Dashboard</MyLink></SouTitle>
    </Body>
  )
}
